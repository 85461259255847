import React from 'react';
import {Redirect} from 'react-router-dom';

import {Divider, Layout, notification, PageHeader} from 'antd';
import {InfoCircleOutlined} from '@ant-design/icons';
import {postNetwork} from '../../Api/Networks.js';
import FormNetwork from './FormNetwork.js';
import {GlobalContext} from '../../GlobalContext.js';

class NetworkAdd extends React.Component {
    static contextType = GlobalContext;
    /**
     * 
     * @param {*} props 
     */
    constructor(props) {
        super(props);

        this.state = {
            redirection: false,
            id: null,
            network: null,
            user: null
        }

        this.onSubmit = this.onSubmit.bind(this);
    }

    /**
     * 
     * @param {*} values 
     */
    onSubmit(values) {
        let data = { ...values };
        data.price_grid = 1;
        postNetwork(data)
            .then(function (response) {
                return response.json();
            }).then((data) => {
                notification['success']({
                    message: 'Le réseau a bien été enregistré'
                })
                this.setState({ id: data.network.id, redirection: true });
            });
    };

    render() {
        const { Content } = Layout;
        const { redirection } = this.state;
        const { id } = this.state;

        if (redirection) {
            return (<Redirect to={'/network/' + id} />)
        }

        return (
            <div>
                <PageHeader title="Réseaux" subTitle="Création d'un réseau" />
                <Content style={{ margin: '0 16px 16px 16px' }}>
                    <div className="site-layout-background" style={{ padding: 24, minHeight: 360 }}>
                        <h1 style={{ color: "#2FAC66" }}><InfoCircleOutlined /> INFORMATIONS</h1>
                        <Divider></Divider>
                        <FormNetwork network={this.state.network} user={this.context.global.profile} formSubmit={this.onSubmit} />
                    </div>
                </Content>
            </div>
        )
    }
}

export default NetworkAdd;

import config from '../Config.js';

/**
 * 
 * @param {*} data 
 * @returns 
 */
export function getManyNetworks(data) {
    return fetch(config.hostApi + 'networks', {
        method: 'POST',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + localStorage.getItem('token')
        },
        body: JSON.stringify(data)
    });
}

/**
 * 
 * @param {*} id 
 */
export function getOneNetwork(id) {
    return fetch(config.hostApi + 'network/' + id, {
        method: 'GET',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + localStorage.getItem('token')
        },
    })
}

/**
 * 
 * @param {*} data 
 */
export function postNetwork(data) {
    return fetch(config.hostApi + 'network', {
        method: 'POST',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + localStorage.getItem('token')
        },
        body: JSON.stringify(data)
    })
}

/**
 * 
 * @param {*} id 
 * @param {*} data 
 */
export function updateNetwork(id, data) {
    return fetch(config.hostApi + 'network/' + id, {
        method: 'PUT',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + localStorage.getItem('token')
        },
        body: JSON.stringify(data)
    })
}

/**
 * 
 * @param {*} id 
 */
export function deleteNetwork(id) {
    return fetch(config.hostApi + 'network/' + id, {
        method: 'DELETE',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + localStorage.getItem('token')
        },
    })
}

/**
 * 
 * @param {*} id 
 */
export function networkOpeningSchedule(id, data) {
    return fetch(config.hostApi + 'network/' + id + '/saveOpeningSchedule', {
        method: 'POST',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': 'Bearer' + localStorage.getItem('token')
        },
        body: JSON.stringify({
            'opening_schedules': data
        })
    })
}

/**
 * 
 * @param {*} id 
 */
export function getNetworkOpeningSchedule(id) {
    return fetch(config.hostApi + 'network/' + id + '/getOpeningSchedule', {
        method: 'GET',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': 'Bearer' + localStorage.getItem('token')
        }
    })
}

/**
 * 
 * @param {*} id 
 * @param {*} data 
 * @returns 
 */
export function saveNetworkBoundaries(id, data) {
    return fetch(config.hostApi + 'network/' + id + '/boundaries', {
        method: 'POST',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + localStorage.getItem('token')
        },
        body: JSON.stringify(data)
    })
}

/**
 * 
 * @param {*} id 
 * @returns 
 */
export function getNetworkBoundaries(id) {
    return fetch(config.hostApi + 'network/' + id + '/getBoundaries', {
        method: 'GET',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + localStorage.getItem('token')
        },
    })
}

/**
 * 
 * @param {*} id 
 * @param {*} data 
 * @returns 
 */
export function saveNetworkStore(id, data) {
    return fetch(config.hostApi + 'network/' + id + '/store', {
        method: 'POST',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + localStorage.getItem('token')
        },
        body: JSON.stringify(data)
    })
}

/**
 * 
 * @param {*} id 
 * @param {*} data 
 * @returns 
 */
export function updateNetworkStore(id, data) {
    return fetch(config.hostApi + 'network/' + id + '/store', {
        method: 'PUT',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + localStorage.getItem('token')
        },
        body: JSON.stringify(data)
    })
}

/**
 * 
 * @param {*} id 
 * @returns 
 */
export function getNetworkStores(id) {
    return fetch(config.hostApi + 'network/' + id + '/getStores', {
        method: 'GET',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + localStorage.getItem('token')
        },
    })
}

/**
 * 
 * @param {*} id 
 * @returns 
 */
export function deleteNetworkStore(id, store_id) {
    return fetch(config.hostApi + 'network/' + id + '/store/' + store_id, {
        method: 'DELETE',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + localStorage.getItem('token')
        },
    })
}

/**
 * 
 * @param {*} id 
 * @returns 
 */
export function getNetworkOneStore(id, store_id) {
    return fetch(config.hostApi + 'network/' + id + '/store/' + store_id, {
        method: 'GET',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + localStorage.getItem('token')
        },
    })
}
import React, {useContext, useEffect, useState} from 'react';
import {Link} from 'react-router-dom';
import moment from 'moment';
import {GlobalContext} from '../../GlobalContext.js';

import {
    Button,
    Col,
    DatePicker,
    Divider,
    Drawer,
    Layout, message,
    PageHeader,
    Progress,
    Row,
    Select,
    Space,
    Table,
    Tag,
    Tooltip
} from 'antd';
import {
    AimOutlined,
    ArrowDownOutlined,
    EyeOutlined,
    HomeOutlined,
    MoreOutlined,
    UnorderedListOutlined
} from '@ant-design/icons';

import {getManyWhiteLabels} from "../../Api/WhiteLabels.js";
import {getManyTours, getOneTour, postTourCoursesOrder} from "../../Api/Tours";
import {getManyNetworks} from "../../Api/Networks";
import DrawerDetailsCourse from "../../Components/Course/DrawerDetailsCourse";
import {getOneCourse} from "../../Api/Courses";
import {closestCorners, DndContext, KeyboardSensor, PointerSensor, useSensor, useSensors} from "@dnd-kit/core";
import {
    SortableContext,
    sortableKeyboardCoordinates,
    useSortable,
    verticalListSortingStrategy
} from '@dnd-kit/sortable';
import {restrictToVerticalAxis, restrictToWindowEdges} from '@dnd-kit/modifiers';
import {translateStatus} from "../../constants";


const {Content} = Layout;
const {Option} = Select;

const Tours = () => {

    const contextType = useContext(GlobalContext)

    const [loading, setLoading] = useState(false)
    const [tours, setTours] = useState([])
    const [dataWhiteLabel, setDataWhiteLabel] = useState([])
    const [whiteLabelId, setWhiteLabelId] = useState(null)
    const [networkId, setNetworkId] = useState(null)
    const [dataNetwork, setDataNetwork] = useState([])
    const [date, setDate] = useState(moment())
    const [showDrawerTour, setShowDrawerTour] = useState(false)
    const [selectedTourId, setSelectedTourId] = useState(null)
    const [selectedCourse, setSelectedCourse] = useState(null)
    const [showDrawerCourse, setShowDrawerCourse] = useState(false)
    const [pagination, setPagination] = useState({
        current: 1,
        pageSize: 10,
    })

    const columns = [
        {
            title: '#',
            dataIndex: 'id',
            key: 'id',
            width: 50
        },
        {
            title: 'Réseau',
            dataIndex: 'network',
            sorter: true,
            key: 'driver',
            render: (network) => (
                <Link to={'/network/' + network.id}>{network.name}</Link>
            )
        },
        {
            title: 'Transporteur',
            dataIndex: 'driver',
            sorter: true,
            key: 'driver',
            render: (item) => (
                <Link to={'/driver/' + item.id}>{item.firstName} {item.lastName}</Link>
            )
        },
        {
            title: 'Progression',
            key: 'progression',
            render: (tour) => {
                return (
                    <TourProgress tour={tour}/>
                )
            }
        },
        {
            title: '',
            dataIndex: 'id',
            key: 'id',
            width: 100,
            render: (id) => (
                <Space size="middle">
                    <Button type='primary' onClick={() => {
                        setShowDrawerTour(true)
                        setSelectedTourId(id);
                    }}><EyeOutlined/>
                    </Button>
                </Space>
            )
        }
    ];

    useEffect(() => {
        loadTours({
            whiteLabel_id: whiteLabelId,
            network_id: networkId,
            date: date.format('Y-MM-DD'),
            pagination,
        });
    }, []);

    /**
     *
     * @param {*} params
     */
    const loadTours = (params = {}) => {
        setLoading(true)
        getManyTours(params)
            .then((res) => res.json())
            .then((json) => {
                setLoading(false)
                setTours(json.results)
                setPagination({
                    ...params.pagination,
                    total: json.totalCount,
                })
            });
    }

    /**
     *
     * @param {*} pagination
     * @param {*} filters
     * @param {*} sorter
     */
    const handleTableChange = (pagination, filters, sorter) => {
        loadTours({
            whiteLabel_id: whiteLabelId,
            network_id: networkId,
            date: date.format('Y-MM-DD'),
            sortField: sorter.field,
            sortOrder: sorter.order,
            pagination,
        });
    };


    /**
     *
     * @param {*} value
     */
    const handleSearchNetwork = (value) => {
        if (value.length > 2) {
            getManyNetworks({
                term: value
            })
                .then((res) => res.json())
                .then((json) => {
                    setDataNetwork(json.results)
                });
        }
    };

    /**
     *
     * @param {*} value
     */
    const onChangeNetwork = (value) => {
        setNetworkId(value)
    }

    /**
     *
     * @param {*} value
     */
    const onChangeDate = (value) => {
        setDate(value)
    }

    /**
     *
     */
    const onClickFilterTours = () => {
        let data = {
            pagination: pagination.pagination,
            whiteLabel_id: whiteLabelId,
            network_id: networkId,
            date: date.format('Y-MM-DD'),
        };

        setLoading(true);

        getManyTours(data)
            .then((res) => res.json())
            .then((json) => {
                setLoading(false);
                setTours(json.results)
                setPagination({
                    ...pagination,
                    total: json.totalCount,
                })
            })
            .catch(() => {
                setLoading(false);
            });
    }

    /**
     *
     */
    const onClickDeleteFilter = () => {
        setWhiteLabelId(null)
        setNetworkId(null)
        setDate(moment())
        const pagination = {
            current: 1,
            pageSize: 10
        };
        loadTours({
            whiteLabel_id: whiteLabelId,
            network_id: networkId,
            date: date.format('Y-MM-DD'),
            pagination
        });
    }

    /**
     *
     * @param {*} value
     */
    const handleSearchWhiteLabel = (value) => {
        if (value.length > 2) {
            getManyWhiteLabels({
                term: value
            })
                .then((res) => res.json())
                .then((json) => {
                    setDataWhiteLabel(json.results)
                });
        }
    };

    const showSelectedCourse = (course) => {
        setSelectedCourse(course);
        setShowDrawerCourse(true)
    }

    /**
     *
     * @param {*} value
     */
    const onChangeWhiteLabel = (value) => {
        setWhiteLabelId(value)
    }

    const user = contextType.global.profile;

    let filterWhiteLabelPart = null;
    if (user !== null && user.whiteLabel_id === null) {
        filterWhiteLabelPart = (
            <div>
                <Space>
                    <div className="filter-title">Marques blanches</div>
                    <Select
                        className="filter"
                        showSearch
                        value={whiteLabelId}
                        placeholder="Entrer au minimum 3 caractères"
                        showArrow={true}
                        filterOption={false}
                        onSearch={handleSearchWhiteLabel}
                        onChange={onChangeWhiteLabel}
                        notFoundContent={null}
                        allowClear
                    >
                        {dataWhiteLabel.map((d) => (
                            <Option key={d.id}>{d.name}</Option>
                        ))}
                    </Select>
                </Space>
            </div>
        );
    }

    return (
        <div>
            <PageHeader title="Tournées" subTitle="Liste des tournées"/>
            <Content style={{margin: '0 16px 16px 16px'}}>
                <div className="site-layout-background" style={{padding: 24, minHeight: 360}}>
                    <h2 style={{color: "#2FAC66"}}><UnorderedListOutlined/> LISTE</h2>
                    <Divider/>
                    <Space direction="vertical">
                        {filterWhiteLabelPart}
                        <Space>
                            <div className="filter-title">Date</div>
                            <DatePicker
                                value={date}
                                onChange={onChangeDate}
                                className="filter"
                                style={{width: '100%'}}
                                format={'DD/MM/YYYY'}
                                allowClear={false}
                            />
                        </Space>
                        <Space>
                            <div className="filter-title">Réseaux</div>
                            <Select
                                className="filter"
                                showSearch
                                value={networkId}
                                placeholder="Entrer au minimum 3 caractères"
                                showArrow={true}
                                filterOption={false}
                                onSearch={handleSearchNetwork}
                                onChange={onChangeNetwork}
                                notFoundContent={null}
                            >
                                {dataNetwork.map((d) => (
                                    <Option key={d.id}>{d.name}</Option>
                                ))}
                            </Select>
                        </Space>
                        <Space>
                            <Button
                                className="filter-button"
                                type="primary"
                                onClick={onClickFilterTours}
                            >
                                Filtrer
                            </Button>
                            <Button
                                style={{backgroundcolor: "#2FAC66"}}
                                onClick={onClickDeleteFilter}
                            >
                                Annuler
                            </Button>
                        </Space>
                    </Space>
                    <Divider/>
                    <Table
                        columns={columns}
                        loading={loading}
                        onChange={handleTableChange}
                        pagination={pagination}
                        dataSource={tours}
                    />
                </div>
            </Content>
            <DrawerTour visible={showDrawerTour} tourId={selectedTourId}
                        onSelectCourse={showSelectedCourse} onClose={() => {
                setShowDrawerTour(false)
            }}>
                <DrawerDetailsCourse
                    course={selectedCourse}
                    showDrawerCourseDetails={showDrawerCourse}
                    showDrawer={() => {
                        setShowDrawerCourse(false)
                        setSelectedCourse(null)
                    }}
                />
            </DrawerTour>
        </div>
    );
}

const TourProgress = (props) => {

    if (!props.tour) {
        return (<></>);
    }

    let title = (<>{props.tour.progression.ended} terminée(s), {props.tour.progression.current} en
        cours, {props.tour.progression.total} au total</>)

    let percent = Math.round((props.tour.progression.ended / props.tour.progression.total) * 100);

    return (
        <Tooltip title={title}>
            <Progress percent={percent} success={{percent: percent}}/>
        </Tooltip>
    )
}

function SortableItem({id, isDragging, action, onSelectCourse}) {
    const {
        attributes,
        listeners,
        setNodeRef,
        transform,
        transition,
    } = useSortable({id});

    const style = {
        opacity: isDragging ? 0.7 : 1,
        transform: transform ? `translate3d(${transform.x}px, ${transform.y}px, 0)` : "none",
        transition: transition ?? "none",
    };

    return (
        <div ref={setNodeRef} style={style} {...attributes} >
            <Row style={{
                alignItems: "center"
            }}>
                <Col span={1}  {...listeners} style={{
                    cursor: "grab",
                }}>
                    <div style={{
                        padding: "15px 0 15px"
                    }}>
                        <MoreOutlined/>
                    </div>
                </Col>
                <Col span={23}>
                    <Row>
                        {(action.type === 'pickup') ? <Col span={24}><HomeOutlined/> Retrait</Col> :
                            <Col span={24}><AimOutlined/> Livraison </Col>}
                    </Row>
                    <Row style={{
                        alignItems: "center"
                    }}>
                        <Col span={17}><ArrowDownOutlined/> <Link
                            to={'/merchant/' + action.course.merchant_id}>{action.course.pickup_area.name}</Link>, {action.course.pickup_area.address}
                        </Col>
                    </Row>
                    <Row style={{
                        alignItems: "center"
                    }}>
                        <Col span={17}><ArrowDownOutlined/> <Link
                            to={'/customer/' + action.course.customer_id}>{action.course.delivery_area.firstname} {action.course.delivery_area.name}</Link>, {action.course.delivery_area.address}
                        </Col>
                        <Col span={5}>
                            <Tag color="green" key={action.course.status}>
                                {translateStatus[action.course.status]}
                            </Tag>
                        </Col>
                        <Col span={2}>
                            <Button type='primary' onClick={() => {
                                getOneCourse(action.course.id).then((response) => {
                                    return response.json();
                                }).then((course) => {
                                    onSelectCourse(course.course);
                                })
                            }}><EyeOutlined/>
                            </Button>
                        </Col>
                    </Row>
                </Col>
            </Row>
            <Divider/>
        </div>
    );
}

const DrawerTour = (props) => {

    const [loading, setLoading] = useState(false);
    const [tour, setTour] = useState(null);
    const [sortedActions, setSortedActions] = useState([]);
    const [activeId, setActiveId] = useState(null);

    const sensors = useSensors(
        useSensor(PointerSensor),
        useSensor(KeyboardSensor, {
            coordinateGetter: sortableKeyboardCoordinates,
        })
    );

    /**
     *
     * @param event
     */
    const onDragEndHandler = (event) => {
        const {active, over} = event;
        const pickupIds = [];
        if (active.id !== over.id) {
            const oldIndex = sortedActions.findIndex((item) => item.id === active.id);
            const newIndex = sortedActions.findIndex((item) => item.id === over.id);
            const reorderedItems = [...sortedActions];
            const [movedItem] = reorderedItems.splice(oldIndex, 1);
            reorderedItems.splice(newIndex, 0, movedItem);

            let sortIsAllowed = true
            for (const reorderedItemsKey of reorderedItems) {
                if (reorderedItemsKey.type === 'pickup') {
                    pickupIds.push(reorderedItemsKey.course.id)
                } else if (pickupIds.indexOf(reorderedItemsKey.course.id) === -1) {
                    sortIsAllowed = false;
                }
            }

            if (sortIsAllowed) {
                setSortedActions(reorderedItems);
                postTourCoursesOrder(tour.id, {
                    order: reorderedItems.map((action) => action.id)
                });
            } else {
                message.error('Vous ne pouvez pas mettre une livraison avant son retrait');
            }
        }

        setActiveId(null);
    }

    /**
     *
     * @param active
     */
    const onDragStartHandler = ({active}) => {

        if (!active) {
            return;
        }

        setActiveId(active.id);
    }

    useEffect(() => {
        if (props.visible === true) {
            setTour(null);
            setSortedActions([]);
        }

        if (props.tourId !== null && props.visible) {
            setLoading(true);
            getOneTour(props.tourId).then((response) => {
                return response.json();
            }).then((tour) => {
                setLoading(false);
                setTour(tour.tour);

                let actions = {};
                for (const course of tour.tour.courses) {
                    actions[course.pickup_area.id] = {
                        ...course.pickup_area,
                        'type': 'pickup',
                        'course': course,
                    };

                    actions[course.delivery_area.id] = {
                        ...course.delivery_area,
                        'type': 'delivery',
                        'course': course,
                    };
                }

                let newSortedActions = [];

                if (tour.tour.courses_order.length !== tour.tour.courses.length * 2) {
                    for (const course of tour.tour.courses) {
                        newSortedActions.push(actions[course.pickup_area.id]);
                        newSortedActions.push(actions[course.delivery_area.id]);
                    }
                } else {
                    for (const sortedAction of tour.tour.courses_order) {
                        newSortedActions.push(actions[sortedAction.action_id])
                    }
                }

                setSortedActions(newSortedActions);

            })
        }
    }, [props.tourId, props.visible])

    let content = 'Chargement ...';

    if (tour && !loading) {
        content = (
            <>
                <Row gutter={20}>
                    <Col span={12}>
                        <Row gutter={20}>
                            <Col span={12}>Transporteur : </Col>
                            <Col span={12}>{tour.driver.firstName} {tour.driver.lastName}</Col>
                        </Row>
                    </Col>
                </Row>
                <Row gutter={20}>
                    <Col span={12}>
                        <Row gutter={20}>
                            <Col span={12}>Date : </Col>
                            <Col span={12}>{moment(tour.date).format('DD/MM/Y')}</Col>
                        </Row>
                    </Col>
                </Row>

                <div style={{marginBottom: '25px', marginTop: '25px'}}>
                    <TourProgress tour={tour}/>
                </div>

                <DndContext
                    strategy={verticalListSortingStrategy}
                    collisionDetection={closestCorners}
                    sensors={sensors}
                    modifiers={[restrictToVerticalAxis, restrictToWindowEdges]}
                    onDragStart={onDragStartHandler}
                    onDragEnd={onDragEndHandler}
                    onDragCancel={() => setActiveId(null)}
                >
                    <SortableContext items={sortedActions.map((action) => action.id)}
                                     strategy={verticalListSortingStrategy}>
                        {sortedActions.map(action => {
                            return (<SortableItem handle={true} id={action.id} key={action.id} action={action}
                                                  isDragging={action.id === activeId}
                                                  onSelectCourse={props.onSelectCourse}/>)
                        })}
                    </SortableContext>
                </DndContext>
            </>
        );
    }

    return (
        <Drawer
            title="Tournée"
            width={720}
            onClose={props.onClose}
            visible={props.visible}
            bodyStyle={{paddingBottom: 80}}
        >
            {content}
            {props.children}
        </Drawer>
    )
}

export default Tours;

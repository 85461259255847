import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import moment from 'moment';

import { Button, Col, Row, Drawer, Divider, Space, Tooltip } from 'antd';
import { ArrowRightOutlined } from '@ant-design/icons';

import { getOneCustomer } from '../../Api/Customers.js';

const DrawerDetailsCourse = (props) => {

    let { course, showDrawerCourseDetails } = props;
    const [customer, setCustomer] = useState(false);

    let [visibleDrawerCourseInfo, setVisibleDrawerCourseInfo] = React.useState(false);

    const onCloseCourseInfo = () => {
        setVisibleDrawerCourseInfo(false);
        props.showDrawer(false);
    };

    const loadCustomer = () => {
        let customerId = course.customer_id;
        getOneCustomer(customerId)
            .then((response) => response.json())
            .then((data) => setCustomer(data.customer));
    };

    useEffect(() => {
        if (showDrawerCourseDetails === true) {
            setVisibleDrawerCourseInfo(true)
        }
    });

    useEffect(() => {
        if (course) {
            loadCustomer();
        }
    }, [course]);

    let drawerInfo = null;
    let courseUuid = null;
    let packetPrice = null;
    let firstnameCustomer = null;
    let deliveryOptions = [];
    let deliveryAreaComment = null;
    if (course !== null) {
        courseUuid = course.short_uuid;

        if (course.packet_price !== null) {
            packetPrice = course.packet_price
        } else {
            packetPrice = 0
        }

        if (course.delivery_area.firstname === null) {
            firstnameCustomer = ""
        } else {
            firstnameCustomer = course.delivery_area.firstname
        }

        if (course.delivery_options.length > 0) {
            let options = course.delivery_options;
            options.map((option) => {
                let chooseOption = {
                    name: option.option_name,
                }
                deliveryOptions.push(chooseOption);
            })
        }

        deliveryAreaComment = course.delivery_area.comment;

        const disabledCustomerButton = (customer !== null && customer.archived_at !== null);

        drawerInfo = (
            <div>
                <Row gutter={20}>
                    <Col span={12}>
                        <Row gutter={20}>
                            <Col span={12}>Date de livraison : </Col>
                            <Col span={12}>{moment(course.deliveryDate).format('DD/MM/YYYY')}</Col>
                        </Row>
                    </Col>
                    <Col span={12}>
                        <Row gutter={20}>
                            <Col span={12}>Horaires :</Col>
                            <Col span={12}>{course.collectTime} <ArrowRightOutlined /> {course.deliveryTime} </Col>
                        </Row>
                    </Col>
                </Row>
                <Row gutter={20}>
                    <Col span={12}>
                        <Row gutter={20}>
                            <Col span={12}>Prix HT (transporteur) :</Col>
                            <Col span={12}>{course.driverPrice} € </Col>
                        </Row>
                    </Col>
                    <Col span={12}>
                        <Row gutter={20}>
                            <Col span={12}>Prix HT (commerçant) :</Col>
                            <Col span={12}>{course.merchantPrice} € </Col>
                        </Row>
                    </Col>
                </Row>
                <Row gutter={20}>
                    <Col span={12}>
                        <Row gutter={20}>
                            <Col span={12}>Distance de la course:</Col>
                            <Col span={12}>{course.distance / 1000} km </Col>
                        </Row>
                    </Col>
                </Row>
                <Row gutter={20}>
                    <Col span={6}>Commerçant : </Col>
                    <Col span={18}>{course.pickup_area.name}</Col>
                </Row>
                <Space>
                    <Button type='primary' style={{ marginTop: '20px', width: '170px' }}>
                        <Link to={'/course/' + course.uuid}>Détails de la course</Link>
                    </Button>
                    <Button type='primary' style={{ marginTop: '20px', width: '170px' }}>
                        <Link to={'/merchant/' + course.merchant_id}>Détail du commerçant</Link>
                    </Button>
                </Space>
                <Divider orientation="left">Zone de Retrait</Divider>
                <Row gutter={20}>
                    <Col span={6}>Adresse :</Col>
                    <Col span={12}>{course.pickup_area.address}</Col>
                </Row>
                <Row gutter={20}>
                    <Col span={6}>Personne à contacter :</Col>
                    <Col span={12}>{course.pickup_area.contact_firstname} {course.pickup_area.contact_lastname}</Col>
                </Row>
                <Row gutter={20}>
                    <Col span={6}>Téléphone :</Col>
                    <Col span={16}>{course.pickup_area.phone}</Col>
                </Row>
                <Divider orientation="left">Zone de livraison</Divider>
                <Row gutter={20}>
                    <Col span={6}>Nom :</Col>
                    <Col span={16}>{firstnameCustomer} {course.delivery_area.name}</Col>
                </Row>
                <Row gutter={20}>
                    <Col span={6}>Adresse :</Col>
                    <Col span={16}>{course.delivery_area.address}</Col>
                </Row>
                <Row gutter={20}>
                    <Col span={6}>Téléphone :</Col>
                    <Col span={16}>{course.delivery_area.phone}</Col>
                </Row>
                <Row gutter={20}>
                    <Col span={6}>Téléphone portable :</Col>
                    <Col span={16}>{course.delivery_area.mobile_phone}</Col>
                </Row>
                <Row gutter={20}>
                    <Col span={6}>Etage :</Col>
                    <Col span={16}>{course.delivery_area.floor}</Col>
                </Row>
                <Row gutter={20}>
                    <Col span={6}>Interphone :</Col>
                    <Col span={16}>{course.delivery_area.intercom_code}</Col>
                </Row>
                <Row gutter={20}>
                    <Col span={6}>Commentaire :</Col>
                    <Col span={16}>{deliveryAreaComment}</Col>
                </Row>
                <Row style={{ marginTop: '20px' }}>
                    <Tooltip title={(disabledCustomerButton) ? "client archivé" : null}>
                        <Button type='primary' style={{ width: '170px' }} disabled={disabledCustomerButton}>
                            <Link to={'/customer/' + course.customer_id}>
                                Détail du client
                            </Link>
                        </Button>
                    </Tooltip>
                </Row>
                <Divider orientation="left">Colis</Divider>
                <Row gutter={20}>
                    <Col span={6}>Prix :</Col>
                    <Col span={16}>{packetPrice} €</Col>
                </Row>
                <Row gutter={20}>
                    <Col span={6}>Poids :</Col>
                    <Col span={16}>{course.packet_weight} kg</Col>
                </Row>
                <Row gutter={20}>
                    <Col span={6}>Description :</Col>
                    <Col span={16}>{course.packetDescription}</Col>
                </Row>
                <Divider orientation="left">Options de livraison</Divider>
                {deliveryOptions.map((deliveryOption) => (
                    <Row gutter={20}>
                        <Col span={16}>- {deliveryOption.name}</Col>
                    </Row>
                ))}
            </div>
        )
    }

    return (
        <Drawer
            title={"Course N°" + courseUuid}
            width={720}
            onClose={onCloseCourseInfo}
            visible={visibleDrawerCourseInfo}
            bodyStyle={{ paddingBottom: 80 }}
        >
            {drawerInfo}
        </Drawer>
    )
}

export default DrawerDetailsCourse;

import config from '../Config.js';

/**
 * 
 * @param {*} id 
 * @param {*} data 
 * @returns 
 */
export function saveDeliveryOption(data) {
    return fetch(config.hostApi + 'deliveryOption', {
        method: 'POST',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + localStorage.getItem('token')
        },
        body: JSON.stringify(data)
    })
}

/**
 * 
 * @param {*} id 
 * @param {*} data 
 * @returns 
 */
export function updateDeliveryOption(data) {
    return fetch(config.hostApi + 'deliveryOption', {
        method: 'PUT',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + localStorage.getItem('token')
        },
        body: JSON.stringify(data)
    })
}

/**
 * 
 * @param {*} id 
 * @returns 
 */
export function getDeliveryOptions(data) {
    return fetch(config.hostApi + 'deliveryOptions', {
        method: 'POST',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + localStorage.getItem('token')
        },
        body: JSON.stringify(data)
    })
}

/**
 * 
 * @param {*} id 
 * @returns 
 */
export function deleteDeliveryOption(option_id) {
    return fetch(config.hostApi + 'deliveryOption/' + option_id, {
        method: 'DELETE',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + localStorage.getItem('token')
        },
    })
}

/**
 * 
 * @param {*} id 
 * @returns 
 */
export function getDeliveryOption(option_id) {
    return fetch(config.hostApi + 'deliveryOption/' + option_id, {
        method: 'GET',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + localStorage.getItem('token')
        },
    })
}

/**
 * 
 * @param {*} id 
 * @returns 
 */
export function getCourseDeliveryOptions(data) {
    return fetch(config.hostApi + 'deliveryOptions/course', {
        method: 'POST',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + localStorage.getItem('token')
        },
        body: JSON.stringify(data)
    })
}

/**
 * 
 * @param {*} id 
 * @returns 
 */
export function deliveryOptionVisibility(id, data) {
    return fetch(config.hostApi + 'deliveryOption/' + id + '/deliveryOptionVisibility', {
        method: 'POST',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + localStorage.getItem('token')
        },
        body: JSON.stringify(data)
    })
}

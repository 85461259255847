import config from '../Config.js';


/**
 * 
 * @param {*} data 
 */
export function getManyDeliveryRules(data) {
    return fetch(config.hostApi + 'deliveryrules', {
        method: 'POST',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + localStorage.getItem('token')
        },
        body: JSON.stringify(data)
    })
}

/**
 * 
 * @param {*} id 
 */
export function getOneDeliveryRule(id) {
    return fetch(config.hostApi + 'deliveryrule/' + id, {
        method: 'GET',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + localStorage.getItem('token')
        },
    })
}

/**
 * 
 * @param {*} id 
 * @param {*} data 
 */
export function updateDeliveryRule(id, data) {
    return fetch(config.hostApi + 'deliveryrule/' + id, {
        method: 'PUT',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + localStorage.getItem('token')
        },
        body: JSON.stringify(data)
    })
}

/**
 * 
 * @param {*} data 
 */
export function postDeliveryRule(data) {
    return fetch(config.hostApi + 'deliveryrule', {
        method: 'POST',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + localStorage.getItem('token')
        },
        body: JSON.stringify(data)
    })
}

/**
 * 
 * @param {*} id 
 */
export function deleteDeliveryRule(id) {
    return fetch(config.hostApi + 'deliveryrule/' + id, {
        method: 'DELETE',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + localStorage.getItem('token')
        },
    })
}
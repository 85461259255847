import React, {useEffect, useRef} from 'react';
import {Link} from 'react-router-dom';
import moment from 'moment-timezone';

import {
    Button,
    Col,
    Divider,
    Drawer,
    Form,
    Image,
    notification,
    Popconfirm,
    Row,
    Select,
    Space,
    Timeline,
    Tooltip,
    Typography
} from 'antd';
import {DeleteOutlined, InfoCircleOutlined, WarningOutlined} from '@ant-design/icons';

import DrawerCourseDeliveryArea from '../../Components/Course/DrawerCourseDeliveryArea';
import DrawerCoursePickupArea from '../../Components/Course/DrawerCoursePickupArea';
import DrawerCoursePositionDriver from '../../Components/Course/DrawerCoursePositionDriver';

import mapboxgl from 'mapbox-gl';
import '@mapbox/mapbox-gl-draw/dist/mapbox-gl-draw.css';

import config from '../../Config';
import {translateStatus} from "../../constants";

import {
    courseCanceled,
    courseDelete,
    courseDraft,
    courseFailed,
    courseInProgress,
    courseIsDelivered,
    coursePickupFailed,
    courseReturned,
    coursesDriverLink,
    courseStarted
} from '../../Api/Courses';
import {getManyDrivers, getOneDriver} from '../../Api/Drivers';

mapboxgl.accessToken = config.mapboxToken;

const { Option } = Select;

const button_style = {
    backgroundcolor: '#2FAC66',
    'margin-right': '10px',
    'margin-top': '10px'
}

const layoutGeneral = {
    labelCol: {
        span: 12,
    },
    wrapperCol: {
        span: 12,
    },
};

/**
 *
 * @param {*} props
 * @returns
 */
const FormStatutTab = (props) => {
    const [form] = Form.useForm();

    let { course, deliveryArea, pickupArea, latitude, longitude, userStatutes, statutes, lastPoint, courseTracking } = props;

    const mapContainer = useRef();
    const [zoom, setZoom] = React.useState(12.5);
    const [disable, setDisable] = React.useState(false);
    const [statut, setStatut] = React.useState(null);
    const [valueDriver, setValueDriver] = React.useState(undefined);
    const [dataDriver, setDataDriver] = React.useState([]);
    const [visibleDrawerDriver, setVisibleDrawerDriver] = React.useState(false);
    const [disableDriver, setDisableDriver] = React.useState(false);
    const [valueDriverDrawer, setValueDriverDrawer] = React.useState(null);
    const [hiddenWarningDriver, setHiddenWarningDriver] = React.useState(true);
    const [driver, setDriver] = React.useState(null);
    const [visibleDeliveryAreaDrawer, setVisibleDeliveryAreaDrawer] = React.useState(false);
    const [visiblePickupAreaDrawer, setVisiblePickupAreaDrawer] = React.useState(false);
    const [visiblePositionDriverDrawer, setVisiblePositionDriverDrawer] = React.useState(false);

    const hiddenInfoDriver = (valueDriver !== undefined) ? false : true;

    const optionDriver = dataDriver.map(d => <Option key={d.id}>#{d.id} : {d.firstName} {d.lastName}</Option>);
    let allStatutes = [];
    let markers = [];

    /**
     * 
     * @param {*} value 
     */
    const handleSearchDriver = (value) => {
        if (value.length > 2) {
            let data = {
                term: value,
                status: ['valid']
            };
            getManyDrivers(data)
                .then((res) => res.json())
                .then((json) => {
                    setDataDriver(json.results)
                })
        }
    };

    /**
     * 
     * @param {*} values 
     */
    const handleChangeDriver = (values) => {
        if (values === undefined) {
            setValueDriver(undefined)
        } else {
            setValueDriver(values)
        }
    };

    /**
     * 
     */
    const onFinish = () => {
        let data = {
            coursesIds: [course.id],
            driverId: valueDriver.value
        };
        coursesDriverLink(data)
            .then((res) => res.json())
            .then(() => {
                notification['success']({
                    message: 'Le transporteur a bien été associé à la course.'
                });
                props.driverLink();
            })
    };

    /**
     * 
     */
    const showDrawerDriver = () => {
        setVisibleDrawerDriver(true)
        let driverId = valueDriver.value;
        getOneDriver(driverId)
            .then((res) => res.json())
            .then((json) => {
                setValueDriverDrawer(json.driver)
            })
    };

    /**
     * 
     */
    const onCloseDriver = () => {
        setVisibleDrawerDriver(false)
    };

    /**
    * 
    */
    const onClickDraft = () => {
        const courseId = course.id;
        courseDraft(courseId)
            .then((res) => res.json())
            .then((data) => {
                props.actionStatut(data);
            })
            .catch(() => {});
    };

    /**
     * 
     */
    const onClickAvailable = () => {
        props.availableAction(course.id);
    };

    /**
     * 
     */
    const onClickStarted = () => {
        const courseId = course.id;
        courseStarted(courseId)
            .then((res) => res.json())
            .then((data) => {
                props.actionStatut(data);
            })
            .catch(() => {});
    };

    /**
     * 
     */
    const onClickInProgress = () => {
        const courseId = course.id;
        courseInProgress(courseId)
            .then((res) => res.json())
            .then((data) => {
                props.actionStatut(data);
            })
            .catch(() => {});
    };

    /**
     * 
     */
    const onClickIsDelivered = () => {
        const courseId = course.id;
        courseIsDelivered(courseId)
            .then((res) => res.json())
            .then((data) => {
                props.actionStatut(data);
            })
            .catch(() => {});
    };

    /**
     * 
     */
    const onClickReturned = () => {
        const courseId = course.id;
        courseReturned(courseId)
            .then((res) => res.json())
            .then((data) => {
                props.actionStatut(data);
            })
            .catch(() => {});
    };

    /**
     * 
     */
    const onClickFailed = () => {
        const courseId = course.id;
        courseFailed(courseId)
            .then((res) => res.json())
            .then((data) => {
                props.actionStatut(data);
            })
            .catch(() => {});
    };

    /**
     * 
     */
    const onClickPickupFailed = () => {
        const courseId = course.id;
        coursePickupFailed(courseId)
            .then((res) => res.json())
            .then((data) => {
                props.actionStatut(data);
            })
            .catch(() => {});
    };

    /**
     * 
     */
    const onClickCanceled = () => {
        const courseId = course.id;
        courseCanceled(courseId)
            .then((res) => res.json())
            .then((data) => {
                props.actionStatut(data);
            })
            .catch(() => {});
    };

    /**
     * 
     */
    const confirmDeleteCourse = () => {
        const courseId = course.id;
        courseDelete(courseId)
            .then((res) => res.json())
            .then(() => {
                props.deleteCourse();
            })
            .catch(() => {});
    };

    /**
     *
     */
    const onCloseDeliveryAreaDrawer = () => {
        setVisibleDeliveryAreaDrawer(false);
    };

    /**
     *
     */
    const onClosePickupAreaDrawer = () => {
        setVisiblePickupAreaDrawer(false);
    };

    /**
     *
     */
    const onClosePositionDriverDrawer = () => {
        setVisiblePositionDriverDrawer(false);
    };


    /**
     *
     */
    const refresh = () => {
        if (props.refresh) props.refresh();
    };

    /**
     *
     * @param {*} map
     * @param {*} markers
     * @param {*} deliveryArea
     * @param {*} pickupArea
     * @param {*} lastPoint
     * @param {*} courseTracking
     * @returns
     */
    const buildMap = (map, markers, deliveryArea, pickupArea, lastPoint, courseTracking) => {
        for (var i = markers.length - 1; i >= 0; i--) {
            markers[i].remove();
        }

        if (map.getSource("route")) {
            map.removeSource("route");
        }

        if (map.getLayer("route")) {
            map.removeLayer("route");
        }

        let bounds = new mapboxgl.LngLatBounds();;
        let geojsonDelivery = deliveryArea;
        let geojsonPickup = pickupArea;
        let geojsonLastPoint = lastPoint;

        if (geojsonDelivery.length !== 0) {
            if (geojsonDelivery.features.length !== 0) {
                geojsonDelivery.features.forEach(function (markerDelivery) {
                    // create a HTML element for each feature
                    var el = document.createElement('div');
                    el.className = 'marker-delivery';

                    bounds.extend(markerDelivery.geometry.coordinates)
                    // make a marker for each feature and add to the map
                    let marker = new mapboxgl.Marker(el, {
                        offset: [5, -10]
                    })
                        .setLngLat(markerDelivery.geometry.coordinates)
                        .addTo(map);

                    markers.push(marker);

                    marker.getElement('el').addEventListener('click', () => {
                        setVisibleDeliveryAreaDrawer(true);
                    });
                });
            }
        }

        if (geojsonPickup.length !== 0) {
            if (geojsonPickup.features.length !== 0) {
                geojsonPickup.features.forEach(function (markerPickup) {
                    // create a HTML element for each feature
                    var el = document.createElement('div');
                    el.className = 'marker-pickup';

                    bounds.extend(markerPickup.geometry.coordinates);
                    // make a marker for each feature and add to the map
                    let marker = new mapboxgl.Marker(el, {
                        offset: [5, -10]
                    })
                        .setLngLat(markerPickup.geometry.coordinates)
                        .addTo(map);

                    markers.push(marker);

                    marker.getElement('el').addEventListener('click', () => {
                        setVisiblePickupAreaDrawer(true);
                    });
                });
            }
        }

        if (geojsonLastPoint.length !== 0) {
            geojsonLastPoint.features.forEach(function (markerDriver) {
                // create a HTML element for each feature
                var el = document.createElement('div');
                el.className = 'marker-driver';

                bounds.extend(markerDriver.geometry.coordinates);
                // make a marker for each feature and add to the map
                let marker = new mapboxgl.Marker(el, {
                    offset: [5, -10]
                })
                    .setLngLat(markerDriver.geometry.coordinates)
                    .addTo(map);

                markers.push(marker);

                marker.getElement('el').addEventListener('click', () => {
                    let driver = {
                        id: markerDriver.properties.driver_id,
                        identity: markerDriver.properties.title,
                        lastTrackingDateTime: markerDriver.properties.lastTracking,
                        phone: markerDriver.properties.phone
                    };

                    setDriver(driver);
                    setVisiblePositionDriverDrawer(true);
                });
            });
        }

        if (Object.keys(bounds).length !== 0) {
            map.fitBounds(bounds, { padding: 100, animate: false });
        }

        map.scrollZoom.disable();
        map.scrollZoom.setWheelZoomRate(0.02); // Default 1/450

        map.on("wheel", (event) => {
            if (event.originalEvent.ctrlKey) {
                // Check if CTRL key is pressed
                event.originalEvent.preventDefault(); // Prevent chrome/firefox default behavior
                if (!map.scrollZoom._enabled) map.scrollZoom.enable(); // Enable zoom only if it's disabled
            } else {
                if (map.scrollZoom._enabled) map.scrollZoom.disable(); // Disable zoom only if it's enabled
            }
        });

        let courseTrackingCoordinates = courseTracking;

        if (courseTrackingCoordinates.length > 0) {

            map.on('load', function () {
                map.addSource('route', {
                    'type': 'geojson',
                    'data': {
                        'type': 'Feature',
                        'properties': {},
                        'geometry': {
                            'type': 'LineString',
                            'coordinates': courseTrackingCoordinates
                        }
                    }
                });
                map.addLayer({
                    'id': 'route',
                    'type': 'line',
                    'source': 'route',
                    'layout': {
                        'line-join': 'round',
                        'line-cap': 'round'
                    },
                    'paint': {
                        'line-color': 'red',
                        'line-width': 2
                    }
                });
            });
        }

        // Clean up on unmount
        return () => map.remove();
    };

    /**
     *
     */
    useEffect(() => {

        if (latitude && longitude) {
            const map = new mapboxgl.Map({
                container: mapContainer.current,
                style: 'mapbox://styles/mapbox/streets-v11',
                center: [longitude, latitude],
                zoom: zoom
            });

            buildMap(map, markers, deliveryArea, pickupArea, lastPoint, courseTracking);
        }

        if (course !== null) {
            setStatut(translateStatus[course.status]);

            if (course.price === null) {
                setHiddenWarningDriver(false);
            }

            if (course.driver) {
                setValueDriver({
                    key: course.driver.id,
                    value: course.driver.id,
                    label: '#' + course.driver.id + ' : ' + course.driver.firstName + ' ' + course.driver.lastName,
                })
                form.setFieldsValue({
                    driver_id: {
                        key: course.driver.id,
                        value: course.driver.id,
                        label: '#' + course.driver.id + ' : ' + course.driver.firstName + ' ' + course.driver.lastName,
                    }
                })
            }
        }
    }, [deliveryArea, pickupArea, course, lastPoint, courseTracking]);

    statutes.forEach((status) => {
        if (status.comment === null) {
            status.comment = 'Aucun Commentaire'
        }

        userStatutes.forEach((user) => {
            if (user.id === status.user_id) {
                status.user = user;
            }
        });

        allStatutes.push({ ...status });
    });

    let driverDrawer = null;
    if (valueDriverDrawer === null) {
        driverDrawer = (
            <Drawer
                title="Informations du transporteur"
                width={720}
                onClose={onCloseDriver}
                visible={visibleDrawerDriver}
                bodyStyle={{ paddingBottom: 80 }}
            >
                Aucune information
            </Drawer>
        );
    } else {
        driverDrawer = (
            <Drawer
                title="Informations du transporteur"
                width={720}
                onClose={onCloseDriver}
                visible={visibleDrawerDriver}
                bodyStyle={{ paddingBottom: 80 }}
            >
                <Row>
                    <Col span={6}>Nom :</Col>
                    <Col span={12}>{valueDriverDrawer.lastName}</Col>
                </Row>
                <Row>
                    <Col span={6}>Prénom :</Col>
                    <Col span={16}>{valueDriverDrawer.firstName}</Col>
                </Row>
                <Row>
                    <Col span={6}>Adresse  :</Col>
                    <Col span={12}>{valueDriverDrawer.address}</Col>
                </Row>
                <Row>
                    <Col span={6}>Email :</Col>
                    <Col span={12}>{valueDriverDrawer.email}</Col>
                </Row>
                <Row>
                    <Col span={6}>Téléphone :</Col>
                    <Col span={12}>{valueDriverDrawer.phone}</Col>
                </Row>
                <Button type='primary' style={{ marginTop: '20px' }}>
                    <Link to={'/driver/' + valueDriverDrawer.id}>Détail</Link>
                </Button>
            </Drawer>
        );
    }

    return (
        <div>
            <div className="site-layout-background" style={{ padding: 24, minHeight: 150 }}>
                <Form form={form} name="layout" onFinish={onFinish} layout="vertical">
                    <Form.Item {...layoutGeneral}
                        name="driver_id"
                        label={
                            <div>
                                Sélectionner un transporteur
                                <Tooltip >
                                    <Typography.Link
                                        style={{ marginLeft: '20px' }}
                                        onClick={showDrawerDriver}
                                        hidden={hiddenInfoDriver}
                                    >
                                        <InfoCircleOutlined />
                                    </Typography.Link>
                                </Tooltip>
                                <Tooltip
                                    placement="top"
                                    title="Attention, la course n'a aucun tarif."
                                >
                                    <WarningOutlined
                                        hidden={hiddenWarningDriver}
                                        style={{ color: 'red', marginLeft: '5px' }}
                                    />
                                </Tooltip>
                            </div>
                        }
                    >
                        <Select
                            labelInValue={true}
                            showSearch
                            value={valueDriver}
                            placeholder="Entrer au minimum 3 caractères"
                            showArrow={true}
                            filterOption={false}
                            onSearch={handleSearchDriver}
                            onChange={handleChangeDriver}
                            notFoundContent={null}
                            allowClear
                            disabled={disableDriver}
                        >
                            {optionDriver}
                        </Select>
                    </Form.Item>
                    {driverDrawer}
                    <Space>
                        <Button type="primary" htmlType="submit">
                            Assigner la course
                        </Button>
                        <Button style={{ backgroundcolor: '#2FAC66' }}>
                            <Link to='/courses'>Annuler</Link>
                        </Button>
                    </Space>
                </Form>
            </div>
            <div className="site-layout-background" style={{ padding: 24, minHeight: 360 }}>
                <Divider orientation="left">Carte</Divider>
                <div className='map-page-container'>
                    <div ref={mapContainer} className="map-container" />
                </div>
            </div>
            <div className="site-layout-background" style={{ padding: 24, minHeight: 200 }}>
                <Divider orientation="left">Statut</Divider>
                <Space direction="vertical">
                    <div>Statut actuel</div>
                    <Space >
                        <Button type="primary" {...button_style} disabled>
                            {statut}
                        </Button>
                    </Space>
                    <div>Actions possibles</div>
                    <Space wrap>
                        <Button {...button_style} onClick={onClickDraft}>
                            Brouillon
                        </Button>
                        <Button {...button_style} onClick={onClickAvailable} disabled={disable}>
                            Emettre
                        </Button>
                        <Button {...button_style} onClick={onClickStarted} disabled={disable}>
                            Démarrer
                        </Button>
                        <Button {...button_style} onClick={onClickInProgress} disabled={disable}>
                            Colis récupéré
                        </Button>
                        <Button {...button_style} onClick={onClickIsDelivered} disabled={disable}>
                            Colis livré
                        </Button>
                        <Button {...button_style} onClick={onClickReturned} disabled={disable}>
                            Retourné
                        </Button>
                        <Button {...button_style} type="primary" danger onClick={onClickFailed} disabled={disable}>
                            Echouée (Livraison)
                        </Button>
                        <Button {...button_style} type="primary" danger onClick={onClickPickupFailed} disabled={disable}>
                            Echoué (Retrait)
                        </Button>
                        <Button {...button_style} type="primary" danger onClick={onClickCanceled}>
                            Annuler
                        </Button>
                        <Popconfirm
                            title="Etes-vous sûr de vouloir supprimer cette course ?"
                            onConfirm={confirmDeleteCourse}
                            okText="Oui"
                            cancelText="Non"
                        >
                            <Button type="primary" danger style={{ backgroundcolor: '#2FAC66' }}>
                                <DeleteOutlined />Supprimer
                            </Button>
                        </Popconfirm>
                    </Space>
                </Space>
            </div>
            <div className="site-layout-background" style={{ padding: 24, minHeight: 360 }}>
                <Divider orientation="left">Historique</Divider>
                <Timeline mode='left'>
                    {allStatutes.map((statut) => {
                        let dateTz = moment.utc(statut.created_at).tz('Europe/Paris').format('DD/MM/YYYY HH:mm:ss');

                        let user = null;
                        if (statut.user) {
                            user = <p>Utilisateur : {statut.user.name}</p>;
                        }

                        let signature = null;
                        if (statut.signature !== null) {
                            let picture = statut.signature.picture;
                            let src = picture;
                            signature = (
                                <div>
                                    <Image height={200} src={src} />
                                    <p>{statut.signature.firstname} {statut.signature.lastname}</p>
                                </div>
                            );
                        }

                        let photo = null;
                        if (statut.photo_path !== null) {
                            photo = (
                                <Image height={200} src={config.hostApp + '/photos/' + statut.photo_path} />
                            );
                        }

                        return (
                            <Timeline.Item label={dateTz} key={"key_" + statut + "_" + dateTz}>
                                <p>{translateStatus[statut.name]}</p>
                                {user}
                                <p>Commentaire : {statut.comment} </p>
                                {signature}
                                {photo}
                            </Timeline.Item>
                        );
                    })}
                </Timeline>
            </div>
            <DrawerCourseDeliveryArea
                course={course}
                visible={visibleDeliveryAreaDrawer}
                fromView={'course'}
                onClose={onCloseDeliveryAreaDrawer}
                refresh={refresh}
            />
            <DrawerCoursePickupArea
                course={course}
                visible={visiblePickupAreaDrawer}
                fromView={'course'}
                onClose={onClosePickupAreaDrawer}
                refresh={refresh}
            />
            <DrawerCoursePositionDriver
                driver={driver}
                visible={visiblePositionDriverDrawer}
                fromView={'course'}
                onClose={onClosePositionDriverDrawer}
            />
        </div>
    );
}

export default FormStatutTab;

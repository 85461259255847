import React, { useEffect } from 'react';

import { Form, Input, Button, Space, Drawer, notification, Checkbox, InputNumber, message } from 'antd';

import { getDeliveryOption, saveDeliveryOption, updateDeliveryOption } from '../../Api/DeliveryOptions.js';
import { getFloatPrice } from "../../utils";

/**
 *
 * @param {*} props
 * @returns
 */
const DeliveryOptionsDrawer = (props) => {
    let { network, merchant, merchanttheme, id, showDeliveryOptionsDrawer, action } = props;

    const [form] = Form.useForm();

    let [visibleDeliveryOptionsDrawer, setVisibleDeliveryOptionsDrawer] = React.useState(false);
    let [option, setOption] = React.useState(null);
    let [disable, setDisable] = React.useState(false);

    /**
     *
     */
    const onCloseOptionsForm = () => {
        setVisibleDeliveryOptionsDrawer(false);
        form.resetFields();
        props.showDrawer(false);
    };

    /**
     *
     */
    const fetchDeliveryOption = () => {
        getDeliveryOption(id)
            .then(res => res.json())
            .then(json => {
                setOption(json);
                let optionName = json.option_name;
                if (json.option_name === null && json.parent !== null) {
                    optionName = json.parent.option_name;
                }

                form.setFieldsValue({
                    option_name: optionName,
                    option_price: json.option_price,
                    visible: json.visible
                });

                if (json.is_admin === 1 || json.parent_id !== null) {
                    setDisable(true);
                }
                if (json.origin_name === 'network' && (merchant !== undefined || merchanttheme !== undefined)) {
                    setDisable(true);
                }
                if (json.origin_name === 'merchantTheme' && (merchant !== undefined || merchanttheme !== undefined)) {
                    setDisable(true);
                }
            });
    }

    /**
     *
     */
    const onFinish = () => {
        form.validateFields()
            .then(async (values) => {
                let newOption = option !== null ? {...option} : {};

                newOption.network_id = (network) ? network.id : null;
                newOption.merchant_id = (merchant) ? merchant.id : null;
                newOption.merchantTheme_id = (merchanttheme) ? merchanttheme.id : null;
                newOption.option_price = getFloatPrice(values.option_price);
                newOption.visible = values.visible;

                if (action === 'save') {
                    newOption.id = null;
                    newOption.is_admin = false;
                    newOption.option_name = values.option_name;

                    saveDeliveryOption(newOption)
                        .then(res => res.json())
                        .then(() => {
                            notification['success']({
                                message: 'L\'option a bien été enregistrée'
                            });
                            setVisibleDeliveryOptionsDrawer(false);
                            props.showDrawer(false);
                        });

                } else if (action === 'update') {
                    if (option.is_admin === 1 && option.parent_id === null) {
                        newOption.id = null;
                        newOption.parent_id = option.id;
                        newOption.is_admin = false;
                        newOption.option_name = null;

                        saveDeliveryOption(newOption)
                            .then(res => res.json())
                            .then(() => {
                                notification['success']({
                                    message: 'L\'option a bien été enregistrée'
                                });
                                setVisibleDeliveryOptionsDrawer(false);
                                props.showDrawer(false);
                            });

                    } else if (option.is_admin === 0) {
                        if ((merchant !== undefined || merchanttheme !== undefined) && option.origin_name === 'network') {
                            newOption.id = null;
                            newOption.parent_id = option.id;
                            newOption.is_admin = false;
                            newOption.option_name = null;

                            saveDeliveryOption(newOption)
                                .then(res => res.json())
                                .then(() => {
                                    notification['success']({
                                        message: 'L\'option a bien été enregistrée'
                                    });
                                    setVisibleDeliveryOptionsDrawer(false);
                                    props.showDrawer(false);
                                });

                        } else if ((merchant !== undefined) && option.origin_name === 'merchantTheme') {
                            newOption.id = null;
                            newOption.parent_id = option.id;
                            newOption.is_admin = false;
                            newOption.option_name = null;

                            saveDeliveryOption(newOption)
                                .then(res => res.json())
                                .then(() => {
                                    notification['success']({
                                        message: 'L\'option a bien été enregistrée'
                                    });
                                    setVisibleDeliveryOptionsDrawer(false);
                                    props.showDrawer(false);
                                });

                        } else {
                            newOption.id = option.id;
                            newOption.is_admin = option.is_admin;
                            newOption.parent_id = option.parent_id;

                            if (option.parent_id === null) {
                                newOption.option_name = values.option_name;
                            } else {
                                newOption.option_name = null;
                            }

                            updateDeliveryOption(newOption)
                                .then(res => res.json())
                                .then(() => {
                                    notification['success']({
                                        message: 'L\'option a bien été mise à jour'
                                    });
                                    setVisibleDeliveryOptionsDrawer(false);
                                    props.showDrawer(false);
                                });
                        }
                    }
                }
            })
            .catch(() => {
                message.error("Champs manquants");
            });
    };

    /**
     * 
     */
    useEffect(() => {
        form.setFieldsValue({
            option_name: null,
            option_price: null,
            visible: true,
        });

        if (showDeliveryOptionsDrawer === true) {
            setVisibleDeliveryOptionsDrawer(true);
        }

        if (action === 'save') {
            setDisable(false);
        }

        if (id !== null && action === 'update') {
            fetchDeliveryOption();
        }

    }, [showDeliveryOptionsDrawer, id, action]);

    return (
        <Drawer
            title="Création des options de livraison"
            width={720}
            onClose={onCloseOptionsForm}
            visible={visibleDeliveryOptionsDrawer}
            bodyStyle={{ paddingBottom: 80 }}
            footer={
                <Space>
                    <Button onClick={onCloseOptionsForm} style={{ marginRight: 8 }}>
                        Annuler
                    </Button>
                    <Button onClick={onFinish} type="primary">
                        Enregistrer
                    </Button>
                </Space>
            }
        >
            <Form form={form} name="delivery_options" layout="vertical" onFinish={onFinish}>
                <Form.Item
                    label="Nom"
                    name="option_name"
                    rules={[{
                        required: true,
                        message: "Merci d\'indiquer un nom."
                    }]}
                >
                    <Input disabled={disable} />
                </Form.Item>
                <Form.Item
                    label="Prix"
                    name="option_price"
                    rules={[{
                        required: true,
                        message: "Merci d\'indiquer un prix."
                    }]}
                >
                    <InputNumber
                        style={{ width: "100%" }}
                        placeholder="3000"
                        prefix="€"
                        min={0}
                        precision={2}
                        formatter={value => value.replace('.', ',')}
                        parser={value => value.replace(',', '.')}
                    />
                </Form.Item>
                <Form.Item name="visible" valuePropName="checked">
                    <Checkbox>Visible</Checkbox>
                </Form.Item>
            </Form>
        </Drawer>
    );
}

export default DeliveryOptionsDrawer;
import React, { useEffect } from 'react';
import { Form, Button, Space, Drawer, notification, InputNumber, message } from 'antd';

import { getOneDeliveryRule, postDeliveryRule, updateDeliveryRule } from '../../Api/DeliveryRules';
import { getFloatPrice } from '../../utils';

const DeliveryRulesDrawerForm = ({ visible, onCloseDeliveryRuleDrawer, onCreatedDeliveryRule, deliveryRuleId, type, network, merchant, merchanttheme }) => {
    const [form] = Form.useForm();

    const [title, setTitle] = React.useState(null);
    const [label, setLabel] = React.useState(null);

    /**
     * 
     */
    const onSubmitDeliveryRule = () => {
        form.validateFields()
            .then(async (values) => {
                let deliveryRule = {
                    network_id: (network) ? network.id : null,
                    merchant_id:  (merchant) ? merchant.id : null,
                    merchanttheme_id: (merchanttheme) ? merchanttheme.id : null,
                    type: type,
                    time: values.time * 60,
                    price: getFloatPrice(values.price),
                };

                if (!deliveryRuleId) {
                    postDeliveryRule(deliveryRule)
                        .then(res => res.json())
                        .then(() => {
                            notification['success']({
                                message: 'La règle a bien été enregistrée.'
                            });
                            onCreatedDeliveryRule();
                            form.resetFields();
                        });
                } else {
                    deliveryRule.id = deliveryRuleId;

                    updateDeliveryRule(deliveryRuleId, deliveryRule)
                        .then(res => res.json())
                        .then(() => {
                            notification['success']({
                                message: 'La règle a bien été mise à jour.'
                            });
                            onCreatedDeliveryRule();
                            form.resetFields();
                        });
                }
            })
            .catch(() => {
                message.error("Champs manquants");
            });
    };

    /**
     * 
     */
    const fetchOneDeliveryRule = () => {
        getOneDeliveryRule(deliveryRuleId)
            .then(res => res.json())
            .then(json => {
                let formValues = {
                    time: json.time / 60,
                    price: json.price,
                };

                form.setFieldsValue(formValues);
            });
    };

    /**
     * 
     */
    useEffect(() => {
        form.resetFields();

        if (deliveryRuleId) {
            fetchOneDeliveryRule();
        }

        if (type === 'slots') {
            setTitle('Configuration des créneaux de livraison');
            setLabel("Temps entre l'heure de collecte et l'heure de livraison");
        } else {
            setTitle('Configuration des délais de livraison');
            setLabel("Temps entre l'heure où la course est passée et l'heure de livraison");
        }
    }, [deliveryRuleId, type]);

    return (
        <Drawer
            title={title}
            width={650}
            onClose={onCloseDeliveryRuleDrawer}
            visible={visible}
            bodyStyle={{ paddingBottom: 80 }}
            footer={
                <Space>
                    <Button onClick={onCloseDeliveryRuleDrawer} style={{ marginRight: 8 }}>
                        Annuler
                    </Button>
                    <Button onClick={onSubmitDeliveryRule} type="primary">
                        Enregistrer
                    </Button>
                </Space>
            }
        >
            <Form form={form} name="delivery_rules" layout="vertical">
                <Form.Item label={label} name="time">
                    <InputNumber
                        style={{ width: "100%" }}
                        placeholder="90"
                        prefix="min"
                        min={0}
                        precision={0}
                        formatter={value => value.replace('.', ',')}
                        parser={value => value.replace(',', '.')}
                    />
                </Form.Item>
                <Form.Item
                    label="Prix"
                    name="price"
                    rules={[{
                        required: true,
                        message: "Merci d\'indiquer un prix."
                    }]}
                >
                    <InputNumber
                        style={{ width: "100%" }}
                        placeholder="3000"
                        prefix="€"
                        min={0}
                        precision={2}
                        formatter={value => value.replace('.', ',')}
                        parser={value => value.replace(',', '.')}
                    />
                </Form.Item>
            </Form>
        </Drawer>
    );
};

export default DeliveryRulesDrawerForm;
import config from '../Config.js';


/**
 * 
 * @param {*} data 
 */
export function getManyTransportRules(data) {
    return fetch(config.hostApi + 'transportrules', {
        method: 'POST',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + localStorage.getItem('token')
        },
        body: JSON.stringify(data)
    })
}

/**
 * 
 * @param {*} id 
 */
export function getOneTransportRule(id) {
    return fetch(config.hostApi + 'transportrule/' + id, {
        method: 'GET',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + localStorage.getItem('token')
        },
    })
}

/**
 * 
 * @param {*} id 
 * @param {*} data 
 */
export function updateTransportRule(id, data) {
    return fetch(config.hostApi + 'transportrule/' + id, {
        method: 'PUT',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + localStorage.getItem('token')
        },
        body: JSON.stringify(data)
    })
}

/**
 * 
 * @param {*} data 
 */
export function postTransportRule(data) {
    return fetch(config.hostApi + 'transportrule', {
        method: 'POST',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + localStorage.getItem('token')
        },
        body: JSON.stringify(data)
    })
}

/**
 * 
 * @param {*} id 
 */
export function deleteTransportRule(id) {
    return fetch(config.hostApi + 'transportrule/' + id, {
        method: 'DELETE',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + localStorage.getItem('token')
        },
    })
}
import React, {useEffect} from 'react';
import {Link} from 'react-router-dom';

import {Button, Divider, Form, Input, Space} from 'antd';

const layout = {
    labelCol: {
        span: 8,
    },
    wrapperCol: {
        span: 8,
    },
};

/**
 * 
 * @param {*} props 
 * @returns 
 */
const FormCommissionTab = (props) => {

    let { merchanttheme } = props;

    const [form] = Form.useForm();

    /**
     * 
     * @param {*} values 
     */
    const onFinish = (values) => {
        let number = parseFloat(values.commission_price);
        let newMerchantTheme = { ...merchanttheme };
        if (newMerchantTheme.commission === null) {
            newMerchantTheme.commission = {
                id: null,
                commission_price: (number + 100) / 100
            };
        } else {
            newMerchantTheme.commission.commission_price = (number + 100) / 100;
        }

        props.formSubmit(newMerchantTheme);
    };

    /**
     * 
     */
    useEffect(() => {
        if (merchanttheme !== null) {
            let formValues = {
                commission_price: null,
            };

            if (merchanttheme.commission !== null) {
                let number = parseFloat(merchanttheme.commission.commission_price)
                formValues.commission_price = Math.round((number * 100) - 100);
            }
            form.setFieldsValue(formValues);
        }
    }, [merchanttheme])

    return (
        <Form form={form} name="layout" onFinish={onFinish} layout='vertical'>
            <Form.Item {...layout} label="Commission" name="commission_price">
                <Input name="commission_price" suffix="%" />
            </Form.Item>
            <Divider></Divider>
            <Form.Item>
                <Space>
                    <Button type="primary" htmlType="submit">
                        Enregistrer
                    </Button>
                    <Button style={{ backgroundcolor: '#2FAC66' }}>
                        <Link to='/merchantthemes'>Annuler</Link>
                    </Button>
                </Space>
            </Form.Item>
        </Form>
    )
}

export default FormCommissionTab;

import React from 'react';
import {Link} from 'react-router-dom';
import moment from 'moment';

import {Button, Divider, Layout, PageHeader, Select, Space, Table} from 'antd';
import {CopyOutlined, FormOutlined, PlusCircleOutlined, UnorderedListOutlined} from '@ant-design/icons';

import {duplicateMerchantTheme, getManyMerchantThemes} from '../../Api/MerchantThemes.js';
import {getManyWhiteLabels} from '../../Api/WhiteLabels.js';
import {GlobalContext} from '../../GlobalContext.js';

class MerchantThemes extends React.Component {
    static contextType = GlobalContext;
    /**
     * 
     * @param {*} props 
     */
    constructor(props) {
        super(props);

        this.state = {
            merchantThemes: [],
            pagination: {
                current: 1,
                pageSize: 10,
            },
            loading: false,
            data: [],
            value: undefined
        };

        this.columns = [
            {
                title: '#',
                dataIndex: 'id',
                key: 'id',
                width: 50,
            },
            {
                title: 'Nom',
                dataIndex: 'name',
                sorter: true,
                key: 'name',
            },
            {
                title: 'Marque blanche',
                dataIndex: 'white_label',
                key: 'white_label',
                render: (white_label) => {
                    if (white_label == null) {
                        return (
                            <div></div>
                        )
                    } else {
                        return (
                            <div>{white_label.name}</div>
                        )
                    }
                }
            },
            {
                title: 'Date de création',
                dataIndex: 'created_at',
                key: 'created_at',
                render: (created_at) => (
                    <div>{moment(created_at, 'YYYY-MM-DD hh:mm:ss').format('DD/MM/YYYY à hh:mm')}</div>
                )
            },
            {
                title: '',
                dataIndex: 'id',
                key: 'id',
                width: 100,
                render: (id) => (
                    <Space size="middle">
                        <Button type='primary'>
                            <Link to={'/merchanttheme/' + id}><FormOutlined /> Editer</Link>
                        </Button>
                        <Button type='primary' onClick={() => this.onClickDuplicateMerchantTheme(id)}>
                            <CopyOutlined /> Dupliquer
                        </Button>
                    </Space>
                )
            }
        ];

        this.handleSearch = this.handleSearch.bind(this);
        this.onClickWhiteLabelFilter = this.onClickWhiteLabelFilter.bind(this);
        this.onChangeWhiteLabel = this.onChangeWhiteLabel.bind(this);
        this.onClickDeleteFilter = this.onClickDeleteFilter.bind(this);
        this.onClickDuplicateMerchantTheme = this.onClickDuplicateMerchantTheme.bind(this);
    }

    /**
     * 
     * @param {*} params 
     */
    loadMerchantThemes(params = {}) {
        this.setState({ loading: true })
        getManyMerchantThemes(params)
            .then((res) => res.json())
            .then((json) => {
                this.setState({
                    loading: false,
                    merchantThemes: json.results,
                    pagination: {
                        ...params.pagination,
                        total: json.totalCount,
                    },
                })
            })
    }

    /**
     * 
     */
    componentDidMount() {
        const { pagination } = this.state;
        this.loadMerchantThemes({ pagination });
    }

    /**
     * 
     * @param {*} pagination 
     * @param {*} filters 
     * @param {*} sorter 
     */
    handleTableChange = (pagination, filters, sorter) => {
        this.loadMerchantThemes({
            whiteLabel_id: this.state.value,
            sortField: sorter.field,
            sortOrder: sorter.order,
            pagination,
        });
    };

    /**
     * 
     */
    onClickWhiteLabelFilter() {
        const pagination = this.state.pagination;
        let value = this.state.value;
        let data = {
            pagination: pagination,
            whiteLabel_id: value
        }
        getManyMerchantThemes(data)
            .then((res) => res.json())
            .then((json) => {
                this.setState({
                    merchantThemes: json.results,
                    pagination: {
                        ...this.state.pagination,
                        total: json.totalCount,
                    },
                });
            });
    }

    /**
     * 
     */
    onClickDeleteFilter() {
        const pagination = {
            current: 1,
            pageSize: 10
        };
        this.loadMerchantThemes({ pagination });
    }

    /**
     * 
     * @param {*} value 
     */
    handleSearch = (value) => {
        if (value.length > 2) {
            getManyWhiteLabels({
                term: value
            })
                .then((res) => res.json())
                .then((json) => {
                    this.setState({ data: json.results });
                })
        }
    }

    /**
     * 
     * @param {*} value 
     */
    onChangeWhiteLabel(value) {
        this.setState({ value })
    }

    onClickDuplicateMerchantTheme(id) {
        duplicateMerchantTheme(id)
            .then((res) => res.json())
            .then(() => {
                const { pagination } = this.state;
                this.loadMerchantThemes({ pagination });
            })
    }

    render() {
        const { merchantThemes, pagination, loading } = this.state;
        const { Content } = Layout;
        const { Option } = Select;

        const user = this.context.global.profile;

        let filterWhiteLabel = null;
        if (user !== null && user.whiteLabel_id === null) {
            filterWhiteLabel = (
                <div>
                    <Space>
                        <div>Marques blanches </div>
                        <Select
                            style={{ width: 300 }}
                            showSearch
                            value={this.state.value}
                            placeholder="Entrer au minimum 3 caractères"
                            showArrow={true}
                            filterOption={false}
                            onSearch={this.handleSearch}
                            onChange={this.onChangeWhiteLabel}
                            notFoundContent={null}
                        >
                            {this.state.data.map((d) => (
                                <Option key={d.id}>{d.name}</Option>
                            ))}
                        </Select>
                        <Button type="primary" onClick={this.onClickWhiteLabelFilter}>
                            Filtrer
                        </Button>
                        <Button style={{ backgroundcolor: "#2FAC66" }} onClick={this.onClickDeleteFilter}>
                            Annuler
                        </Button>
                    </Space>
                    <Divider></Divider>
                </div>
            )
        }

        return (
            <div>
                <PageHeader title="Thèmes commerçants" subTitle="Liste des thèmes commerçants" />
                <Content style={{ margin: '0 16px 16px 16px' }}>
                    <div className="site-layout-background" style={{ padding: 24, minHeight: 360 }}>
                        <h2 style={{ color: "#2FAC66" }}><UnorderedListOutlined /> LISTE</h2>
                        <div className="button-add">
                            <Space>
                                <Button>
                                    <Link to='/merchanttheme/add'><PlusCircleOutlined style={{ paddingRight: "5px" }} /> Ajouter</Link>
                                </Button>
                            </Space>
                        </div>
                        <Divider></Divider>
                        {filterWhiteLabel}
                        <Table columns={this.columns} loading={loading} onChange={this.handleTableChange} pagination={pagination} dataSource={merchantThemes} />
                    </div>
                </Content>
            </div>
        )
    }
}

export default MerchantThemes;

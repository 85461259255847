import React, {useEffect, useRef} from 'react';
import {DatePicker, Layout} from 'antd';

import mapboxgl from 'mapbox-gl';
import '@mapbox/mapbox-gl-draw/dist/mapbox-gl-draw.css';

import DrawerCourseDeliveryArea from '../../Components/Course/DrawerCourseDeliveryArea';
import DrawerCoursePickupArea from '../../Components/Course/DrawerCoursePickupArea';

import {getOneCourse} from '../../Api/Courses';

import moment from 'moment';
import config from '../../Config.js';

mapboxgl.accessToken = config.mapboxToken;

const { Content } = Layout;
const { RangePicker } = DatePicker;

const radioStyle = {
    display: "block",
    height: "30px",
    lineHeight: "30px",
    textAlign: "left",
};

/**
 *
 * @param {*} props
 * @returns
 */
const FormMapTab = (props) => {

    let { merchant, deliveryArea, pickupArea, lastPoint, latitude, longitude } = props;

    const mapContainer = useRef();

    let [zoom, setZoom] = React.useState(12.5);
    let [deliveryDates, setDeliveryDates] = React.useState([moment(), moment()]);
    const [course, setCourse] = React.useState(null);
    const [visibleDeliveryAreaDrawer, setVisibleDeliveryAreaDrawer] = React.useState(false);
    const [visiblePickupAreaDrawer, setVisiblePickupAreaDrawer] = React.useState(false);

    let markers = [];

    /**
     *
     * @param {*} e
     */
    const onChangeTracking = (e) => {
        setDeliveryDates(e);
        props.trackingChange(e);
    };

    /**
     *
     */
    const onCloseDeliveryAreaDrawer = () => {
        setVisibleDeliveryAreaDrawer(false);
        setCourse(null);
    };

    /**
     *
     */
    const onClosePickupAreaDrawer = () => {
        setVisiblePickupAreaDrawer(false);
        setCourse(null);
     };

    /**
     *
     * @param {*} id
     */
    const loadCourse = (id) => {
        return getOneCourse(id)
            .then((res) => res.json())
            .then((json) => {
                setCourse(json.course);
            });
    };

    /**
     *
     */
    const refresh = () => {
        loadCourse(course.id);
    };

    const buildMap = (map, markers, deliveryArea, pickupArea, lastPoint) => {
        for (var i = markers.length - 1; i >= 0; i--) {
            markers[i].remove();
        }

        let bounds = new mapboxgl.LngLatBounds();;
        let geojsonDelivery = deliveryArea;
        let geojsonPickup = pickupArea;

        if (geojsonDelivery.length !== 0) {
            if (geojsonDelivery.features.length !== 0) {
                geojsonDelivery.features.forEach(function (markerDelivery) {
                    // create a HTML element for each feature
                    var el = document.createElement('div');
                    el.className = 'marker-delivery';

                    bounds.extend(markerDelivery.geometry.coordinates)
                    // make a marker for each feature and add to the map
                    let marker = new mapboxgl.Marker(el, {
                        offset: [5, -10]
                    })
                        .setLngLat(markerDelivery.geometry.coordinates)
                        .addTo(map);

                    markers.push(marker);

                    marker.getElement('el').addEventListener('click', () => {
                        let courseId = markerDelivery.properties.course_id;
                        loadCourse(courseId).then(() => {
                            setVisibleDeliveryAreaDrawer(true);
                        });
                    });
                });
            }
        }
        if (geojsonPickup.length !== 0) {
            if (geojsonPickup.features.length !== 0) {
                geojsonPickup.features.forEach(function (markerPickup) {
                    // create a HTML element for each feature
                    var el = document.createElement('div');
                    el.className = 'marker-pickup';

                    bounds.extend(markerPickup.geometry.coordinates);
                    // make a marker for each feature and add to the map
                    let marker = new mapboxgl.Marker(el, {
                        offset: [5, -10]
                    })
                        .setLngLat(markerPickup.geometry.coordinates)
                        .addTo(map);

                    markers.push(marker);

                    marker.getElement('el').addEventListener('click', () => {
                        let courseId = markerPickup.properties.course_id;
                        loadCourse(courseId).then(() => {
                            setVisiblePickupAreaDrawer(true);
                        });
                    });
                });
            }
        }

        if (Object.keys(bounds).length !== 0) {
            map.fitBounds(bounds, { padding: 100, animate: false });
        }

        map.scrollZoom.disable();
        map.scrollZoom.setWheelZoomRate(0.02); // Default 1/450

        map.on("wheel", (event) => {
            if (event.originalEvent.ctrlKey) {
                // Check if CTRL key is pressed
                event.originalEvent.preventDefault(); // Prevent chrome/firefox default behavior
                if (!map.scrollZoom._enabled) map.scrollZoom.enable(); // Enable zoom only if it's disabled
            } else {
                if (map.scrollZoom._enabled) map.scrollZoom.disable(); // Disable zoom only if it's enabled
            }
        });
        // Clean up on unmount
        return () => map.remove();
    };

    /**
     *
     */
    useEffect(() => {

        if (latitude && longitude) {
            const map = new mapboxgl.Map({
                container: mapContainer.current,
                style: 'mapbox://styles/mapbox/streets-v11',
                center: [longitude, latitude],
                zoom: zoom
            });
    
            buildMap(map, markers, deliveryArea, pickupArea, lastPoint);
        }
    }, [deliveryArea, pickupArea, lastPoint]);

    return (
        <div>
            <Content>
                <div>
                    <span style={{ marginRight: '20px' }}>Date de livraison entre le : </span>
                    <RangePicker
                        style={{ width: '500px' }}
                        format={'DD/MM/YYYY'}
                        allowClear={false}
                        value={deliveryDates}
                        onChange={onChangeTracking}
                    />
                    <div style={{ marginTop: "16px" }} className="map-page-container">
                        <div ref={mapContainer} className="map-container" />
                    </div>
                </div>
            </Content>
            <DrawerCourseDeliveryArea
                course={course}
                visible={visibleDeliveryAreaDrawer}
                fromView={'merchant'}
                onClose={onCloseDeliveryAreaDrawer}
                refresh={refresh}
            />
            <DrawerCoursePickupArea
                course={course}
                visible={visiblePickupAreaDrawer}
                fromView={'merchant'}
                onClose={onClosePickupAreaDrawer}
                refresh={refresh}
            />
        </div>
    )
};

export default FormMapTab;

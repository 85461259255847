import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { Table, Tag, Spin, Tooltip } from 'antd';
import { WarningOutlined } from '@ant-design/icons';
import moment from 'moment';

import DrawerDetailsCourse from '../../Components/Course/DrawerDetailsCourse';

import { roundUpHalfHour, roundDownHalfHour } from "../../utils";

const timeFormat = 'HHmm';

const onCellDefault = {
    rowSpan: 1,
    colSpan: 1
};


/**
 *
 * @param props
 *   @props {object(id, resourceId, resourceName, startAt, endAt)} events
 *   @props {dateTime} dateDisplay
 *   @props {dateTime} startTimeDisplay
 *   @props {dateTime} endTimeDisplay
 *   @props {*} resourceType
 *   @props {*} resourceName
 *   @props {*} resourcePath
 *   @props {boolean} loading
 */
const ResourcesTable = (props) => {
    const {
        dateDisplay,
        startTimeDisplay,
        endTimeDisplay,
        resourceType,
        resourceName,
        resourcePath,
        loading,
    } = props;
    let events = props.events;

    const [intervals, setIntervals] = useState([]);
    const [course, setCourse] = useState(null);
    const [showDrawerCourse, setShowDrawerCourse] = useState(false);

    const widthColumn = 200; // 200px largeur de base d'une course
    const interval = 30; // 30 minutes

    /**
     *
     * @param {*} time
     * @param {*} endTime
     */
    const generateIntervals = (time, endTime) => {
        let array = [];
        const type = 'minutes';

        while (time.isBefore(endTime)) {
            array.push(time.clone());
            time = time.clone().add(interval, type);
        }

        return array;
    };

    /**
     *
     * @param {*} title
     */
    const getHeader = (title) => (<div className="delivery-schedule-hourly-header">{title}</div>);

    /**
     *
     */
    const generateColumns = () => {
        let columnsTime = intervals.map((time)=> ({
                title: getHeader(time.format("HH:mm")),
                key: time,
                width: widthColumn,
                render: (row) => getCourse(row, time),
                onCell: (row) => getOnCellRow(row, time)
            }));

        return [
            {
                title: getHeader(resourceName),
                fixed: 'left',
                render: (row) => {
                    return (
                        <Link
                            to={resourcePath(row.resource_id)}
                            style={{ textAlign: 'left', padding: 20 }}
                        >
                            {row.resource_name}
                        </Link>
                    );
                },
                onCell: (row, index) => {
                    let onCellRow = { ...onCellDefault };
                    if (row['resource_rows'] > 1 && row['resource_row'] === 1) {
                        onCellRow['rowSpan'] = row['resource_rows'];
                    } else if (row['resource_rows'] > 1 && row['resource_row'] > 1) {
                        onCellRow['rowSpan'] = 0;
                    }

                    return onCellRow;
                }
            }, {
                title: (
                    <div style={{ height: 32 }} />
                ),
                children: columnsTime,
            }
        ];
    };

    /**
     *
     */
    const generateResources = () => {
        let eventsByResources = [];

        events.forEach((course) => {
            let index = null;
            let courseIntervals = generateIntervals(course.startAt, course.endAt);

            let eventsResource = eventsByResources.filter(elem => elem.resource_id === course.resourceId);
            eventsResource.some((resource, i) => {
                if (testResource(resource, courseIntervals)) {
                    index = i;
                    return true;
                }
                return false;
            });

            if (index === null) {
                let resourceKey = eventsByResources.length;
                let resource = {
                    'key': resourceKey,
                    'resource_id': course.resourceId,
                    'resource_name': course.resourceName,
                    'resource_row': eventsResource.length + 1,
                    'resource_rows': 1
                };
                intervals.forEach((time)=> {
                    resource[time.format(timeFormat)] = null;
                });

                eventsByResources.push(resource);

                eventsResource = eventsByResources.filter(elem => elem.resource_id === course.resourceId);
                eventsResource.forEach(elem => { elem['resource_rows'] = eventsResource.length; });

                index = eventsResource.findIndex(elem => elem.key === resourceKey);
            }

            eventsResource = eventsByResources.filter(elem => elem.resource_id === course.resourceId);
            let availableResource = eventsResource[index];

            courseIntervals.forEach(creneau => {
                availableResource[creneau.format(timeFormat)] = course;
            });
        });

        eventsByResources.sort((a, b) => {
            // alphabetical sorting resource
            if (a.resource_name > b.resource_name) return 1;
            if (a.resource_name < b.resource_name) return -1;
            // if same resource, sorting by resource_row
            if (a.resource_row > b.resource_row) return 1;
            if (a.resource_row < b.resource_row) return -1;
            return 0;
        });

        return eventsByResources;
    };

    /**
     *
     * @param {*} resource
     * @param {*} courseIntervals
     */
    const testResource = (resource, courseIntervals) => {
        let available = true;
        courseIntervals.some(creneau => {
            if (resource[creneau.format(timeFormat)] !== null) {
                available = false;
                return true;
            }
            return false;
        });

        return available;
    };

    /**
     *
     * @param {*} resource
     * @param {date} date
     */
    const merchantOpenningHourlysTest = (resource, date) => {
        let day = date.format('d');

        if (resource.opening_schedules === undefined || resource.opening_schedules.length === 0) {
            return null;
        }

        return resource.opening_schedules.find((item) => item['day'] === parseInt(day));
    };

    /**
     *
     * @param {*} row
     * @param {*} dateTime
     */
    const getCourse = (row, dateTime) => {
        let data = row[dateTime.format(timeFormat)];

        let isClose = false;
        let resourceOpeningHour = null;
        let resourceClosureHour = null;
        let resourceCloseContent = null;

        if (resourceType == "merchant") {
            let resource = events.find(item => item.merchant.id === row.resource_id).merchant;

            let merchantOpenningHourlys = (resource, date) => {
                let day = date.format('d');
                if (resource.opening_schedules === undefined || resource.opening_schedules.length === 0) {
                    return null;
                }
                return resource.opening_schedules.find((item) => item['day'] === parseInt(day));
            };
            let dailyHoraires = merchantOpenningHourlys(resource, dateTime);

            if (dailyHoraires !== undefined && dailyHoraires !== null) {
                resourceOpeningHour = dateDisplay.clone().set({
                    hour: dailyHoraires.opening_hours,
                    minute: dailyHoraires.opening_minutes,
                    second: dailyHoraires.opening_seconds
                });
                resourceOpeningHour = roundDownHalfHour(resourceOpeningHour);
                resourceClosureHour = dateDisplay.clone().set({
                    hour: dailyHoraires.closing_hours,
                    minute: dailyHoraires.closing_minutes,
                    second: dailyHoraires.closing_seconds
                });
                resourceClosureHour = roundUpHalfHour(resourceClosureHour);

                isClose = (resourceOpeningHour.isAfter(dateTime) || !resourceClosureHour.isAfter(dateTime)
                    || (data && (resourceClosureHour.isBefore(data.endAt) || resourceOpeningHour.isAfter(data.startAt)))
                );
                resourceCloseContent = (
                    <div style={{ textAlign: 'center' }}>
                        <WarningOutlined style={{ marginRight: 5 }} />
                        Course attributée hors des heures d'ouverture du commerçant
                        <br />
                        ({resourceOpeningHour.format('HH:mm')} - {resourceClosureHour.format('HH:mm')})
                    </div>
                );
            }
        }

        let classContent = ['delivery-schedule-hourly'];
        if (!data) {
            if (isClose) classContent.push('delivery-schedule-hourly-close');
            return <div className={classContent.join(' ')}>{(isClose) ? 'FERMÉ' : null}</div>;
        }

        classContent.push('delivery-schedule-hourly-course');
        if (isClose) classContent.push('delivery-schedule-hourly-warning');

        return (
            <Tooltip title={(isClose) ? (resourceCloseContent) : null}>
                <Tag
                    color={data.color}
                    className={classContent.join(' ')}
                    onClick={() => { props.onClickEvent(data.id)}}
                >
                    Course {data.short_uuid} ({data.collectTime} - {data.deliveryTime})
                    <br />
                    <div className="delivery-schedule-text-overflow">
                        <span>{data.customer.firstname} {data.customer.name}</span>
                    </div>
                    <br />
                    <div style={{ width: 145, display: 'inline-block' }}>
                        <span>{data.delivery_area.zip_code} - {Math.round(data.distance / 1000) + ' km'}</span>
                    </div>
                </Tag>
            </Tooltip>
        );
    };

    /**
     *
     * @param {*} row
     * @param {*} dateTime
     */
    const getOnCellRow = (row, dateTime) => {
        let hour = dateTime.format("HHmm");
        let onCellRow = { ...onCellDefault };

        if (!row || !row[hour]) return onCellRow;

        let course = row[hour];
        let durationHour = 0;

        if (course.startAt.isSame(dateTime, 'minutes')) {
            durationHour = (course.endAt.diff(course.startAt) / 3600000);
        } else if (dateTime === intervals[0]) {
            durationHour = (course.endAt.diff(dateTime) / 3600000);
        }

        onCellRow.colSpan = durationHour * 2; // 1 bloc = 1/2 heure;
        return onCellRow;
    };

    /**
     *
     */
    const getPositionHourLine = () => {
        let now = moment();

        let stylePosition = {
            left: -10,
        };

        if (intervals.length === 0) return stylePosition;

        if (now.isSame(dateDisplay, 'day') && now.isAfter(startTimeDisplay) && now.isBefore(endTimeDisplay)) {
            let nowMinutes = now.diff(startTimeDisplay, 'minutes');
            stylePosition['left'] = ((widthColumn / 30) * nowMinutes) + 250;
        }

        return stylePosition;
    };

    useEffect(() => {
        let intervalsColumns = generateIntervals(startTimeDisplay, endTimeDisplay);
        setIntervals(intervalsColumns);
    }, [dateDisplay, startTimeDisplay, endTimeDisplay]);

    let widthTable = (intervals.length * widthColumn) + 250;
    let styleHourLine = getPositionHourLine();

    let columns = generateColumns();

    return (
        <div className="delivery-schedule" style={{ position: 'relative' }}>
            <Spin spinning={loading}>
                <div className="scroll" style={{ padding: 0 }}>
                    <div style={{ width: widthTable, position: 'relative' }}>
                        <Table
                            columns={columns}
                            dataSource={generateResources()}
                            pagination={false}
                            bordered
                            size='small'
                        />
                        <div className="delivery-schedule-hour-line" style={styleHourLine}></div>
                    </div>
                </div>
            </Spin>
            <div className="table-header-absolute title">
                <span style={{ padding: '0 15px'}}>{dateDisplay.format('DD/MM/YYYY')}</span>
            </div>
            <DrawerDetailsCourse
                course={course}
                showDrawerCourseDetails={showDrawerCourse}
                showDrawer={setShowDrawerCourse}
            />
        </div>
    );
};

export default ResourcesTable;

import React, { useEffect } from 'react';

import { Form, Input, Button, Space, Drawer, Select, notification } from 'antd';
import { getOneTransportRule, postTransportRule, updateTransportRule } from '../../Api/TransportRules';

const layout = {
    labelCol: {
        span: 24,
    },
    wrapperCol: {
        span: 24,
    },
};

const TransportRulesDrawerForm = ({ visible, onCloseTransportRuleDrawer, onCreatedTransportRule, transportRuleId, action, network, merchant, merchanttheme }) => {
    const [form] = Form.useForm();

    /**
     * 
     */
    const onSubmitTransportRule = () => {
        let values = form.getFieldsValue();
        if(values.volume !== null) {
            values.volume = values.volume * 1000
        }
        let network_id = null;
        let merchantTheme_id = null;
        let merchant_id = null;

        if (network !== undefined) {
            network_id = network.id;
        }

        if (merchant !== undefined) {
            merchant_id = merchant.id;
        }

        if (merchanttheme !== undefined) {
            merchantTheme_id = merchanttheme.id;
        }

        let data = {
            network_id: network_id,
            merchant_id: merchant_id,
            merchanttheme_id: merchantTheme_id,
            transport: values.transport,
            distance: values.distance,
            weigth: values.weigth,
            volume: values.volume,
            side: values.side,
        }

        if (action === 'save') {
            postTransportRule(data)
                .then(res => res.json())
                .then(() => {
                    notification['success']({
                        message: 'La règle de transport a bien été enregistrée.'
                    });
                    onCreatedTransportRule();
                    form.resetFields();
                })
        } else if (action === 'update') {
            data.id = transportRuleId;
            updateTransportRule(transportRuleId, data)
                .then(res => res.json())
                .then(() => {
                    notification['success']({
                        message: 'La règle de transport a bien été mise à jour.'
                    });
                    onCreatedTransportRule();
                    form.resetFields();
                })
        }

    };

    /**
     * 
     */
    const fetchOneTransportRule = () => {
        getOneTransportRule(transportRuleId)
            .then(res => res.json())
            .then(json => {
                let volume = null;
            if(json.volume !== null){
                volume = json.volume/1000
            }
                let formValues = {
                    transport: json.transport,
                    distance: json.distance,
                    weigth: json.weigth,
                    volume: volume,
                    side: json.side,
                };

                form.setFieldsValue(formValues);
            })
    }

    /**
     * 
     */
    useEffect(() => {
        form.resetFields();

        if (action === 'update' && transportRuleId !== null) {
            fetchOneTransportRule()
        }
    }, [transportRuleId, action])

    return (
        <Drawer
            title="Configuration des règles de transport"
            width={650}
            onClose={onCloseTransportRuleDrawer}
            visible={visible}
            bodyStyle={{ paddingBottom: 80 }}
            footer={
                <Space>
                    <Button onClick={onCloseTransportRuleDrawer} style={{ marginRight: 8 }}>
                        Annuler
                    </Button>
                    <Button onClick={onSubmitTransportRule} type="primary">
                        Enregistrer
                    </Button>
                </Space>
            }
            footerStyle={{ textAlign: "right" }}
        >
            <Form {...layout} form={form} name="TransportRuleDrawer" layout="vertical">
                <Form.Item
                    initialValue={null}
                    label="Distance"
                    name='distance'
                >
                    <Input placeholder="Distance" suffix="m" />
                </Form.Item>
                <Form.Item
                    initialValue={null}
                    label="Poids"
                    name='weigth'
                >
                    <Input placeholder="Poids" suffix="g" />
                </Form.Item>
                <Form.Item
                    initialValue={null}
                    label="Volume"
                    name='volume'
                >
                    <Input placeholder="Volume" suffix="dm³" />
                </Form.Item>
                <Form.Item
                    initialValue={null}
                    label="Longueur d'un coté"
                    name='side'
                >
                    <Input placeholder="Longueur d'un coté" suffix="cm" />
                </Form.Item>
                <Form.Item
                    label="Moyen de transport"
                    name='transport'
                    rules={[{ required: true, message: 'Sélectionner un transport' }]}
                >
                    <Select>
                        <Select.Option value='all'>Tous</Select.Option>
                        <Select.Option value='walk'>A pied</Select.Option>
                        <Select.Option value='bike'>En vélo</Select.Option>
                        <Select.Option value='cargoBike'>En vélo cargo</Select.Option>
                        <Select.Option value='motorbike'>En scooter</Select.Option>
                        <Select.Option value='car'>En voiture</Select.Option>
                        <Select.Option value='commercialVehicle'>En véhicule utilitaire</Select.Option>
                    </Select>
                </Form.Item>
            </Form>
        </Drawer>
    );
};

export default TransportRulesDrawerForm;

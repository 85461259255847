import React, {useEffect} from 'react';
import {Link} from 'react-router-dom';

import {Button, Divider, Form, InputNumber, Space} from 'antd';

/**
 * 
 * @param {*} props 
 * @returns 
 */
const FormCommissionTab = (props) => {
    const { merchant } = props;

    const [form] = Form.useForm();

    /**
     * 
     * @param {*} values 
     */
    const onFinish = (values) => {
        let newMerchant = { ...merchant };

        if (!newMerchant.commission) {
            newMerchant.commission = {
                id: null,
                commission_price: null
            };
        }

        let number = parseFloat(values.commission_price);
        newMerchant.commission.commission_price = (number + 100) / 100;

        props.formSubmit(newMerchant);
    };

    /**
     * 
     */
    useEffect(() => {
        let formValues = { commission_price: null };

        if (merchant && merchant.commission) {
            let number = parseFloat(merchant.commission.commission_price);
            formValues.commission_price = Math.round((number * 100) - 100);
        }

        form.setFieldsValue(formValues);
    }, [merchant]);

    return (
        <Form
            form={form}
            name="merchant_commission"
            layout="vertical"
            onFinish={onFinish}
        >
            <Form.Item label="Commission" name="commission_price">
                <InputNumber
                    style={{ width: 200 }}
                    prefix="%"
                    min={0}
                    precision={2}
                    formatter={value => value.replace('.', ',')}
                    parser={value => value.replace(',', '.')}
                />
            </Form.Item>
            <Divider />
            <Form.Item>
                <Space>
                    <Button type="primary" htmlType="submit">
                        Enregistrer
                    </Button>
                    <Button style={{ backgroundcolor: '#2FAC66' }}>
                        <Link to="/networks">Annuler</Link>
                    </Button>
                </Space>
            </Form.Item>
        </Form>
    );
}

export default FormCommissionTab;

import React from 'react';

import {Redirect} from 'react-router-dom';

import {Alert, Divider, Modal, notification, PageHeader} from 'antd';
import {InfoCircleOutlined} from '@ant-design/icons';

import {postCourse} from '../../Api/Courses.js';
import FormCourseTab from './FormCourseTab.js';
import {GlobalContext} from '../../GlobalContext.js';

class CourseAdd extends React.Component {
    static contextType = GlobalContext;

    /**
     * 
     * @param {*} props 
     */
    constructor(props) {
        super(props);

        this.state = {
            value: 1,
            redirection: false,
            uuid: null,
            course: null,
            customer: null,
            merchant: null,
            driver: null,
            isWarningModalVisible: false,
            errors: [],
            errorsCount: null
        }

        this.onSubmit = this.onSubmit.bind(this);
    }


    /**
     * 
     * @param {*} values 
     */
    onSubmit(values) {
        postCourse(values)
            .then(function (response) {
                return response.json();
            }).then((data) => {
                this.setState({
                    uuid: data.course.uuid,
                });
                notification['success']({
                    message: 'La course a bien été enregistrée'
                });
                if (data.errors !== null) {
                    let errors = data.errors;
                    const messages = {
                        101: 'Le colis est trop lourd.',
                        102: 'Le colis est trop volumineux.',
                        103: 'La longueur maximale du colis est dépassée.',
                        104: 'La largeur maximale du colis est dépassée.',
                        105: 'La hauteur maximale du colis est dépassée.',
                        201: 'La distance maximale de livraison est dépassée.',
                        202: 'La zone de livraison ne se situe pas dans la zone autorisée.',
                        203: 'La zone de retrait ne se situe pas dans la zone autorisée.',
                        301: 'La date de récupération et de livraison du colis ne sont pas le même jour.',
                        302: 'L\'heure de retrait demandée est dépassée.',
                        303: 'L\'heure de livraison demandée est dépassée.',
                        304: 'L\'heure de livraison est avant l\'heure de retrait.',
                        305: 'Le délais de prévenance n\'est pas respecté.',
                        306: 'L\'heure de retrait autorisée est dépassée.',
                        307: 'L\'heure de retrait ou livraison ne rentre pas dans les horaires d\'ouverture.',
                        308: 'La date de livraison demandée correspond à un jour non travaillé.',
                        401: 'Aucune tarification trouvée pour la course.'
                    }
                    let errorsMessage = [];
                    if (errors.length > 0) {
                        errors.map((error) => {
                            errorsMessage.push(messages[error]);
                        })
                        Modal.warning({
                            title: 'Voici les avertissements sur votre course.',
                            content: (
                                <div>
                                    {errorsMessage.map((error) => {
                                        return (
                                            <Alert showIcon message={error} type="warning" style={{ marginTop: '20px', marginBottom: '20px' }} />
                                        )
                                    })}
                                </div>
                            ),
                            onOk() { }
                        })
                    }
                }
                this.setState({
                    redirection: true
                });
            });
    };



    render() {
        const { redirection } = this.state;
        const { uuid } = this.state;

        if (redirection) {
            return (<Redirect to={'/course/' + uuid} />)
        }

        return (
            <div>
                <PageHeader title="Courses" subTitle="Création d'une course" />
                <div style={{ margin: '0 16px 16px 16px' }}>
                    <div className="site-layout-background" style={{ padding: 24, minHeight: 360 }}>
                        <h2 style={{ color: "#2FAC66" }}><InfoCircleOutlined /> INFORMATIONS</h2>
                        <Divider></Divider>
                        <FormCourseTab user={this.context.global.profile} course={this.state.course} customer={this.state.customer} merchant={this.state.merchant} stores={this.state.stores}
                            driver={this.state.driver}  formSubmit={this.onSubmit}
                        />
                    </div>
                </div>
            </div>
        )
    }
}

export default CourseAdd;

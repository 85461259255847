import React from 'react';
import {saveAs} from 'file-saver';
import moment from 'moment';

import {Divider, Layout, notification, PageHeader} from 'antd';
import {InfoCircleOutlined} from '@ant-design/icons';

import mapboxgl from 'mapbox-gl';
import '@mapbox/mapbox-gl-draw/dist/mapbox-gl-draw.css';

import config from '../../Config.js';
import {
    findFilterDayCourses,
    getInvoice,
    getMerchantBoundaries,
    getMerchantOpeningSchedule,
    getMerchantUser,
    getOneMerchant,
    updateMerchant
} from '../../Api/Merchants.js';
import FormMerchant from './FormMerchant.js';
import {GlobalContext} from '../../GlobalContext.js';

mapboxgl.accessToken = config.mapboxToken;
const dateFormat = 'YYYY-MM-DD';

function createFeatureDetail(data) {
    let delivery = {
        lat: null,
        lng: null
    };
    let pickup = {
        lat: null,
        lng: null
    };

    if (data.delivery_area !== null) {
        delivery.lat = data.delivery_area.lat;
        delivery.lng = data.delivery_area.lng;
    }

    if (data.pickup_area !== null) {
        pickup.lat = data.pickup_area.lat;
        pickup.lng = data.pickup_area.lng;
    }

    let featureDetailDelivery = {
        type: "Feature",
        geometry: {
            type: "Point",
            coordinates: [delivery.lng, delivery.lat],
        },
        properties: {
            course_id: data.id,
        },
    };

    let featureDetailPickup = {
        type: "Feature",
        geometry: {
            type: "Point",
            coordinates: [pickup.lng, pickup.lat],
        },
        properties: {
            course_id: data.id,
        },
    };

    return [featureDetailDelivery, featureDetailPickup];
}


export class MerchantEdit extends React.Component {
    static contextType = GlobalContext;

    /**
     *
     * @param {*} props
     */
    constructor(props) {
        super(props);

        this.state = {
            value: 1,
            deliveryDates: [moment(), moment()],
            deliveryArea: [],
            pickupArea: [],
            lng: 1.4437,
            lat: 43.6043,
            zoom: 12.5,
            merchant: null,
            user: null,
            userToken: null,
            openingSchedule: {
                "0": [[0, 24]],
                "1": [[0, 24]],
                "2": [[0, 24]],
                "3": [[0, 24]],
                "4": [[0, 24]],
                "5": [[0, 24]],
                "6": [[0, 24]],
            },
            boundaries: [],
        }

        this.onSubmit = this.onSubmit.bind(this);
        this.onClickInvoice = this.onClickInvoice.bind(this);
        this.reloadBoundaries = this.reloadBoundaries.bind(this);
    }

    /**
     *
     */
    async loadOneMerchant() {
        const id = this.props.match.params.id;
        await getOneMerchant(id)
            .then((response) => {
                return response.json();
            }).then((data) => {
                this.setState({
                    merchant: data.merchant,
                    lat: data.merchant.latitude,
                    lng: data.merchant.longitude
                })
            })
    }

    /**
     *
     */
    async loadMerchantUser() {
        const id = this.props.match.params.id;
        await getMerchantUser(id)
            .then((response) => {
                return response.json();
            }).then((data) => {
                this.setState({ userToken: data.userToken })
            })
    }

    /**
     *
     */
    async loadBoundaries() {
        const id = this.props.match.params.id;
        await getMerchantBoundaries(id)
            .then((response) => {
                return response.json();
            }).then((data) => {
                let translate = {
                    deliveryArea: 'zone de livraison',
                    pickupArea: 'zone de retrait'
                }
                data.map((d) => {
                    //a supprimer quand anciennes boundaries mises à  jour
                    if (!d.id) {
                        d.id = Math.floor(Math.random() * Date.now());
                        d.zone_type = 'deliveryArea';
                        d.zone_name = 'zone';
                    }
                    d.zone_type = translate[d.zone_type]
                })
                this.setState({
                    boundaries: data
                })
            })
    }

    /**
     *
     */
    async loadCourses() {
        const id = this.props.match.params.id;
        const deliveryDates = this.state.deliveryDates;

        let payload = {
            dateStart: deliveryDates[0].format(dateFormat),
            dateEnd: deliveryDates[1].format(dateFormat)
        };

        await findFilterDayCourses(id, payload)
            .then((res) => res.json())
            .then((json) => {
                let data = json.courses;
                let geoJsonDelivery = {};
                let featureDelivery = [];

                let geoJsonPickup = {};
                let featurePickup = [];

                data.map((d) => {
                    let featureDetail = createFeatureDetail(d);

                    featureDelivery.push(featureDetail[0]);
                    featurePickup.push(featureDetail[1]);
                });

                geoJsonDelivery = {
                    type: "FeatureCollection",
                    features: featureDelivery,
                };
                geoJsonPickup = {
                    type: "FeatureCollection",
                    features: featurePickup,
                };
                this.setState({
                    deliveryArea: geoJsonDelivery,
                    pickupArea: geoJsonPickup,
                });

            })

    }

    /**
     *
     */
    async loadOpeningSchedules() {
        const id = this.props.match.params.id;
        await getMerchantOpeningSchedule(id)
            .then((response) => {
                return response.json();
            }).then((data) => {
                if (data.length !== 0) {
                    let openingSchedules = data;
                    let openingScheduleByDay = {};
                    let openingScheduleByDay0 = [];
                    let openingScheduleByDay1 = [];
                    let openingScheduleByDay2 = [];
                    let openingScheduleByDay3 = [];
                    let openingScheduleByDay4 = [];
                    let openingScheduleByDay5 = [];
                    let openingScheduleByDay6 = [];
                    let formatSchedules = [];
                    openingSchedules.map((openingSchedule) => {
                        let openingMinutes = openingSchedule.opening_minutes;
                        if (openingMinutes === 30) {
                            openingMinutes = .5
                        }
                        let closingMinutes = moment(openingSchedule.closing_minutes, "mm").add(1, 'minutes').get('minutes');
                        if (closingMinutes === 30) {
                            closingMinutes = .5
                        }
                        openingSchedule = {
                            day: openingSchedule.day,
                            hourFrom: openingSchedule.opening_hours + openingMinutes,
                            hourTo: openingSchedule.closing_hours + closingMinutes
                        }
                        formatSchedules.push(openingSchedule);
                    })
                    formatSchedules.map((formatSchedule) => {
                        let hoursTable = [formatSchedule.hourFrom, formatSchedule.hourTo]
                        if (formatSchedule.day === 0) {
                            openingScheduleByDay0.push(hoursTable)
                        } else if (formatSchedule.day === 1) {
                            openingScheduleByDay1.push(hoursTable)
                        } else if (formatSchedule.day === 2) {
                            openingScheduleByDay2.push(hoursTable)
                        } else if (formatSchedule.day === 3) {
                            openingScheduleByDay3.push(hoursTable)
                        } else if (formatSchedule.day === 4) {
                            openingScheduleByDay4.push(hoursTable)
                        } else if (formatSchedule.day === 5) {
                            openingScheduleByDay5.push(hoursTable)
                        } else {
                            openingScheduleByDay6.push(hoursTable)
                        }
                    })
                    openingScheduleByDay = {
                        "0": openingScheduleByDay0,
                        "1": openingScheduleByDay1,
                        "2": openingScheduleByDay2,
                        "3": openingScheduleByDay3,
                        "4": openingScheduleByDay4,
                        "5": openingScheduleByDay5,
                        "6": openingScheduleByDay6,
                    }
                    this.setState({
                        openingSchedule: openingScheduleByDay
                    })
                }

            })
    }

    /**
     *
     * @param {*} params
     */
    async loadData(params = {}) {
        await this.loadOneMerchant();
        await this.loadMerchantUser();
        this.setState({ loading: true })
        await this.loadCourses();
        await this.loadOpeningSchedules();
        await this.loadBoundaries();
    }

    /**
     *
     */
    async componentDidMount() {
        await this.loadData();
    }

    /**
     * 
     */
    reloadBoundaries = () => {
        this.loadBoundaries();
    }

    /**
     *
     * @param {*} e
     */
    onChangeDeliveryDates = (e) => {
        this.setState({ deliveryDates: e }, () => {
            this.loadCourses();
        });
    };

    /**
     *
     */
    onClickInvoice(values) {
        let id = this.props.match.params.id;
        let dateStart = values.dateStart;
        let dateEnd = values.dateEnd;
        getInvoice(dateStart, dateEnd, id)
            .then((res) => res.blob())
            .then(blob => saveAs(blob, 'facture_' + this.state.merchant.name + '.xlsx'))
    }

    /**
     *
     * @param {*} values
     */
    onSubmit(values) {
        const merchantId = this.props.match.params.id;
        let data = {
            id: merchantId,
            name: values.name,
            whiteLabel_id: values.whiteLabel_id,
            address: values.address,
            social_name: values.socialName,
            owner_firstName: values.ownerFirstName,
            owner_lastName: values.ownerLastName,
            owner_phone: values.ownerPhone,
            owner_email: values.ownerEmail,
            owner_password: values.owner_password,
            owner_confirmation_password: values.owner_confirmation_password,
            phone: values.phone,
            tva: values.tva,
            siret: values.siret,
            legal_form: values.legalForm,
            latitude: values.latitude,
            longitude: values.longitude,
            network_id: values.network_id,
            merchantTheme_id: values.merchantTheme_id,
            notes: values.notes,
            price_grid: values.price_grid,
            detailed_prices: values.detailed_prices,
            attributed_merchant_tags: values.attributed_merchant_tags,
            zip_code: values.zip_code
        };
        if (values.fixed_price !== null) {
            data.fixed_price = values.fixed_price.price
            if (values.fixed_price.id !== null) {
                data.price_id = values.fixed_price.id
            }
        }
        if (values.configuration !== null) {
            data.max_distance = values.configuration.max_distance;
            data.max_weigth = values.configuration.max_weigth;
            data.max_volume = values.configuration.max_volume;
            data.slack_webhook = values.configuration.slack_webhook;
            data.max_length = values.configuration.max_length;
            data.max_width = values.configuration.max_width;
            data.max_height = values.configuration.max_height;
            data.min_delay_to_start = values.configuration.min_delay_to_start;
        }
        if (values.commission !== null) {
            data.commission_price = values.commission.commission_price
        }
        updateMerchant(merchantId, data)
            .then(function (response) {
                return response.json();
            }).then(() => {
                notification['success']({
                    message: 'Le commerçant a bien été mis à jour'
                })
                this.loadOneMerchant()
            })
    };

    render() {
        const { Content } = Layout;

        let formMerchant = (<label>Chargement...</label>);
        if (this.state.merchant) {
            formMerchant = (
                <FormMerchant
                    merchant={this.state.merchant}
                    formSubmit={this.onSubmit}
                    user={this.context.global.profile}
                    userToken={this.state.userToken}
                    openingSchedule={this.state.openingSchedule}
                    boundaries={this.state.boundaries}
                    reloadBoundaries={this.reloadBoundaries}
                    deliveryArea={this.state.deliveryArea}
                    pickupArea={this.state.pickupArea}
                    trackingChange={this.onChangeDeliveryDates}
                    latitude={this.state.lat}
                    longitude={this.state.lng}
                    getInvoice={this.onClickInvoice}
                />
            );
        }

        return (
            <div>
                <PageHeader title="Commerçants" subTitle="Edition d'un commerçant" />
                <Content style={{ margin: '0 16px 16px 16px' }}>
                    <div className="site-layout-background" style={{ padding: 24, minHeight: 360 }}>
                        <h2 style={{ color: "#2FAC66" }}><InfoCircleOutlined /> INFORMATIONS</h2>
                        <Divider></Divider>
                        {formMerchant}
                    </div>
                </Content>
            </div>
        )
    }
}

export default MerchantEdit;

import React, {useEffect} from 'react';
import {Link} from 'react-router-dom';

import {Button, Divider, Form, InputNumber, List, Popconfirm, Radio, Space} from 'antd';
import {DeleteOutlined, FormOutlined} from '@ant-design/icons';

import DetailedPriceDrawerForm from '../../Components/DetailedPrice/DetailedPriceDrawerForm';
import {getFloatPrice} from '../../utils';

const radioStyle = {
    display: 'block',
    height: '30px',
    lineHeight: '30px',
    textAlign: 'left'
};

/**
 * 
 * @param {*} props 
 * @returns 
 */
const FormPriceGridTab = (props) => {
    const { network } = props;

    const [form] = Form.useForm();

    const [price_grid, setPrice_grid] = React.useState(1);
    const [visible, setVisible] = React.useState(false);
    const [detailedPrices, setDetailedPrices] = React.useState([]);
    const [editDetailedPrice, setEditDetailedPrice] = React.useState(null);
    const [rowKey, setRowKey] = React.useState(null);

    /**
     * 
     * @param {*} values 
     */
    const onFinish = (values) => {
        let newNetwork = (network) ? { ...network } : {};
        newNetwork.price_grid = values.price_grid;

        if (values.price_grid === 4) {
            newNetwork.detailed_prices = detailedPrices;
        }

        if (values.price_grid === 2) {
            if (!newNetwork.fixed_price) {
                newNetwork.fixed_price = {
                    id: null,
                    price: null
                }
            }

            newNetwork.fixed_price.price = getFloatPrice(values.fixed_price);
        }

        props.formSubmit(newNetwork);
    };

    /**
     * 
     * @param {*} e 
     */
    const onChange = e => {
        setPrice_grid(e.target.value);
    };

    /**
     *
     */
    const openDetailedPriceDrawer = () => {
        setEditDetailedPrice(null);
        setRowKey(null);
        setVisible(true);
    };

    /**
     *
     */
    const closeDetailedPriceDrawer = () => {
        setEditDetailedPrice(null);
        setRowKey(null);
        setVisible(false);
    };

    /**
     *
     * @param {*} data
     */
    const onCreatedDetailedPrice = (data) => {
        let addDetailedPrices = [...detailedPrices];

        if (data.rowKey !== null) {
            addDetailedPrices[data.rowKey].transport = data.values.transport;
            addDetailedPrices[data.rowKey].distance = data.values.distance;
            addDetailedPrices[data.rowKey].weigth = data.values.weigth;
            addDetailedPrices[data.rowKey].volume = data.values.volume;
            addDetailedPrices[data.rowKey].price = data.values.price;
            addDetailedPrices[data.rowKey].zip_code = data.values.zip_code;
        } else {
            addDetailedPrices.push(data.values);
        }

        setDetailedPrices(addDetailedPrices);
        closeDetailedPriceDrawer();
    };

    /**
     *
     * @param {*} item
     * @param {*} rowKey
     */
    const editOneDetailedPrice = (item, rowKey) => {
        setRowKey(rowKey);
        setEditDetailedPrice(item);
        setVisible(true);
    };

    /**
     *
     * @param {*} e
     * @param {*} rowKey
     */
    const deleteDetailedPrice = (e, rowKey) => {
        e.preventDefault();
        let newDetailedPrices = [...detailedPrices];
        newDetailedPrices.splice(rowKey, 1);

        setDetailedPrices(newDetailedPrices);
    };

    /**
     * 
     */
    useEffect(() => {
        let formValues = {
            price_grid: null,
            fixed_price: null,
            detailed_prices: null,
        };

        if (network) {
            formValues.price_grid = network.price_grid;
            if (network.fixed_price) {
                formValues.fixed_price = network.fixed_price.price;
            }

            setDetailedPrices(network.detailed_prices);
            setPrice_grid(formValues.price_grid);
        }

        form.setFieldsValue(formValues);
    }, [network]);

    let inputFixedPrice = null;
    let listDetailedPrice = null;
    let ButtonOpenDetailedPriceDrawer = null;

    if (price_grid === 2) {
        inputFixedPrice = (
            <Form.Item name="fixed_price" label="Insérez votre tarif">
                <InputNumber
                    style={{ width: 200 }}
                    prefix="€"
                    min={0}
                    precision={2}
                    formatter={value => value.replace(".", ",")}
                    parser={value => value.replace(",", ".")}
                />
            </Form.Item>
        );
    }
    if (price_grid === 4) {
        ButtonOpenDetailedPriceDrawer = (
            <div>
                <Button type="primary" onClick={openDetailedPriceDrawer}>
                    Ajouter une règle
                </Button>
                <Divider />
            </div>
        );

        listDetailedPrice = (
            <List
                size="small"
                dataSource={detailedPrices}
                renderItem={(item, rowKey) => {
                    const translate = {
                        bike: "Vélo",
                        walk: "A pied",
                        cargoBike: "Vélo cargo",
                        motorbike: "Scooter ou moto",
                        car: "Voiture",
                        bus: "Bus",
                        tram: "Tram",
                        commercialVehicle: "Véhicule commercial",
                        all: "Tous",
                    };

                    let transport = "En " + translate[item.transport];
                    if (item.transport === "all") {
                        transport = "Tous moyens de transport";
                    } else if (item.transport === "walk") {
                        transport = translate[item.transport];
                    }

                    let distance = (item.distance) ? "pour une distance de " + item.distance + " m" : null;
                    let weigth = (item.weigth) ? "pour un poids de " + item.weigth + " g" : null;
                    let volume = (item.volume) ? "pour un volume de " + item.volume/1000 + " dm³" : null;
                    let zipCode = (item.zip_code) ? "pour le code postal " + item.zip_code : null;
                    let price = [item.price, "€"].join(" ");

                    let text = [transport, distance, weigth, volume, zipCode].filter(item => item !== null);

                    return (
                        <List.Item
                            actions={[
                                <Button size="small" type="primary" onClick={() => editOneDetailedPrice(item, rowKey)}><FormOutlined /></Button>,
                                <Popconfirm
                                    title="Etes-vous sûr de vouloir supprimer cette règle ?"
                                    onConfirm={(e) => deleteDetailedPrice(e, rowKey)}
                                    okText="Oui"
                                    cancelText="Non"
                                >
                                    <Button size="small" type="primary" ><DeleteOutlined /></Button>
                                </Popconfirm>
                            ]}
                        >
                            <List.Item.Meta title={text.join(', ') + " :"} />
                            <div>{price}</div>
                        </List.Item>
                    );
                }}
            />
        );
    }

    return (
        <div>
            <Form
                form={form}
                name="network_price_grid"
                layout="vertical"
                onFinish={onFinish}
            >
                <Form.Item label="Sélectionner une grille tarifaire :" name="price_grid">
                    <Radio.Group onChange={onChange}>
                        <Radio style={radioStyle} value={1}>Désactivé</Radio>
                        <Radio style={radioStyle} value={3}>Personnalisé</Radio>
                        <Radio style={radioStyle} value={2}>Tarif fixe (en euro) </Radio>
                        <Radio style={radioStyle} value={4}>Détaillé</Radio>
                    </Radio.Group>
                </Form.Item>
                <Divider />
                {inputFixedPrice}
                {ButtonOpenDetailedPriceDrawer}
                {listDetailedPrice}
                <Divider />
                <Form.Item>
                    <Space>
                        <Button type="primary" htmlType="submit">
                            Enregistrer
                        </Button>
                        <Button style={{ backgroundcolor: "#2FAC66" }}>
                            <Link to="/networks">Annuler</Link>
                        </Button>
                    </Space>
                </Form.Item>
            </Form>
            <DetailedPriceDrawerForm
                visible={visible}
                onCloseDetailedPriceDrawer={closeDetailedPriceDrawer}
                onCreatedDetailedPrice={onCreatedDetailedPrice}
                editDetailedPrice={editDetailedPrice}
                rowKey={rowKey}
            />
        </div>
    );
}

export default FormPriceGridTab;

import React, {useEffect, useState} from 'react';
import moment from 'moment';
import {Button, Col, DatePicker, Divider, Layout, Row, Space} from 'antd';

import ChartCoursesDelivered from '../../Components/Charts/ChartCoursesDelivered';
import ChartCoursesFailed from '../../Components/Charts/ChartCoursesFailed';
import ChartCoursesPlanned from '../../Components/Charts/ChartCoursesPlanned';
import ChartCoursesPrices from '../../Components/Charts/ChartCoursesPrices';
import ChartCoursesStatus from '../../Components/Charts/ChartCoursesStatus';

import {
    coursesDeliveredByDay,
    coursesFailedByDay,
    coursesMerchantPrice,
    coursesPlannedByDay,
    coursesStatusByDay
} from '../../Api/Courses.js';

const { Content } = Layout;
const { RangePicker } = DatePicker;
const dateFormat = 'DD/MM/YYYY';

/**
 *
 */
const FormChartsCourses = ({ merchant }) => {

    const [dateEnd, setDateEnd] = useState(moment().format('YYYY-MM-DD'));
    const [dateStart, setDateStart] = useState(moment().subtract(7, 'days').format('YYYY-MM-DD'));
    const [coursesDelivered, setCoursesDelivered] = useState([]);
    const [coursesFailed, setCoursesFailed] = useState([]);
    const [coursesPlanned, setCoursesPlanned] = useState([]);
    const [coursesPrices, setCoursesPrices] = useState([]);
    const [coursesStatus, setCoursesStatus] = useState([]);
    const [totalDelivered, setTotalDelivered] = useState(0);
    const [totalFailed, setTotalFailed] = useState(0);
    const [totalPlanned, setTotalPlanned] = useState(0);
    const [totalStatus, setTotalStatus] = useState(0);
    const [totalPriceSuccess, setTotalPriceSuccess] = useState(0);
    const [totalPriceError, setTotalPriceError] = useState(0);

    const loadData = () => {
        loadCoursesDelivered();
        loadCoursesFailed();
        loadCoursesPlanned();
        loadCoursesStatus();
        loadCoursesPrices();
    };

    /**
     *
     */
    const loadCoursesDelivered = () => {
        let data = {
            dateStart: dateStart,
            dateEnd: dateEnd,
            merchantId: merchant.id
        };

        coursesDeliveredByDay(data)
            .then((res) => res.json())
            .then((json) => {
                setCoursesDelivered(json.data);
                setTotalDelivered(json.total);
            });
    };

    /**
     *
     */
    const loadCoursesFailed = () => {
        let data = {
            dateStart: dateStart,
            dateEnd: dateEnd,
            merchantId: merchant.id
        };

        coursesFailedByDay(data)
            .then((res) => res.json())
            .then((json) => {
                setCoursesFailed(json.data);
                setTotalFailed(json.total);
            });
    };

    /**
     *
     */
    const loadCoursesPlanned = () => {
        let data = {
            dateStart: dateStart,
            dateEnd: dateEnd,
            merchantId: merchant.id
        };

        coursesPlannedByDay(data)
            .then((res) => res.json())
            .then((json) => {
                setCoursesPlanned(json.data);
                setTotalPlanned(json.total);
            });
    };

    /**
     *
     */
    const loadCoursesStatus = () => {
        let data = {
            merchantId: merchant.id
        };

        coursesStatusByDay(data)
            .then((res) => res.json())
            .then((json) => {
                setCoursesStatus(json.data);
                setTotalStatus(json.total);
            });
    };

    /**
     *
     */
    const loadCoursesPrices = () => {
        let data = {
            dateStart: dateStart,
            dateEnd: dateEnd,
            merchantId: merchant.id
        };

        coursesMerchantPrice(data)
            .then((res) => res.json())
            .then((json) => {
                setCoursesPrices(json.data);
                setTotalPriceSuccess(json.total.success);
                setTotalPriceError(json.total.error);
            });
    };

    /**
     *
     * @param {*} values
     */
    const onDateChange = (values) => {
        if (values !== null ) {
            setDateStart(values[0].format('YYYY-MM-DD'));
            setDateEnd(values[1].format('YYYY-MM-DD'));
        }
    };

    /**
     *
     */
    const onDateSend = () => {
        loadData();
    };

    /**
     *
     */
    useEffect(() => {
        if (merchant) {
            loadData();
        }
    }, [merchant]);

    return (
        <Content style={{ backgroundColor: "white", margin: '16px 16px', padding: "24px 24px" }}>
            <Space>
                <div>Sélectionnez une période pour obtenir vos statistiques :</div>
                <RangePicker
                    defaultValue={[moment(), moment().subtract(7, 'days')]}
                    format={dateFormat}
                    onChange={onDateChange}
                    allowClear={false}
                />
                <Button type="primary" onClick={onDateSend}>
                    Envoyer
                </Button>
            </Space>
            <Divider></Divider>
            <div style={{ margin: '40px 0' }}>
                <Row gutter={[32, 40]}>
                    <Col span={12}>
                        <ChartCoursesDelivered
                            data={coursesDelivered}
                            total={totalDelivered}
                        />
                    </Col>
                    <Col span={12}>
                        <ChartCoursesFailed
                            data={coursesFailed}
                            total={totalFailed}
                        />
                    </Col>
                    <Col span={12}>
                        <ChartCoursesPlanned
                            data={coursesPlanned}
                            total={totalPlanned}
                        />
                    </Col>
                    <Col span={12}>
                        <ChartCoursesPrices
                            data={coursesPrices}
                            priceSuccess={totalPriceSuccess}
                            priceError={totalPriceError}
                        />
                    </Col>
                    <Col span={24}>
                        <ChartCoursesStatus
                            data={coursesStatus}
                            total={totalStatus}
                        />
                    </Col>
                </Row>
            </div>
        </Content>
    );
};

export default FormChartsCourses;

import config from '../Config.js';

/**
 * 
 * @param {*} data 
 * @returns 
 */
export function getManyCustomers(data) {
    return fetch(config.hostApi + 'customers', {
        method: 'POST',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + localStorage.getItem('token')
        },
        body: JSON.stringify(data),
    });
}

/**
 * 
 * @param {*} id 
 */
export function getOneCustomer(id) {
    return fetch(config.hostApi + 'customer/' + id, {
        method: 'GET',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + localStorage.getItem('token')
        },
    });
}

/**
 * 
 * @param {*} data 
 */
export function postCustomer(data) {
    return fetch(config.hostApi + 'customer', {
        method: 'POST',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + localStorage.getItem('token')
        },
        body: JSON.stringify(data)
    });
}

/**
 * 
 * @param {*} id 
 * @param {*} data 
 */
export function updateCustomer(id, data) {
    return fetch(config.hostApi + 'customer/' + id, {
        method: 'PUT',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + localStorage.getItem('token')
        },
        body: JSON.stringify(data)
    });
}

/**
 * 
 * @param {*} id 
 */
export function deleteCustomer(id) {
    return fetch(config.hostApi + 'customer/' + id, {
        method: 'DELETE',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + localStorage.getItem('token')
        },
    });
}

/**
 *
 * @param {*} id
 */
export function archive(id) {
    return fetch(config.hostApi + 'customer/' + id + '/archive', {
        method: 'PUT',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + localStorage.getItem('token')
        },
    });
}
import React from "react";

import {Button, Space} from 'antd';

/**
 *
 * @param {*} props
 * @returns
 */
const FormStatutTab = (props) => {

  let { driver } = props;

  /**
   *
   */
  const onClickValidate = () => {
    props.validateDriver();
  }

  /**
   *
   */
  const onClickEject = () => {
    props.ejectDriver();
  }

  let statut = null;

  if (driver !== null) {
    statut = driver.status;
    if (statut === "draft") {
      statut = "Brouillon";
    }
    if (statut === "valid") {
      statut = "Validé";
    }
    if (statut === "deportee") {
      statut = "Expulsé";
    }
  }

  return (
    <div>
      <Space direction="vertical">
        <div>Statut actuel</div>
        <Button type="primary" disabled>
          {statut}
        </Button>
        <div>Actions possibles</div>
        <Space wrap>
          <Button onClick={onClickValidate}>Valider</Button>
          <Button type="primary" danger onClick={onClickEject}>
            Expulser
          </Button>
        </Space>
      </Space>
    </div>
  )
}

export default FormStatutTab;

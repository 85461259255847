import React, { useEffect } from 'react';
import GooglePlacesAutocomplete from 'react-google-places-autocomplete';
import { geocodeByAddress, getLatLng } from 'react-google-places-autocomplete';

import { Form, Input, Button, Space, Divider, Drawer, notification } from 'antd';

import { saveNetworkStore, getNetworkOneStore, updateNetworkStore } from '../../Api/Networks.js';
import { saveMerchantStore, getMerchantOneStore, updateMerchantStore } from '../../Api/Merchants.js';

const layout = {
    labelCol: {
        span: 24,
    },
    wrapperCol: {
        span: 24,
    },
};


/**
 * 
 * @param {*} props 
 * @returns 
 */
const StoreDrawer = (props) => {
    let { merchant, network, id, showStoreDrawer, action } = props;

    const [form] = Form.useForm();

    let [location, setLocation] = React.useState(null);
    let [visibleStoreDrawer, setVisibleStoreDrawer] = React.useState(false);
    let [store, setStore] = React.useState(null);


    const onCloseStoreForm = () => {
        setVisibleStoreDrawer(false);
        form.resetFields();
        setLocation(null);
        props.showDrawer(false);
    }
    /**
     * 
     * @param {*} location 
     */
    const onChangeLocation = (location) => {
        setLocation(location);
        geocodeByAddress(location.label)
            .then(results => {
                let nbElement = results[0].address_components.length;
                let zipCode = results[0].address_components[nbElement - 1].long_name;
                form.setFieldsValue({
                    address: location.label,
                    zip_code: zipCode
                })
            })
    }

    const fetchMerchantStore = () => {
        const merchant_id = merchant.id;
        let store_id = id;
        getMerchantOneStore(merchant_id, store_id)
            .then((res) => res.json())
            .then((store) => {
                form.setFieldsValue({
                    store_name: store.store_name,
                    address: store.address,
                    contact_firstname: store.contact_firstname,
                    contact_name: store.contact_name,
                    contact_email: store.contact_email,
                    contact_phone: store.contact_phone,
                    zip_code: store.zip_code
                })

                setLocation({
                    "label": store.address,
                    "value": {}
                })
            })
    }

    const fetchNetworkStore = () => {
        const network_id = network.id;
        let store_id = id;
        getNetworkOneStore(network_id, store_id)
            .then((res) => res.json())
            .then((store) => {
                form.setFieldsValue({
                    store_name: store.store_name,
                    address: store.address,
                    contact_firstname: store.contact_firstname,
                    contact_name: store.contact_name,
                    contact_email: store.contact_email,
                    contact_phone: store.contact_phone,
                    zip_code: store.zip_code
                })

                setLocation({
                    "label": store.address,
                    "value": {}
                })
            })
    }

    const onFinish = (values) => {
        values.address = location.label;
        geocodeByAddress(location.label)
            .then(results => getLatLng(results[0]))
            .then(({ lat, lng }) => {
                if (merchant !== undefined) {
                    let merchant_id = merchant.id;
                    let newStore = store !== null ? { ...store } : {};

                    newStore.store_name = values.store_name;
                    newStore.address = values.address;
                    newStore.lat = lat;
                    newStore.lng = lng;
                    newStore.contact_name = values.contact_name;
                    newStore.contact_firstname = values.contact_firstname;
                    newStore.contact_phone = values.contact_phone;
                    newStore.contact_email = values.contact_email;
                    newStore.zip_code = values.zip_code;

                    if (action === 'save') {
                        newStore.id = null;

                        saveMerchantStore(merchant_id, newStore)
                            .then((res) => res.json())
                            .then(() => {
                                notification['success']({
                                    message: 'L\'entrepôt a bien été enregistré'
                                })
                                setVisibleStoreDrawer(false);
                                props.showDrawer(false);
                            })
                    } else {
                        newStore.id = id;
                        updateMerchantStore(merchant_id, newStore)
                            .then((res) => res.json())
                            .then(() => {

                                notification['success']({
                                    message: 'L\'entrepôt a bien été mis à jour'
                                })
                                setVisibleStoreDrawer(false);
                                props.showDrawer(false);
                            })
                    }
                } else if (network !== undefined) {
                    let network_id = network.id;
                    let newStore = store !== null ? { ...store } : {};

                    newStore.store_name = values.store_name;
                    newStore.address = values.address;
                    newStore.lat = lat;
                    newStore.lng = lng;
                    newStore.contact_name = values.contact_name;
                    newStore.contact_firstname = values.contact_firstname;
                    newStore.contact_phone = values.contact_phone;
                    newStore.contact_email = values.contact_email;
                    newStore.zip_code = values.zip_code;

                    if (action === 'save') {
                        newStore.id = null;

                        saveNetworkStore(network_id, newStore)
                            .then((res) => res.json())
                            .then(() => {
                                notification['success']({
                                    message: 'L\'entrepôt a bien été enregistré'
                                })
                                setVisibleStoreDrawer(false);
                                props.showDrawer(false);
                            })
                    } else {
                        newStore.id = id;
                        updateNetworkStore(network_id, newStore)
                            .then((res) => res.json())
                            .then(() => {

                                notification['success']({
                                    message: 'L\'entrepôt a bien été mis à jour'
                                })
                                setVisibleStoreDrawer(false);
                                props.showDrawer(false);
                            })
                    }
                }
            });
    };


    useEffect(() => {

        if (showStoreDrawer === true) {
            setVisibleStoreDrawer(true)
        }

        if (action === 'save') {
            form.resetFields();
            setLocation(null)
        }

        if (id !== null && action === 'update' && merchant !== undefined) {
            fetchMerchantStore()
        }

        if (id !== null && action === 'update' && network !== undefined) {
            fetchNetworkStore()
        }


    }, [id, action, showStoreDrawer])

    return (
        <Drawer
            title="Création d'un entrepôt"
            width={720}
            onClose={onCloseStoreForm}
            visible={visibleStoreDrawer}
            bodyStyle={{ paddingBottom: 80 }}
        >
            <Form {...layout} form={form} name="layout" onFinish={onFinish} layout='vertical'>

                <Divider orientation="left">GENERAL</Divider>
                <Form.Item label="Nom " name="store_name" hasFeedback rules={[{ required: true, message: 'Merci d\'indiquer un nom .' }]}>
                    <Input name="name" />
                </Form.Item>
                <Form.Item label="Adresse" name="address" hasFeedback rules={[{ required: true, message: 'Merci d\'indiquer une adresse.' }]}>
                    <GooglePlacesAutocomplete
                        apiKey="AIzaSyDxZByWuUeeXpDrajMFV4NNpvLA2Wcynyk"
                        selectProps={{
                            value: location,
                            onChange: onChangeLocation,
                        }}
                        autocompletionRequest={{
                            componentRestrictions: {
                                country: ['fr'],
                            }
                        }}
                    />
                </Form.Item>
                <Form.Item label="Code Postal" name="zip_code">
                    <Input name="zip_code" />
                </Form.Item>
                <Divider orientation="left">CONTACT</Divider>
                <Form.Item label="Prénom" name="contact_firstname" hasFeedback rules={[{ required: true, message: 'Merci d\'indiquer votre Prénom.' }]}>
                    <Input />
                </Form.Item>
                <Form.Item label="Nom" name="contact_name" hasFeedback rules={[{ required: true, message: 'Merci d\'indiquer votre Nom.' }]}>
                    <Input />
                </Form.Item>
                <Form.Item label="Téléphone" name="contact_phone" hasFeedback rules={[{ required: true, message: 'Merci d\'indiquer un numéro de téléphone.' }]}>
                    <Input />
                </Form.Item>
                <Form.Item label="Email" name="contact_email" hasFeedback rules={[{ type: 'email', required: true, message: 'Merci d\'indiquer votre email.' }]}>
                    <Input name="owner_email" />
                </Form.Item>

                <Divider></Divider>
                <Form.Item>
                    <Space>
                        <Button onClick={onCloseStoreForm} style={{ marginRight: 8 }}>
                            Annuler
                        </Button>
                        <Button htmlType="submit" type="primary">
                            Enregistrer
                        </Button>
                    </Space>
                </Form.Item>
            </Form>
        </Drawer>
    )
}

export default StoreDrawer;
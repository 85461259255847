import React from 'react';
import moment from 'moment-timezone';

import {Redirect} from 'react-router-dom';

import {Alert, Badge, Divider, Layout, Modal, notification, PageHeader} from 'antd';
import {EnvironmentOutlined, InfoCircleOutlined, UnorderedListOutlined, WarningOutlined} from '@ant-design/icons';

import mapboxgl from 'mapbox-gl';
import '@mapbox/mapbox-gl-draw/dist/mapbox-gl-draw.css';

import config from '../../Config.js';
import {getOneUser} from '../../Api/Login.js';
import {
    courseTracking,
    courseUnlinkDriver,
    courseValidate,
    getErrors,
    getOneCourseUuid,
    updateCourse,
    updateNotes as updateCourseNotes
} from '../../Api/Courses.js';
import {getOneCustomer} from '../../Api/Customers.js';
import {getMerchantStores, getOneMerchant} from '../../Api/Merchants.js';
import {getOneDriver} from '../../Api/Drivers.js';
import {getNetworkStores} from '../../Api/Networks.js';

import FormCourseTab, {FormNotes} from './FormCourseTab.js';
import FormStatutTab from './FormStatutTab.js';
import {GlobalContext} from '../../GlobalContext.js';

mapboxgl.accessToken = config.mapboxToken;

class CourseEdit extends React.Component {
    static contextType = GlobalContext;

    /**
     *
     * @param {*} props
     */
    constructor(props) {

        super(props);

        this.state = {
            value: 1,
            course: null,
            customer: null,
            deliveryArea: [],
            pickupArea: [],
            lng: 1.4437,
            lat: 43.6043,
            zoom: 12.5,
            merchant: null,
            driver: null,
            user: null,
            statutes: [],
            userStatutes: [],
            redirection: false,
            hiddenWarning: true,
            isWarningModalVisible: false,
            errors: [],
            errorsCount: null,
            courseTracking: [],
            lastPoint: [],
            stores: null,
            disableStatut: true,
            modalAvailableVisible: false,
            previousPrice: null,
            newPrice: null
        };

        this.onSubmit = this.onSubmit.bind(this);
        this.onSubmitNotes = this.onSubmitNotes.bind(this);
        this.onClikUnlinkDriver = this.onClikUnlinkDriver.bind(this);
        this.actionStatut = this.actionStatut.bind(this);
        this.deleteCourse = this.deleteCourse.bind(this);
        this.showWarning = this.showWarning.bind(this);
        this.handleOk = this.handleOk.bind(this);
        this.handleCancel = this.handleCancel.bind(this);
        this.driverLink = this.driverLink.bind(this);
        this.handleAvailableCancel = this.handleAvailableCancel.bind(this);
        this.handleAvailableOk = this.handleAvailableOk.bind(this);
        this.onClickAvailable = this.onClickAvailable.bind(this);
        this.refresh = this.refresh.bind(this);
    }

    /**
     *
     */
    async loadOneCourse() {
        const uuid = this.props.match.params.id;
        await getOneCourseUuid(uuid)
            .then((response) => response.json())
            .then((json) => {
                let course = json.course;
                let geoJsonDelivery = {
                    type: 'featureCollection',
                    features: [{
                        type: 'Feature',
                        geometry: {
                            type: 'Point',
                            coordinates: [course.delivery_area.lng, course.delivery_area.lat]
                        },
                        properties: {
                            course_uuid: course.uuid,
                        }
                    }]
                };
                let geoJsonPickup = {
                    type: 'featureCollection',
                    features: [{
                        type: 'Feature',
                        geometry: {
                            type: 'Point',
                            coordinates: [course.pickup_area.lng, course.pickup_area.lat]
                        },
                        properties: {
                            course_uuid: course.uuid,
                        }
                    }]
                };

                this.setState({
                    course: course,
                    deliveryArea: geoJsonDelivery,
                    pickupArea: geoJsonPickup,
                    statutes: course.statutes
                });
            });
    }

    /**
     *
     */
    async loadCourseTracking() {
        const id = this.state.course.id;
        const driver = this.state.course.driver;

        await courseTracking(id)
            .then((res) => res.json())
            .then((json) => {
                let data = json;
                let points = [];
                let coordinates = [];

                if (data.length > 1) {
                    data.map((d) => {
                        if (d !== null && d.longitude !== null && d.latitude !== null) {
                            coordinates = [d.longitude, d.latitude];
                            points.push(coordinates);
                        }
                    });

                    let lastPoint = data[data.length - 1];

                    let geoJsonLastPoint = {};
                    let featureLastPoint = [];
                    let featureDetailLastPoint = {};

                    featureDetailLastPoint = {
                        type: 'Feature',
                        geometry: {
                            type: 'Point',
                            coordinates: [lastPoint.longitude, lastPoint.latitude]
                        },
                        properties: {
                            title: (driver) ? driver.firstName + ' ' + driver.lastName : "-",
                            lastTracking: moment(lastPoint.received_at).format('DD/MM/YYYY HH:mm:ss'),
                            driver_id: driver.id,
                            phone: (driver) ? driver.phone : "-"
                        }
                    };
                    featureLastPoint.push(featureDetailLastPoint);

                    geoJsonLastPoint = {
                        type: 'featureCollection',
                        features: featureLastPoint
                    };

                    this.setState({
                        lastPoint: geoJsonLastPoint,
                        courseTracking: points
                    });
                }
            });
    }

    /**
     *
     */
    async loadErrors() {
        const id = this.state.course.id;
        const messages = {
            101: 'Le colis est trop lourd.',
            102: 'Le colis est trop volumineux.',
            103: 'La longueur maximale du colis est dépassée.',
            104: 'La largeur maximale du colis est dépassée.',
            105: 'La hauteur maximale du colis est dépassée.',
            201: 'La distance maximale de livraison est dépassée.',
            202: 'La zone de livraison ne se situe pas dans la zone autorisée.',
            203: 'La zone de retrait ne se situe pas dans la zone autorisée.',
            301: 'La date de récupération et de livraison du colis ne sont pas le même jour.',
            302: 'L\'heure de retrait demandée est dépassée.',
            303: 'L\'heure de livraison demandée est dépassée.',
            304: 'L\'heure de livraison est avant l\'heure de retrait.',
            305: 'Le délais de prévenance n\'est pas respecté.',
            306: 'L\'heure de retrait autorisée est dépassée.',
            307: 'L\'heure de livraison ne rentre pas dans les horaires d\'ouverture.',
            308: 'La date de livraison demandée correspond à un jour non travaillé.',
            401: 'Aucune tarification trouvée pour la course.'
        }
        await getErrors(id)
            .then((response) => {
                return response.json();
            }).then((data) => {
                let errors = data.errors;
                let errorsMessage = [];
                if (errors.length > 0) {
                    errors.map((error) => {
                        errorsMessage.push(messages[error]);
                    })
                    this.setState({
                        hiddenWarning: false,
                        errors: errorsMessage,
                        errorsCount: data.nbError
                    })
                } else {
                    this.setState({
                        hiddenWarning: true,
                        errorsCount: data.nbError
                    })
                }
            })
    }

    /**
     *
     */
    async loadOneCustomer() {
        let customerId = this.state.course.customer_id;
        await getOneCustomer(customerId)
            .then((response) => {
                return response.json();
            }).then((data) => {
                this.setState({
                    customer: data.customer
                })
            })
    }

    /**
     *
     */
    async loadOneMerchant() {
        let merchantId = this.state.course.merchant_id;
        await getOneMerchant(merchantId)
            .then((response) => {
                return response.json();
            }).then((data) => {
                this.setState({
                    merchant: data.merchant,
                    lat: data.merchant.latitude,
                    lng: data.merchant.longitude
                })
            })
    }

    /**
     *
     */
    async loadOneDriver() {
        if (this.state.course.driver_id !== 0) {
            let driverId = this.state.course.driver_id;
            await getOneDriver(driverId)
                .then((response) => {
                    return response.json();
                }).then((data) => {
                    this.setState({
                        driver: data.driver
                    })
                })
        }
    }

    /**
     *
     */
    loadStores() {
        let merchant_id = this.state.merchant.id;
        let network_id = this.state.merchant.network_id;
        Promise.all([
            getMerchantStores(merchant_id).then(value => value.json()),
            getNetworkStores(network_id).then(value => value.json())
        ]).then(allResponses => {
            let merchantStores = allResponses[0];
            let networkStores = allResponses[1];

            this.setState({
                stores: {
                    merchantStores: merchantStores,
                    networkStores: networkStores
                }
            })
        })

    }

    /**
     *
     */
    loadUserStatutes() {
        if (this.state.statutes !== []) {
            let userStatutes = [];
            let userStatusId = new Set();
            this.state.statutes.forEach((status) => {
                userStatusId.add(status.user_id);
            })
            userStatusId.forEach((userId) => {
                getOneUser(userId)
                    .then((response) => {
                        return response.json();
                    }).then((data) => {
                        if (data.user !== null) {
                            userStatutes.push(data.user);
                        }
                    });
            })
            this.setState({
                userStatutes: userStatutes
            });
        }
    }

    /**
     *
     */
    async loadData() {
        await this.loadOneCourse();
        await this.loadCourseTracking();
        await this.loadOneCustomer();
        await this.loadOneMerchant();
        await this.loadOneDriver();
        await this.loadStores();
        await this.loadUserStatutes();
        await this.loadErrors();
    }

    /**
     *
     */
    async componentDidMount() {
        await this.loadData();
    }

    /**
     *
     * @param {*} prevProps
     */
    componentDidUpdate(prevProps) {
        if (prevProps.location !== this.props.location) {
            this.loadData()
        }
    }

    /**
     *
     * @param {*} values
     */
    onSubmit(values) {
        const courseId = this.props.match.params.id;
        updateCourse(courseId, values)
            .then(function (response) {
                return response.json();
            }).then((data) => {
            notification['success']({
                message: 'La course a bien été mise à jour'
            });
            this.loadData();
        })
    };

    /**
     *
     * @param {*} values
     */
    onSubmitNotes(values) {
        const id = this.state.course.id;
        updateCourseNotes(id, values)
            .then(() => {
                notification['success']({
                    message: 'La course a bien été mise à jour'
                });
                this.loadOneCourse();
            })
            .catch(() => {
            });
    }

    /**
     *
     */
    onClikUnlinkDriver() {
        const courseId = this.state.id;
        courseUnlinkDriver(courseId)
            .then((res) => res.json())
            .then((json) => {
                this.setState({
                    course: json
                })
            })
    }

    /**
     *
     */
    actionStatut() {
        this.loadData();
    }

    /**
     *
     */
    deleteCourse() {
        this.setState({redirection: true})
    }

    /**
     *
     */
    onClickAvailable(values) {
        const courseId = values;
        const confirm = false;
        courseValidate(courseId, confirm)
            .then((res) => res.json())
            .then((data) => {
                if (data.error === null) {
                    this.setState({
                        course: data.course
                    });
                } else {
                    this.setState({
                        modalAvailableVisible: true,
                        previousPrice: data.previousPrice,
                        newPrice: data.newPrice,
                        course: data.course
                    })
                }

            })
    }

    /**
     *
     */
    driverLink() {
        this.loadData();
    }

    /**
     *
     */
    showWarning() {
        this.setState({
            isWarningModalVisible: true
        })
    }

    /**
     *
     */
    handleOk() {
        this.setState({
            isWarningModalVisible: false
        })
    };

    /**
     *
     */
    handleCancel() {
        this.setState({
            isWarningModalVisible: false
        })
    };

    /**
     *
     */
    handleAvailableOk() {
        const courseId = this.state.course.id;
        const confirm = true;
        courseValidate(courseId, confirm)
            .then((res) => res.json())
            .then((data) => {
                if (data.error === null) {
                    this.setState({
                        course: data.course
                    });
                } else {
                    this.setState({
                        modalAvailableVisible: true,
                        previousPrice: data.previousPrice,
                        newPrice: data.newPrice
                    })
                }

            })
        this.setState({
            modalAvailableVisible: false
        })
    }

    /**
     *
     */
    handleAvailableCancel() {
        this.setState({
            modalAvailableVisible: false
        })
    }

    /**
     *
     */
    refresh() {
        this.loadData();
    }

    render() {
        const {Content} = Layout;
        const {redirection} = this.state;
        const hiddenWarning = this.state.hiddenWarning;

        if (redirection) {
            return (<Redirect to={'/courses'}/>)
        }

        let uuid = null;

        if (this.state.course !== null) {
            uuid = this.state.course.short_uuid;
        }

        return (
            <div>
                <PageHeader title="Courses" subTitle="Edition d'une course"/>
                <Content style={{margin: '0 16px 16px 16px'}}>
                    <div className="site-layout-background" style={{padding: 24, minHeight: 360}}>
                        <h2 style={{color: "#2FAC66"}}>
                            <InfoCircleOutlined style={{marginRight: '10px'}}/>
                            COURSE N° {uuid}
                        </h2>
                        <div className="warning-course">
                            <Badge count={this.state.errorsCount} size="small">
                                <WarningOutlined
                                    style={{fontSize: '30px', color: '#faad14'}}
                                    hidden={hiddenWarning}
                                    onClick={this.showWarning}
                                />
                            </Badge>
                        </div>
                        <Modal
                            visible={this.state.isWarningModalVisible}
                            onOk={this.handleOk}
                            onCancel={this.handleCancel}
                            cancelButtonProps={{style: {display: 'none'}}}
                        >
                            {this.state.errors.map((error) => {
                                return (
                                    <Alert
                                        showIcon
                                        message={error}
                                        type="warning"
                                        style={{marginTop: '20px', marginBottom: '20px'}}
                                    />
                                )
                            })}
                        </Modal>
                        <Divider/>
                        <FormCourseTab
                            user={this.context.global.profile}
                            course={this.state.course}
                            customer={this.state.customer}
                            merchant={this.state.merchant}
                            stores={this.state.stores}
                            driver={this.state.driver}
                            formSubmit={this.onSubmit}
                        />
                    </div>
                </Content>
                <Content style={{margin: '0 16px 16px 16px'}}>
                    <div className="site-layout-background" style={{padding: 24, minHeight: 360}}>
                        <h2 style={{color: "#2FAC66"}}>
                            <UnorderedListOutlined style={{marginRight: '10px'}}/>
                            Remarques
                        </h2>
                        <Divider/>
                        <FormNotes course={this.state.course} formSubmit={this.onSubmitNotes}/>
                    </div>
                </Content>
                <Content style={{margin: '0 16px 16px 16px'}}>
                    <div className="site-layout-background" style={{padding: 24, minHeight: 360}}>
                        <h2 style={{color: "#2FAC66"}}>
                            <EnvironmentOutlined style={{marginRight: '10px'}}/>
                            TRACKING
                        </h2>
                        <Divider/>
                        <FormStatutTab
                            course={this.state.course}
                            driver={this.state.driver}
                            deliveryArea={this.state.deliveryArea}
                            pickupArea={this.state.pickupArea}
                            latitude={this.state.lat}
                            longitude={this.state.lng}
                            actionStatut={this.actionStatut}
                            deleteCourse={this.deleteCourse}
                            userStatutes={this.state.userStatutes}
                            statutes={this.state.statutes}
                            lastPoint={this.state.lastPoint}
                            courseTracking={this.state.courseTracking}
                            driverLink={this.driverLink}
                            availableAction={this.onClickAvailable}
                            refresh={this.refresh}
                        />
                    </div>
                </Content>
                <Modal
                    visible={this.state.modalAvailableVisible}
                    onOk={this.handleAvailableOk}
                    onCancel={this.handleAvailableCancel}
                    cancelButtonProps={{style: {display: 'none'}}}
                >
                    <Alert
                        showIcon
                        message="Le prix de la course a changé."
                        description={
                            <div>
                                <p>Ancien prix : {this.state.previousPrice} €</p>
                                <p>Nouveau prix : {this.state.newPrice} €</p>
                                <p>Confirmez-vous l'émission de la course avec le nouveau prix ?</p>
                            </div>
                        }
                        type="warning"
                        style={{marginTop: '20px', marginBottom: '20px'}}
                    />
                </Modal>
            </div>
        );
    }
}

export default CourseEdit;

import React, {useEffect} from 'react';
import {Link} from 'react-router-dom';

import {Button, Divider, Form, Input, Space} from 'antd';

const layout = {
    labelCol: {
        span: 8,
    },
    wrapperCol: {
        span: 8,
    },
};

/**
 * 
 * @param {*} props 
 * @returns 
 */
const FormConfigurationTab = (props) => {

    let { merchanttheme } = props;

    const [form] = Form.useForm();

    /**
     * 
     * @param {*} values 
     */
    const onFinish = (values) => {
        let newMerchantTheme = { ...merchanttheme };
        newMerchantTheme.configuration = {
            max_distance: values.max_distance,
            max_weigth: values.max_weigth,
            max_volume: values.max_volume*1000,
            slack_webhook: values.slack_webhook,
            max_length: values.max_length,
            max_width: values.max_width,
            max_height: values.max_height,
            min_delay_to_start: values.min_delay_to_start,
        }

        props.formSubmit(newMerchantTheme);
    };

    /**
     * 
     */
    useEffect(() => {

        if (merchanttheme !== null) {

            if (merchanttheme.configuration !== null) {
                let formValues = {
                    max_distance: merchanttheme.configuration.max_distance,
                    max_weigth: merchanttheme.configuration.max_weigth,
                    max_volume: null,
                    slack_webhook: merchanttheme.configuration.slack_webhook,
                    max_length: merchanttheme.configuration.max_length,
                    max_width: merchanttheme.configuration.max_width,
                    max_height: merchanttheme.configuration.max_height,
                    min_delay_to_start: merchanttheme.configuration.min_delay_to_start,
                };

                if (merchanttheme.configuration.max_volume !== null) {
                    formValues.max_volume = merchanttheme.configuration.max_volume/1000
                }

                form.setFieldsValue(formValues);
            }
        }
    }, [merchanttheme])

    return (
        <Form form={form} name="layout" onFinish={onFinish} layout='vertical'>
            <Form.Item {...layout} label="Distance maximale" name="max_distance">
                <Input name="max_distance" value="" suffix="m" />
            </Form.Item>
            <Form.Item {...layout} label="Poids maximal" name="max_weigth">
                <Input name="max_weigth" value="" suffix="kg" />
            </Form.Item>
            <Form.Item {...layout} label="Délais de prévenance" name="min_delay_to_start">
                <Input name="min_delay_to_start" value="" suffix="min" />
            </Form.Item>
            <Form.Item {...layout} label="Volume maximal" name="max_volume">
                <Input name="max_volume" value="" suffix="dm³" />
            </Form.Item>
            <Divider orientation="left">Taille du colis</Divider>
            <Form.Item {...layout} label="Longueur maximale" name="max_length">
                <Input name="max_length" value="" suffix="cm" />
            </Form.Item>
            <Form.Item {...layout} label="Largeur maximale" name="max_width">
                <Input name="max_width" value="" suffix="cm" />
            </Form.Item>
            <Form.Item {...layout} label="Hauteur maximale" name="max_height">
                <Input name="max_height" value="" suffix="cm" />
            </Form.Item>
            <Divider orientation="left">Slack</Divider>
            <Form.Item label="Canal Slack (url du webhook, channel)" name="slack_webhook">
                <Input name="slack_webhook" value="" />
            </Form.Item>
            <Divider></Divider>
            <Form.Item>
                <Space>
                    <Button type="primary" htmlType="submit">
                        Enregistrer
                    </Button>
                    <Button style={{ backgroundcolor: '#2FAC66' }}>
                        <Link to='/merchantthemes'>Annuler</Link>
                    </Button>
                </Space>
            </Form.Item>
        </Form>
    )
}

export default FormConfigurationTab;

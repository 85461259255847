import React from 'react';
import {Tabs} from 'antd';
import {
    EuroCircleOutlined,
    HistoryOutlined,
    HourglassOutlined,
    MoneyCollectOutlined,
    ToolOutlined
} from '@ant-design/icons';

import FormPriceGridTab from './FormPriceGridTab';
import FormCommissionTab from './FormCommissionTab';
import FormDeliveryOptions from './FormDeliveryOptions';
import DeliveryRulesTab from "../../Components/DeliveryRules/DeliveryRulesTab";

const { TabPane } = Tabs;

/**
 * 
 * @param {*} props 
 * @returns 
 */
const FormPricingTab = (props) => {
    const { network } = props;

    /**
     * 
     * @param {*} values 
     */
    const onFinish = (values) => {
        props.formSubmit(values);
    };

    return (
        <div style={{ marginTop: 20 }}>
            <Tabs defaultActiveKey="1" tabPosition="left">
                <TabPane tab={<span><MoneyCollectOutlined />Commission</span>} key="1">
                    <FormCommissionTab network={network} formSubmit={onFinish} />
                </TabPane>
                <TabPane tab={<span><EuroCircleOutlined />Grille </span>} key="2">
                    <FormPriceGridTab network={network} formSubmit={onFinish} />
                </TabPane>
                <TabPane tab={<span><ToolOutlined />Options </span>} key="3">
                    <FormDeliveryOptions network={network} />
                </TabPane>
                <TabPane tab={<span><HistoryOutlined />Créneaux</span>} key="4">
                    <DeliveryRulesTab network={network} ruleType="slots" />
                </TabPane>
                <TabPane tab={<span><HourglassOutlined />Délais</span>} key="5">
                    <DeliveryRulesTab network={network} ruleType="time_limit" />
                </TabPane>
            </Tabs>
        </div>
    )
}

export default FormPricingTab;

import React, { useEffect } from 'react';

import { Button, Form, Drawer, Divider, Space, Radio, Input, notification } from 'antd';

import { saveMerchantBoundaries } from '../../Api/Merchants.js';
import { saveNetworkBoundaries } from '../../Api/Networks.js';
import { saveMerchantThemeBoundaries } from '../../Api/MerchantThemes.js';

import MapForDrawer from '../MapForDrawer/MapForDrawer.js';

const layout = {
    labelCol: {
        span: 24,
    },
    wrapperCol: {
        span: 24,
    },
};

/**
 * 
 * @param {*} props 
 * @returns 
 */
const DrawerInfoBoundaries = (props) => {

    let { merchant, network, merchanttheme, boundaries, showInfoDrawer, selectedId } = props;

    const [form] = Form.useForm();

    let [visibleDrawerInfoBoundaries, setVisibleDrawerInfoBoundaries] = React.useState(false);
    let [zoneCoordinates, setZoneCoordinates] = React.useState(null);
    let [infoBoundaries, setInfoBoundaries] = React.useState([]);
    let [editBoundaries, setEditBoundaries] = React.useState([]);

    /**
     * 
     */
    const onCloseInfoBoundaries = () => {
        setVisibleDrawerInfoBoundaries(false);
        form.resetFields();
        props.showDrawer(false);
    }

    /**
     * 
     * @param {*} values 
     */
    const onFinish = (values) => {

        let infoBoundaries = [];
        let existentBoundaries = boundaries;
        if (selectedId === null) {
            if (existentBoundaries.length !== 0) {
                existentBoundaries.map(existentBoundarie => {
                    if (existentBoundarie.zone_type === 'zone de livraison') {
                        existentBoundarie.zone_type = 'deliveryArea'
                    }
                    if (existentBoundarie.zone_type === 'zone de retrait') {
                        existentBoundarie.zone_type = 'pickupArea'
                    }
                    infoBoundaries.push(existentBoundarie);
                })
            }
            let formatBoundaries = {
                id: Math.floor(Math.random() * Date.now()),
                zone_name: values.zone_name,
                zone_type: values.zone_type,
                coordinates: zoneCoordinates[0].coordinates,
                type: zoneCoordinates[0].type
            }
            infoBoundaries.push(formatBoundaries);
        } else {
            if (existentBoundaries.length !== 0) {
                existentBoundaries.map(existentBoundarie => {
                    if (existentBoundarie.zone_type === 'zone de livraison') {
                        existentBoundarie.zone_type = 'deliveryArea'
                    }
                    if (existentBoundarie.zone_type === 'zone de retrait') {
                        existentBoundarie.zone_type = 'pickupArea'
                    }
                    if (existentBoundarie.id === selectedId) {
                        if (existentBoundarie.zone_name !== values.zone_name) {
                            existentBoundarie.zone_name = values.zone_name
                        }
                        if (existentBoundarie.zone_type !== values.zone_type) {
                            existentBoundarie.zone_type = values.zone_type
                        }
                        if (existentBoundarie.coordinates !== zoneCoordinates[0].coordinates) {
                            existentBoundarie.coordinates = zoneCoordinates[0].coordinates
                        }
                    }
                    infoBoundaries.push(existentBoundarie);
                })
            }
        }

        let newBoundaries = {
            boundaries: infoBoundaries
        }

        if (merchant !== undefined) {
            let id = merchant.id;
            saveMerchantBoundaries(id, newBoundaries)
                .then((res) => res.json())
                .then(() => {
                    notification['success']({
                        message: 'La zone d\'activité a bien été mise à jour'
                    })
                    setVisibleDrawerInfoBoundaries(false);
                }).then(() => {
                    props.showDrawer(false);
                    form.resetFields();
                })
        }

        if (network !== undefined) {
            let id = network.id;
            saveNetworkBoundaries(id, newBoundaries)
                .then((res) => res.json())
                .then(() => {
                    notification['success']({
                        message: 'La zone d\'activité a bien été mise à jour'
                    })
                    setVisibleDrawerInfoBoundaries(false);
                }).then(() => {
                    props.showDrawer(false);
                    form.resetFields();
                })
        }

        if (merchanttheme !== undefined) {
            let id = merchanttheme.id;
            saveMerchantThemeBoundaries(id, newBoundaries)
                .then((res) => res.json())
                .then(() => {
                    notification['success']({
                        message: 'La zone d\'activité a bien été mise à jour'
                    })
                    setVisibleDrawerInfoBoundaries(false);
                }).then(() => {
                    props.showDrawer(false);
                    form.resetFields();
                })
        }

    }

    /**
     * 
     * @param {*} values 
     */
    const boundariesCoordinates = (values) => {
        setZoneCoordinates(values);
    }

    /**
     * 
     */
    useEffect(() => {
        if (showInfoDrawer === true) {
            setVisibleDrawerInfoBoundaries(true)
        }

        form.setFieldsValue({
            zone_type: "deliveryArea"
        })

        if (boundaries.length !== 0) {
            let info = [];
            let edit = [];

            boundaries.forEach(boundarie => {
                if (selectedId !== null) {
                    if (boundarie.id === selectedId) {
                        if (boundarie.zone_type === 'zone de livraison') {
                            boundarie.zone_type = 'deliveryArea'
                        }
                        if (boundarie.zone_type === 'zone de retrait') {
                            boundarie.zone_type = 'pickupArea'
                        }
                        form.setFieldsValue({
                            zone_name: boundarie.zone_name,
                            zone_type: boundarie.zone_type
                        })
                        edit.push(boundarie);
                    } else {
                        info.push(boundarie);
                    }
                } else {
                    info.push(boundarie);
                }
            })
            setEditBoundaries(edit);
            setInfoBoundaries(info);
            setZoneCoordinates(edit);
        }
    }, [boundaries, showInfoDrawer, selectedId])


    return (
        <Drawer
            title="Informations concernant la zone"
            width={1200}
            onClose={onCloseInfoBoundaries}
            visible={visibleDrawerInfoBoundaries}
            bodyStyle={{ paddingBottom: 80 }}
        >
            <Form {...layout} form={form} name="layout" onFinish={onFinish} layout='vertical'>
                <Form.Item
                    label="Nom"
                    name='zone_name'
                    rules={[{ required: true, message: 'Merci de renseigner un nom' }]}
                >
                    <Input />
                </Form.Item>
                <Form.Item
                    label='Type'
                    name='zone_type'
                    rules={[{ required: true, message: 'Merci de choisir une catégorie' }]}
                >
                    <Radio.Group>
                        <Radio value="deliveryArea">Zone de livraison</Radio>
                        <Radio value="pickupArea">Zone de Retrait</Radio>
                    </Radio.Group>
                </Form.Item>
                <Divider></Divider>
                <MapForDrawer merchant={merchant} boundaries={infoBoundaries} editBoundaries={editBoundaries} boundariesCoordinates={boundariesCoordinates} />
                <Divider></Divider>
                <Form.Item>
                    <Space>
                        <Button onClick={onCloseInfoBoundaries} style={{ marginRight: 8 }}>
                            Annuler
                        </Button>
                        <Button htmlType="submit" type="primary">
                            Enregistrer
                        </Button>
                    </Space>
                </Form.Item>
            </Form>
        </Drawer>
    )
}

export default DrawerInfoBoundaries;
import React, { useEffect } from 'react';

import { Button, Divider, List, Popconfirm, notification } from 'antd';
import { FormOutlined, DeleteOutlined } from '@ant-design/icons';

import DeliveryRulesDrawerForm from "../../Components/DeliveryRules/DeliveryRulesDrawerForm.js";
import { getManyDeliveryRules, deleteDeliveryRule } from '../../Api/DeliveryRules.js';

/**
 *
 * @param {*} props
 * @returns
 */
const DeliveryRulesTab = (props) => {
    const { ruleType, network=null, merchant=null, merchanttheme=null } = props;

    const [visible, setVisible] = React.useState(false);
    const [deliveryRules, setDeliveryRules] = React.useState([]);
    const [deliveryRuleId, setDeliveryRuleId] = React.useState(null);

    /**
     *
     */
    const fetchDeliveryRules = () => {
        let payload = {
            network_id: (network) ? network.id : null,
            merchant_id:  (merchant) ? merchant.id : null,
            merchanttheme_id: (merchanttheme) ? merchanttheme.id : null,
        };

        getManyDeliveryRules(payload)
            .then(res => res.json())
            .then(json => {
                setDeliveryRules(json[ruleType]);
            });
    };

    /**
     *
     */
    const onOpenDeliveryRuleForm = () => {
        setDeliveryRuleId(null);
        setVisible(true);
    };

    /**
     *
     */
    const onCloseDeliveryRuleForm = () => {
        setDeliveryRuleId(null);
        setVisible(false);
    };

    /**
     *
     *
     */
    const onCreatedDeliveryRule = () => {
        fetchDeliveryRules();
        setDeliveryRuleId(null);
        setVisible(false);
    };

    /**
     *
     * @param {*} id
     */
    const onEditDeliveryRule = (id) => {
        setDeliveryRuleId(id);
        setVisible(true);
    };

    /**
     *
     * @param {*} e
     *
     */
    const onClickDeleteDeliveryRule = (id, e) => {
        e.preventDefault();

        deleteDeliveryRule(id)
            .then(res => res.json())
            .then(() => {
                notification['success']({
                    message: 'La règle a bien été supprimée'
                });
                fetchDeliveryRules();
            });
    };

    /**
     *
     */
    useEffect(() => {
        fetchDeliveryRules();
    }, []);

    return (
        <div>
            <Button type="primary" onClick={onOpenDeliveryRuleForm}>
                Ajouter une règle
            </Button>
            <Divider />
            <List
                size="small"
                dataSource={deliveryRules}
                renderItem={(item) => {
                    let type = (ruleType === 'slots') ? 'un créneau' : 'un délai';
                    let time = (item.time) ? 'de livraison inférieur à ' + item.time / 60 + ' min  ' : '';
                    let price = (item.price) ? 'majoration de ' + item.price + ' € ' : 'aucune majoration';

                    let text = ['Pour', type, time, ':'];

                    return (
                        <List.Item
                            actions={[
                                <Button size="small" type="primary" onClick={() => onEditDeliveryRule(item.id)}><FormOutlined /></Button>,
                                <Popconfirm
                                    title="Etes-vous sûr de vouloir supprimer cette règle ?"
                                    onConfirm={(e) => onClickDeleteDeliveryRule(item.id, e)}
                                    okText="Oui"
                                    cancelText="Non"
                                >
                                    <Button size="small" type="primary"><DeleteOutlined /></Button>
                                </Popconfirm>

                            ]}
                            key={item.id}
                        >
                            <List.Item.Meta title={text.join(' ')} />
                            <div>{price}</div>
                        </List.Item>
                    );
                }}
            />
            <DeliveryRulesDrawerForm
                visible={visible}
                type={ruleType}
                network={network}
                merchant={merchant}
                merchanttheme={merchanttheme}
                deliveryRuleId={deliveryRuleId}
                onCloseDeliveryRuleDrawer={onCloseDeliveryRuleForm}
                onCreatedDeliveryRule={onCreatedDeliveryRule}
            />
        </div>
    );
};

export default DeliveryRulesTab;
import React, {useEffect} from 'react';
import {Link} from 'react-router-dom';

import {Button, Divider, Form, Input, Space,} from 'antd';

const { TextArea } = Input;

/**
 * 
 * @param {*} props 
 * @returns 
 */
const FormRemarqueTab = (props) => {

    let { merchant } = props;

    const [form] = Form.useForm();

    /**
     * 
     * @param {*} values 
     */
    const onFinish = (values) => {
        let newMerchant = { ...merchant }
        newMerchant.notes = values.notes;
        props.formSubmit(newMerchant)
    };

    /**
     * 
     */
    useEffect(() => {
        if (merchant !== null) {
            let formValues = {
                notes: merchant.notes
            };

            form.setFieldsValue(formValues);
        }
    })

    return (
        <div>
            <Form form={form} name="layout" onFinish={onFinish} layout='vertical'>
                <Form.Item name="notes">
                    <TextArea rows={8} />
                </Form.Item>
                <Divider></Divider>
                <Form.Item>
                    <Space>
                        <Button type="primary" htmlType="submit">
                            Enregistrer
                        </Button>
                        <Button style={{ backgroundcolor: '#2FAC66' }}>
                            <Link to='/merchants'>Annuler</Link>
                        </Button>
                    </Space>
                </Form.Item>
            </Form>
        </div>
    )
}

export default FormRemarqueTab;

import React, {useEffect, useState} from 'react';

import {Divider, Layout, notification, PageHeader} from 'antd';
import {InfoCircleOutlined} from '@ant-design/icons';

import {getOneCustomer, updateCustomer} from '../../Api/Customers.js';
import FormCustomer from './FormCustomer.js';
import {useParams} from "react-router-dom";

const {Content} = Layout;

const CustomerEdit = () => {
    const [customer, setCustomer] = useState(null)

    let {id} = useParams();

    const loadCustomer = () => {
        getOneCustomer(id)
            .then((response) => {
                return response.json();
            }).then((data) => {
            setCustomer(data.customer)
        })
    }

    useEffect(() => {
        loadCustomer();
    }, []);

    /**
     *
     * @param {*} values
     */
    const onSubmit = (values) => {
        let data = {...values};
        data.id = id;
        updateCustomer(id, data)
            .then(function (response) {
                return response.json();
            })
            .then((data) => {
                notification['success']({
                    message: 'Le client a bien été mis à jour'
                })
                setCustomer(data.customer);
            })
    }

    return (
        <div>
            <PageHeader title="Clients" subTitle="Edition d'un client"/>
            <Content style={{margin: '0 16px 16px 16px'}}>
                <div className="site-layout-background" style={{padding: 24, minHeight: 360}}>
                    <h2 style={{color: "#2FAC66"}}><InfoCircleOutlined/> INFORMATIONS</h2>
                    <Divider></Divider>
                    <FormCustomer customer={customer} formSubmit={onSubmit}/>
                </div>
            </Content>
        </div>

    )
}

export default CustomerEdit;

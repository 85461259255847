import config from '../Config.js';

/**
 *
 * @param {number} page
 * @param {number} limit
 * @param {Object} payload
 * @returns
 */
export function getList(page, limit, payload) {
    let url = 'courses?page=' + page + '&limit=' + limit;

    return fetch(config.hostApi + url, {
        method: 'POST',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + localStorage.getItem('token')
        },
        body: JSON.stringify(payload)
    });
}

/**
 * 
 * @param {*} id 
 */
export function getOneCourse(id) {
    return fetch(config.hostApi + 'course/' + id, {
        method: 'GET',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + localStorage.getItem('token')
        },
    });
}

/**
 * 
 * @param {*} uuid
 */
 export function getOneCourseUuid(uuid) {
    return fetch(config.hostApi + 'course/uuid/' + uuid, {
        method: 'GET',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + localStorage.getItem('token')
        },
    });
}

/**
 * 
 * @param {*} payload
 */
export function postCourse(payload) {
    return fetch(config.hostApi + 'course', {
        method: 'POST',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + localStorage.getItem('token')
        },
        body: JSON.stringify(payload)
    });
}

/**
 * 
 * @param {*} id 
 * @param {*} payload
 */
export function updateCourse(id, payload) {
    return fetch(config.hostApi + 'course/' + id, {
        method: 'PUT',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + localStorage.getItem('token')
        },
        body: JSON.stringify(payload)
    });
}

/**
 *
 * @param {*} id
 * @param {*} payload
 */
export function updateNotes(id, payload) {
    return fetch(config.hostApi + 'course/' + id + '/notes', {
        method: 'PUT',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + localStorage.getItem('token')
        },
        body: JSON.stringify(payload)
    });
}

/**
 * 
 * @param {*} id 
 */
export function deleteCourse(id) {
    return fetch(config.hostApi + 'course/' + id, {
        method: 'DELETE',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + localStorage.getItem('token')
        },
    });
}

/**
 * 
 * @param {*} id 
 */
export function courseDraft(id) {
    let url = 'course/' + id + '/draft';
    return fetch(config.hostApi + url, {
        method: 'GET',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + localStorage.getItem('token')
        }
    });
}

/**
 * 
 * @param {*} id 
 */
export function courseStarted(id) {
    return fetch(config.hostApi + 'course/' + id + '/demarrer', {
        method: 'GET',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + localStorage.getItem('token')
        }
    });
}

/**
 * 
 * @param {*} id 
 */
export function courseCanceled(id) {
    let url = 'course/' + id + '/annuler';
    return fetch(config.hostApi + url, {
        method: 'GET',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + localStorage.getItem('token')
        }
    });
}

/**
 * 
 * @param {*} id 
 */
export function courseInProgress(id) {
    return fetch(config.hostApi + 'course/' + id + '/inprogress', {
        method: 'GET',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + localStorage.getItem('token')
        }
    });
}

/**
 * 
 * @param {*} id 
 */
export function courseIsDelivered(id) {
    return fetch(config.hostApi + 'course/' + id + '/delivered', {
        method: 'GET',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + localStorage.getItem('token')
        }
    });
}

/**
 * 
 * @param {*} id 
 */
export function courseReturned(id) {
    return fetch(config.hostApi + 'course/' + id + '/returned', {
        method: 'GET',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + localStorage.getItem('token')
        }
    });
}

/**
 * 
 * @param {*} id 
 */
export function courseFailed(id) {
    return fetch(config.hostApi + 'course/' + id + '/failed', {
        method: 'GET',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + localStorage.getItem('token')
        }
    });
}

/**
 * 
 * @param {*} id 
 */
 export function courseDelete(id) {
    return fetch(config.hostApi + 'course/' + id, {
        method: 'DELETE',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + localStorage.getItem('token')
        }
    });
}

/**
 * 
 * @param {*} id 
 */
export function coursePickupFailed(id) {
    return fetch(config.hostApi + 'course/' + id + '/pickupfailed', {
        method: 'GET',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + localStorage.getItem('token')
        }
    });
}

/**
 * 
 * @param {*} id 
 */
export function courseUnlinkDriver(id) {
    return fetch(config.hostApi + 'course/' + id + '/unlinkdriver', {
        method: 'GET',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + localStorage.getItem('token')
        }
    });
}

/**
 * 
 * @param {*} id 
 * @param {*} data
 */
export function courseValidate(id, data) {
    let url ='course/' + id + '/valider?confirm=' + data;
    return fetch(config.hostApi + url, {
        method: 'GET',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + localStorage.getItem('token')
        }
    });
}

/**
 * 
 * @param {*} dateStart 
 * @param {*} dateEnd 
 */
export function exportCourses(dateStart, dateEnd) {
    return fetch(config.hostApi + 'courses/exporter', {
        method: 'POST',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + localStorage.getItem('token')
        },
        body: JSON.stringify({
            dateStart: dateStart,
            dateEnd: dateEnd
        }),
        responseType: "blob",
    });
}

/**
 * 
 * @param {*} data 
 * @returns 
 */
export function coursesDeliveredByDay(data){
    return fetch(config.hostApi + 'courses/delivered', {
        method: 'POST',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + localStorage.getItem('token')
        },
        body: JSON.stringify(data)
    });
}

/**
 * 
 * @param {*} data 
 * @returns 
 */
export function coursesPlannedByDay(data){
    return fetch(config.hostApi + 'courses/planned', {
        method: 'POST',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + localStorage.getItem('token')
        },
        body: JSON.stringify(data)
    });
}

/**
 * 
 * @param {*} data 
 * @returns 
 */
export function coursesFailedByDay(data){
    return fetch(config.hostApi + 'courses/failed', {
        method: 'POST',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + localStorage.getItem('token')
        },
        body: JSON.stringify(data)
    });
}

/**
 *
 * @param {*} data
 * @returns 
 */
export function coursesStatusByDay(data){
    return fetch(config.hostApi + 'courses/status', {
        method: 'POST',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + localStorage.getItem('token')
        },
        body: JSON.stringify(data)
    });
}

/**
 * 
 * @param {*} data 
 * @returns 
 */
export function coursesMerchantPrice(data){
    return fetch(config.hostApi + 'courses/merchantprice', {
        method: 'POST',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + localStorage.getItem('token')
        },
        body: JSON.stringify(data)
    });
}

/**
 * 
 * @param {*} formData 
 * @returns 
 */
export function importCourses(formData){
    return fetch(config.hostApi + 'courses/importfile', {
        method: 'POST',
        headers: {
            'Accept': 'application/json',
            'Authorization': 'Bearer' + localStorage.getItem('token')
        },
        body: formData
    });
}

/**
 * 
 * @param {*} data 
 * @returns 
 */
 export function coursesDriverLink(data){
    return fetch(config.hostApi + 'courses/driver/link', {
        method: 'PUT',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + localStorage.getItem('token')
        },
        body: JSON.stringify(data)
    });
}

/**
 * 
 * @param {*} data 
 * @returns 
 */
 export function coursesDriverUnlink(data){
    return fetch(config.hostApi + 'courses/driver/unlink', {
        method: 'PUT',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + localStorage.getItem('token')
        },
        body: JSON.stringify(data)
    });
}

/**
 * 
 * @param {*} data 
 * @returns 
 */
 export function searchCourse(data){
    return fetch(config.hostApi + 'course/search', {
        method: 'POST',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + localStorage.getItem('token')
        },
        body: JSON.stringify(data)
    });
}

/**
 * 
 * @param {*} id 
 */
 export function getErrors(id) {
    return fetch(config.hostApi + 'course/' + id + '/errors', {
        method: 'GET',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + localStorage.getItem('token')
        },
    });
}

/**
 * 
 * @param {*} id 
 */
 export function courseTracking(id) {
    return fetch(config.hostApi + 'course/' + id + '/tracking', {
        method: 'GET',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + localStorage.getItem('token')
        },
    });
}

import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { Button, Space, Row, Col, Drawer, Typography } from 'antd';
import { ArrowRightOutlined } from '@ant-design/icons';

import ModalDriver from './ModalDriver';
import { translateStatus, translateTransport } from "../../constants";

const { Text } = Typography;

const DrawerCourseDeliveryArea = ({ course, visible, fromView, onClose, refresh }) => {

    const [showDriverAssignationModal, setShowDriverAssignationModal] = useState(false);

    if (course === null) return null;

    const onShowDriverAssignationModal = (visible) => {
        setShowDriverAssignationModal(visible);
    };

    let transports = '';
    let driverName = '';
    let statut = translateStatus[course.status];

    if (course.transports.length > 0) {
        transports = course.transports.map(item => translateTransport[item]).join(', ');
    }
    if (course.driver) {
        driverName = course.driver.firstName + " " + course.driver.lastName;
    }

    return (
        <div>
            <Drawer
                title="Zone de livraison souhaitée"
                placement="right"
                onClose={onClose}
                visible={visible}
                width={600}
            >
                <div>
                    <Row gutter={[20, 10]}>
                        <Col span={8}><Text strong>Horaires :</Text></Col>
                        <Col span={16}>{course.collectTime} <ArrowRightOutlined /> {course.deliveryTime} </Col>

                        <Col span={8}><Text strong>Adresse de départ :</Text></Col>
                        <Col span={16}>{course.pickup_area.address}</Col>

                        <Col span={8}><Text strong>Adresse d'arrivée :</Text></Col>
                        <Col span={16}>{course.delivery_area.address}</Col>

                        <Col span={8}><Text strong>Commerçant :</Text></Col>
                        <Col span={16}>{course.pickup_area.name}</Col>

                        <Col span={8}><Text strong>Client :</Text></Col>
                        <Col span={16}>{course.delivery_area.firstname} {course.delivery_area.name}</Col>

                        <Col span={8}><Text strong>Coursier :</Text></Col>
                        <Col span={16}>
                            <Space>
                                {driverName}
                                <Button type="primary" size="small" onClick={() => onShowDriverAssignationModal(true)}>
                                    Assigner
                                </Button>
                            </Space>
                        </Col>

                        <Col span={8}><Text strong>Fixe :</Text></Col>
                        <Col span={16}>{course.delivery_area.phone || "-"}</Col>

                        <Col span={8}><Text strong>Mobile :</Text></Col>
                        <Col span={16}>{course.delivery_area.mobile_phone || "-"}</Col>

                        <Col span={8}><Text strong>Statut :</Text></Col>
                        <Col span={16}>{statut}</Col>

                        <Col span={8}><Text strong>Transports :</Text></Col>
                        <Col span={16}>{transports}</Col>

                        <Col span={8}><Text strong>Description :</Text></Col>
                        <Col span={16}>{course.packetDescription}</Col>
                    </Row>
                    <Space>
                        {(fromView !== 'course') && (
                            <Button type="primary" style={{ marginTop: '20px', width: '170px' }}>
                                <Link to={'/course/' + course.uuid}>Voir la course</Link>
                            </Button>
                        )}
                        {(fromView !== 'merchant') && (
                            <Button type="primary" style={{ marginTop: '20px', width: '170px' }}>
                                <Link to={'/merchant/' + course.merchant_id}>Voir le commerçant</Link>
                            </Button>
                        )}
                        {(fromView !== 'driver' && course.driver !== null) && (
                            <Button type="primary" style={{ marginTop: '20px', width: '170px' }}>
                                <Link to={'/driver/' + course.driver_id}>Voir le transporteur</Link>
                            </Button>
                        )}
                    </Space>
                </div>
            </Drawer>
            <ModalDriver
                selectedRowKeys={[course.id]}
                showModalDriverVisible={showDriverAssignationModal}
                showModalDriver={onShowDriverAssignationModal}
                refresh={refresh}
            />
        </div>
    );
};

export default DrawerCourseDeliveryArea;
import React, {useEffect} from 'react';
import {Link} from 'react-router-dom';

import {Button, Divider, Form, Input, Select, Space} from 'antd';

import {getManyWhiteLabels} from '../../Api/WhiteLabels.js';

const layout = {
    labelCol: {
        span: 8,
    },
    wrapperCol: {
        span: 8,
    },
};

const { Option } = Select;

/**
 * 
 * @param {*} props 
 * @returns 
 */
const FormGeneralTab = (props) => {

    let { merchanttheme, user } = props;

    const [form] = Form.useForm();
    const [value, setValue] = React.useState(undefined);
    let [data, setData] = React.useState([]);

    let selectWhiteLabel = null;
    const option = data.map(d => <Option key={d.id}>{d.name}</Option>);

    /**
     * 
     * @param {*} value 
     */
    const handleSearch = (value) => {
        if (value.length > 2) {
            getManyWhiteLabels({
                term: value
            })
                .then((res) => res.json())
                .then((json) => {
                    setData(data = json.results);
                })
        }
    }

    /**
     * 
     * @param {*} values 
     */
    const onFinish = (values) => {
        let newMerchantTheme = merchanttheme !== null ? { ...merchanttheme } : {};

        newMerchantTheme.name = values.name;

        let selectedWhiteLabelId = user.whiteLabel_id
        if (user.whiteLabel_id === null && values.whiteLabel_id) {
            selectedWhiteLabelId = parseInt(values.whiteLabel_id.value);
        }

        newMerchantTheme.whiteLabel_id = selectedWhiteLabelId;
        props.formSubmit(newMerchantTheme);
    };

    /**
     * 
     */
    useEffect(() => {
        if (merchanttheme !== null) {
            let formValues = {
                name: merchanttheme.name,
                whiteLabel_id: {
                    key: null,
                    value: null,
                    label: null,
                },
            };

            if (merchanttheme.white_label !== null) {
                formValues.whiteLabel_id = {
                    key: merchanttheme.white_label.id,
                    value: merchanttheme.white_label.id,
                    label: merchanttheme.white_label.name,
                }
            }

            form.setFieldsValue(formValues);
        }
    }, [merchanttheme]);

    if (user !== null && user.whiteLabel_id === null) {
        selectWhiteLabel = (
            <Form.Item {...layout}
                label="Marque blanche"
                name="whiteLabel_id"
            >
                <Select
                    labelInValue={true}
                    showSearch
                    value={value}
                    placeholder="Entrer au minimum 3 caractères"
                    showArrow={true}
                    filterOption={false}
                    onSearch={handleSearch}
                    notFoundContent={null}
                    allowClear
                >
                    {option}
                </Select>
            </Form.Item>
        )
    }

    return (
        <Form form={form} name="layout" onFinish={onFinish} layout='vertical'>
            <Form.Item {...layout}
                label="Nom"
                name="name"
                hasFeedback
                rules={[{ required: true, message: 'Merci d\'indiquer un nom de thème commerçant' }]}
            >
                <Input />
            </Form.Item>
            {selectWhiteLabel}
            <Divider></Divider>
            <Form.Item>
                <Space>
                    <Button type="primary" htmlType="submit">
                        Enregistrer
                    </Button>
                    <Button style={{ backgroundcolor: '#2FAC66' }}>
                        <Link to='/merchantthemes'>Annuler</Link>
                    </Button>
                </Space>
            </Form.Item>
        </Form>
    )
}

export default FormGeneralTab;

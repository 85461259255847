import React, {useEffect, useState} from 'react';

import {Button, Divider, Form, Input, Layout, Modal, notification, PageHeader, Popconfirm, Space, Table} from 'antd';
import {CloseOutlined, FormOutlined, UnorderedListOutlined} from '@ant-design/icons';
import {deleteTag, getManyTags, getOneTag, postTag, updateTag} from '../../Api/Settings';

const { Content } = Layout;

/**
 * 
 * @param {*} param0 
 * @returns 
 */
const TagForm = ({ visible, onCreate, onCancel, tagId, tagName }) => {
    const [form] = Form.useForm();

    /**
     * 
     */
    useEffect(() => {
        let formValue = {
            tag: null
        }
        if (tagId && tagName) {
            formValue.tag = tagName
        }
        form.setFieldsValue(formValue)

    }, [tagName, tagId])

    return (
        <Modal
            visible={visible}
            title="Ajouter un tag"
            okText="Enregistrer"
            cancelText="Annuler"
            onCancel={onCancel}
            onOk={() => {
                form
                    .validateFields()
                    .then((values) => {
                        form.resetFields();
                        onCreate(values);
                    })
            }}

        >
            <Form form={form} layout="vertical" name="tag_form">
                <Form.Item name='tag'>
                    <Input />
                </Form.Item>
            </Form>

        </Modal>
    )
}

/**
 * 
 * @param {*} props 
 * @returns 
 */
const SettingsMerchant = (props) => {

    let [tags, setTags] = useState([]);
    let [loadingTags, setLoadingTags] = useState(false);
    let [visible, setVisible] = useState(false);
    let [tagName, setTagName] = useState(null);
    let [pagination, setPagination] = useState({ current: 1, pageSize: 10, })
    let [tagId, setTagId] = useState(null);

    const columns = [
        {
            title: 'Tag',
            dataIndex: 'tag',
            key: 'tag',
        },
        {
            title: '',
            dataIndex: 'id',
            key: 'id',
            width: 100,
            render: (id) => {
                return (
                    <Space size="middle">
                        <Button type='primary' onClick={(e) => editTag(id, e)}>
                            <FormOutlined /> Editer
                        </Button>
                        <Popconfirm
                            title="Etes-vous sûr de vouloir supprimer ce tag ?"
                            onConfirm={(e) => confirmDeleteTag(id, e)}
                            okText="Oui"
                            cancelText="Non"
                        >
                            <Button type='primary' danger className='button-table'>
                                <CloseOutlined /> Supprimer
                            </Button>
                        </Popconfirm>
                    </Space>);
            }
        }
    ]

    /**
     * 
     * @param {*} params 
     */
    const loadTags = (params = {}) => {
        setLoadingTags(true);
        getManyTags(params)
            .then((res) => res.json())
            .then((json) => {
                setLoadingTags(false);
                setTags(json.results);
                setPagination({
                    ...params.pagination,
                    total: json.totalCount,
                })
            })
    }

    /**
     * 
     * @param {*} values 
     */
    const onCreate = (values) => {

        if (tagId) {
            let data = {
                id: tagId,
                tag: values.tag
            }
            updateTag(tagId, data)
                .then(res => res.json())
                .then((data) => {
                    notification['success']({
                        message: 'Le tag a bien été enregistré'
                    })
                    loadTags({ pagination });
                    setTagId(null);
                    setTagName(null);
                })
        } else {
            let data = {
                tag: values.tag
            }
            postTag(data)
                .then(res => res.json())
                .then((data) => {
                    notification['success']({
                        message: 'Le tag a bien été enregistré'
                    })
                    loadTags({ pagination })
                })
        }
        setVisible(false);
    };

    /**
     * 
     */
    const onCancel = () => {
        setVisible(false);
        setTagName(null);
        setTagId(null);
    }

    /**
     * 
     * @param {*} id 
     * @param {*} e 
     */
    const confirmDeleteTag = (id, e) => {
        e.preventDefault();
        deleteTag(id)
            .then((res) => res.json())
            .then(() => {
                loadTags({ pagination });
            })
    }

    /**
     * 
     * @param {*} id 
     * @param {*} e 
     */
    const editTag = (id, e) => {
        e.preventDefault();
        getOneTag(id)
            .then(res => res.json())
            .then((json) => {
                setVisible(true);
                setTagId(json.tag.id);
                setTagName(json.tag.tag)
            })
    }

    /**
     * 
     */
    useEffect(() => {
        loadTags({ pagination })
    }, [])

    return (
        <div>
            <PageHeader title="Paramétrages" />
            <Content style={{ margin: '0 16px 16px 16px' }}>
                <div className="site-layout-background" style={{ padding: 24, minHeight: 360 }}>
                    <h2 style={{ color: "#2FAC66" }}><UnorderedListOutlined /> TAGS</h2>
                    <Divider></Divider>
                    <div style={{ marginBottom: 16 }}>
                        <Button type="primary" onClick={() => { setVisible(true); }}>
                            Ajouter un tag
                        </Button>
                        <Divider></Divider>
                        <Table columns={columns} dataSource={tags} loading={loadingTags} pagination={pagination} />
                        <TagForm visible={visible} onCreate={onCreate} onCancel={onCancel} tagId={tagId} tagName={tagName} />
                    </div>
                </div>
            </Content>

        </div>
    );

}

export default SettingsMerchant;

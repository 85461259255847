import React, { useEffect } from 'react';
import {
    Space,
    Button,
    Form,
    Input,
    InputNumber,
    Checkbox,
    Row,
    Col,
    Drawer,
    Tooltip,
    Typography,
} from 'antd';
import { InfoCircleOutlined } from '@ant-design/icons';

const { TextArea } = Input;
const { Text } = Typography;

const InputNumberCustom = (props) => (
    <InputNumber
        style={{ width: "100%" }}
        placeholder="100"
        prefix="€"
        min={0}
        precision={2}
        formatter={value => value.replace('.', ',')}
        parser={value => value.replace(',', '.')}
        {...props}
    />
);

/**
 *
 * @param {*} props
 * @returns
 */
const PacketForm = (props) => {

    const { course, visible } = props;

    const [form] = Form.useForm();

    /**
     *
     * @param {*} values
     */
    const onSubmit = () => {
        let values = form.getFieldsValue();
        props.onSubmit(values);
    }

    /**
     *
     */
    const onChangePacketSize = () => {
        let values = form.getFieldsValue();
        const { packet_length=null, packet_width=null , packet_height=null } = values;

        if (!packet_length || !packet_width || !packet_height) {
            return;
        }

        let newVolume = (packet_length * packet_width * packet_height) / 1000;
        form.setFieldsValue({ packet_volume: newVolume });
    };

    /**
     *
     */
    useEffect(() => {
        let defaultValues = {
            packet_description: null,
            packet_price: null,
            packet_weight: null,
            packet_length: null,
            packet_width: null,
            packet_height: null,
            packet_volume: null,
            transports: null,
        };

        if (course) {
            defaultValues.packet_description = course.packetDescription;
            defaultValues.packet_price = course.packet_price
            defaultValues.packet_weight = course.packet_weight;
            defaultValues.packet_length = course.packet_length;
            defaultValues.packet_width = course.packet_width;
            defaultValues.packet_height = course.packet_height;
            defaultValues.packet_volume = course.packet_volume;
            defaultValues.transports = course.transports;
        }

        form.setFieldsValue(defaultValues);
    }, [course]);

    let requiredMark = <Text type="danger">*</Text>;
    let labelVolume = (
        <div>
            {requiredMark} Volume
            <Tooltip
                title={"Le volume peut-être renseigné manuellement ou " +
                    "auto-calculé avec les dimensions du colis saisies"}
            >
                <InfoCircleOutlined style={{ marginLeft: 5 }} />
            </Tooltip>
        </div>
    );

    return (
        <Drawer
            title="Paramètres du colis"
            width={720}
            onClose={props.onClose}
            visible={visible}
            bodyStyle={{ paddingBottom: 80 }}
            footer={
                <Space>
                    <Button onClick={props.onClose} style={{ marginRight: 8 }}>
                        {props.viewMode ? "Fermer" : "Annuler"}
                    </Button>
                    <Button onClick={onSubmit} type="primary" disabled={props.viewMode}>
                        Enregistrer
                    </Button>
                </Space>
            }
        >
            <Form form={form} layout="vertical">
                <Form.Item label="Description" name="packet_description">
                    <TextArea
                        rows={3}
                        placeholder={"Vous pouvez préciser ici les dimensions (largeur, hauteur, profondeur), " +
                            "le poids, la nature ou sa fragilité..."}
                    />
                </Form.Item>
                <Form.Item label="Prix H.T." name="packet_price">
                    <InputNumberCustom placeholder="100" prefix="€" />
                </Form.Item>
                <Form.Item label={<div>{requiredMark} Poids</div>} name="packet_weight">
                    <InputNumberCustom placeholder="10" prefix="kg" />
                </Form.Item>
                <Form.Item label={<div>{requiredMark} Longueur</div>} name="packet_length">
                    <InputNumberCustom placeholder="10" prefix="cm" onChange={onChangePacketSize} />
                </Form.Item>
                <Form.Item label={<div>{requiredMark} Largeur</div>} name="packet_width">
                    <InputNumberCustom placeholder="10" prefix="cm" onChange={onChangePacketSize} />
                </Form.Item>
                <Form.Item label={<div>{requiredMark} Hauteur</div>} name="packet_height">
                    <InputNumberCustom placeholder="10" prefix="cm" onChange={onChangePacketSize} />
                </Form.Item>
                <Form.Item label={labelVolume} name="packet_volume">
                    <InputNumberCustom placeholder="10" prefix="dm³" />
                </Form.Item>
                <Form.Item label="Moyens de transport" name="transports">
                    <Checkbox.Group style={{ width: "100%" }} >
                        <Row>
                            <Col span={18}>
                                <Checkbox value="walk" >A pied et transport en commun</Checkbox>
                            </Col>
                            <Col span={18}>
                                <Checkbox value="bike" >A vélo</Checkbox>
                            </Col>
                            <Col span={18}>
                                <Checkbox value="cargoBike">A vélo cargo ou triporteur</Checkbox>
                            </Col>
                            <Col span={18}>
                                <Checkbox value="motorbike">En moto ou scooter</Checkbox>
                            </Col>
                            <Col span={18}>
                                <Checkbox value="car">En voiture</Checkbox>
                            </Col>
                            <Col span={18}>
                                <Checkbox value="commercialVehicle">En véhicule utilitaire</Checkbox>
                            </Col>
                        </Row>
                    </Checkbox.Group>
                </Form.Item>
            </Form>
        </Drawer>
    );
}

export default PacketForm;

import React, { useEffect } from 'react';

import { Form, Input, InputNumber, Button, Space, Drawer, Select, message } from 'antd';
import { getFloatPrice } from "../../utils";

const layout = {
    labelCol: {
        span: 24,
    },
    wrapperCol: {
        span: 24,
    },
};

const DetailedPriceDrawerForm = ({ visible, onCloseDetailedPriceDrawer, onCreatedDetailedPrice, editDetailedPrice, rowKey }) => {
    const [form] = Form.useForm();

    const onSubmitDetailedPrice = () => {
        form.validateFields()
            .then(async (values) => {
                let data = {
                    rowKey: rowKey,
                    values: {
                        ...values,
                        price: getFloatPrice(values.price),
                        volume: (values.volume) ? values.volume * 1000 : null
                    }
                };

                onCreatedDetailedPrice(data);
                form.resetFields();
            })
            .catch(() => {
                message.error("Champs manquants");
            });
    };

    useEffect(() => {
        let formValues = {
            transport: null,
            distance: null,
            weigth: null,
            volume: null,
            price: null,
            zip_code: null
        };

        if (editDetailedPrice) {
            let volume = null;
            if (editDetailedPrice.volume !== null) {
                volume = editDetailedPrice.volume / 1000;
            }

            formValues.transport = editDetailedPrice.transport;
            formValues.distance = editDetailedPrice.distance;
            formValues.weigth = editDetailedPrice.weigth;
            formValues.volume = volume;
            formValues.price = editDetailedPrice.price;
            formValues.zip_code = editDetailedPrice.zip_code;
        }

        form.setFieldsValue(formValues);
    }, [editDetailedPrice, visible]);

    return (
        <Drawer
            title="Configuration des tarifs"
            width={650}
            onClose={onCloseDetailedPriceDrawer}
            visible={visible}
            bodyStyle={{ paddingBottom: 80 }}
            footer={
                <Space>
                    <Button onClick={onCloseDetailedPriceDrawer} style={{ marginRight: 8 }}>
                        Annuler
                    </Button>
                    <Button onClick={onSubmitDetailedPrice} type="primary">
                        Enregistrer
                    </Button>
                </Space>
            }
        >
            <Form {...layout} form={form} name="detailedPriceDrawer" layout="vertical">
                <Form.Item
                    label="Moyen de transport"
                    name="transport"
                    rules={[{
                        required: true,
                        message: "Veuillez sélectionner un moyen de transport"
                    }]}
                >
                    <Select placeholder="Sélectionner un transport">
                        <Select.Option value="all">Tous</Select.Option>
                        <Select.Option value="walk">A pied</Select.Option>
                        <Select.Option value="bike">En vélo</Select.Option>
                        <Select.Option value="cargoBike">En vélo cargo</Select.Option>
                        <Select.Option value="motorbike">En scooter</Select.Option>
                        <Select.Option value="car">En voiture</Select.Option>
                        <Select.Option value="commercialVehicle">En véhicule utilitaire</Select.Option>
                    </Select>
                </Form.Item>
                <Form.Item label="Distance" name="distance">
                    <InputNumber
                        style={{ width: "100%" }}
                        placeholder="3000"
                        prefix="m"
                        min={0}
                        precision={0}
                        formatter={value => value.replace('.', ',')}
                        parser={value => value.replace(',', '.')}
                    />
                </Form.Item>
                <Form.Item label="Poids" name="weigth">
                    <InputNumber
                        style={{ width: "100%" }}
                        placeholder="150"
                        prefix="g"
                        min={0}
                        precision={0}
                        formatter={value => value.replace('.', ',')}
                        parser={value => value.replace(',', '.')}
                    />
                </Form.Item>
                <Form.Item label="Volume" name="volume">
                    <InputNumber
                        style={{ width: "100%" }}
                        placeholder="40"
                        prefix="dm³"
                        min={0}
                        precision={0}
                        formatter={value => value.replace('.', ',')}
                        parser={value => value.replace(',', '.')}
                    />
                </Form.Item>
                <Form.Item label="Code Postal" name="zip_code">
                    <Input placeholder="Code Postal" />
                </Form.Item>
                <Form.Item
                    label="Prix"
                    name="price"
                    rules={[{
                        required: true,
                        message: "Veuillez renseigner un prix"
                    }]}
                >
                    <InputNumber
                        style={{ width: "100%" }}
                        prefix={"€"}
                        min={0}
                        precision={2}
                        formatter={value => value.replace('.', ',')}
                        parser={value => value.replace(',', '.')}
                    />
                </Form.Item>
            </Form>
        </Drawer>
    );
};

export default DetailedPriceDrawerForm;
import React, {useState} from 'react';
import {Link} from 'react-router-dom';
import moment from 'moment';

import {Button, Divider, Form, Layout, PageHeader, Select, Space, Table} from 'antd';
import {FormOutlined, PlusCircleOutlined, UnorderedListOutlined,} from '@ant-design/icons';

import {getManyNetworks} from '../../Api/Networks';
import {getManyWhiteLabels} from '../../Api/WhiteLabels';
import {GlobalContext} from '../../GlobalContext';

const { Content } = Layout;
const { Option } = Select;

const layoutForm = {
    labelAlign: 'left',
    labelCol: {
        span: 4,
    },
    wrapperCol: {
        span: 8,
    },
};

const defaultFilters = {
    whiteLabelId: null,
};

/**
 *
 * @param {*} props (filters)
 * @returns
 */
const Filter = (props) => {
    const [form] = Form.useForm();
    const [ whiteLabels, setWhiteLabels ] = useState([]);

    /**
     *
     * @param {*} value
     */
    const fetchWhiteLabels = (value) => {
        let payload = {
            term : value
        };

        getManyWhiteLabels(payload)
                .then((res) => {
                    if (res.status !== 200) {
                        throw new Error();
                    }
                    return res.json();
                })
                .then((json) => {
                    setWhiteLabels(json.results);
                })
                .catch(() => {});
    };

    /**
     *
     * @param {*} values
     */
    const onFinish = (values) => {
        props.updateFilters(values);
    };

    /**
     *
     */
    const clearFilter = () => {
        form.setFieldsValue({ ...props.filters });
        props.updateFilters({ ...props.filters });
    };

    /**
     *
     * @param {*} value
     */
    const onSearchWhiteLabels = (value) => {
        if (value.length === 0) {
            return;
        }

        fetchWhiteLabels(value);
    };

    let formItems = [];

    if (props.displayWhiteLabel) {
        formItems.push(
            <Form.Item
                label="Marque blanche"
                name="whiteLabelId"
            >
                <Select
                    style={{ width: 300 }}
                    showSearch
                    placeholder="Entrer au minimum 3 caractères"
                    showArrow={true}
                    filterOption={false}
                    onSearch={onSearchWhiteLabels}
                    notFoundContent={null}
                    allowClear
                >
                    {whiteLabels.map((d) => (
                        <Option key={d.id} value={d.id}>{d.name}</Option>
                    ))}
                </Select>
            </Form.Item>
        );
    }

    if (formItems.length === 0) {
        return null;
    }

    return (
        <div>
            <Form
                //{...layoutForm}
                form={form}
                layout="inline"
                onFinish={onFinish}
                initialValues={{ ...props.filters }}
            >
                {formItems}
                <Form.Item>
                    <Space>
                        <Button type="primary" htmlType="submit">
                            Filtrer
                        </Button>
                        <Button onClick={clearFilter}>
                            Annuler
                        </Button>
                    </Space>
                </Form.Item>
            </Form>
            <Divider />
        </div>
    );
};

class Networks extends React.Component {
    static contextType = GlobalContext;
    /**
     *
     * @param {*} props
     */
    constructor(props) {
        super(props);

        this.state = {
            networks: [],
            pagination: {
                current: 1,
                pageSize: 10,
            },
            loading: false,
            data: [],
            value: null,
            filters: { ...defaultFilters }
        };

        this.columns = [
            {
                title: '#',
                dataIndex: 'id',
                key: 'id',
                width: 50
            },
            {
                title: 'Nom',
                dataIndex: 'name',
                sorter: true,
                key: 'name',
            },
            {
                title: 'Marque blanche',
                dataIndex: 'white_label',
                key: 'white_label',
                render: (white_label) => {
                    if (white_label == null) {
                        return <div></div>;
                    } else {
                        return <div>{white_label.name}</div>;
                    }
                },
            },
            {
                title: 'Date de création',
                dataIndex: 'created_at',
                key: 'created_at',
                render: (created_at) => moment(created_at, 'YYYY-MM-DD hh:mm:ss').format('DD/MM/YYYY à hh:mm'),
            },
            {
                title: '',
                dataIndex: 'id',
                key: 'id',
                width: 100,
                render: (id) => (
                    <Space size="middle">
                        <Link to={'/network/' + id}>
                            <Button type="primary" icon={<FormOutlined />}>Editer</Button>
                        </Link>
                    </Space>
                ),
            },
        ];

        this.setFilters = this.setFilters.bind(this);
    }

    /**
     *
     */
    componentDidMount() {
        const { pagination } = this.state;
        this.loadNetworks({ pagination });
    }

    /**
     *
     * @param {*} pagination
     * @param {*} filters
     * @param {*} sorter
     */
    fetchNetworks(pagination, filters={}, sorter={}) {
        let payload = {
            pagination,
        };

        if (Object.keys(sorter).length > 0) {
            payload.sortField = sorter.field;
            payload.sortOrder = sorter.order;
        }

        getManyNetworks(payload)
            .then((res) => res.json())
            .then((json) => {
                this.setState({
                    loading: false,
                    networks: json.results,
                    pagination: {
                        ...pagination,
                        total: json.totalCount,
                    },
                });
            });
    }

    /**
     *
     * @param {*} params
     */
    loadNetworks(params = {}) {
        this.setState({ loading: true });
        getManyNetworks(params)
            .then((res) => res.json())
            .then((json) => {
                this.setState({
                    loading: false,
                    networks: json.results,
                    pagination: {
                        ...params.pagination,
                        total: json.totalCount,
                    },
                });
            });
    }

    /**
     *
     * @param {*} pagination
     * @param {*} filters
     * @param {*} sorter
     */
    handleTableChange = (pagination, filters, sorter) => {

        this.loadNetworks({
            whiteLabel_id: this.state.value,
            sortField: sorter.field,
            sortOrder: sorter.order,
            pagination,
        });
    };

    /**
     *
     * @param {*} values
     */
    setFilters(values) {
        this.setState({ filters: values }, () => {
            this.fetchNetworks(this.state.pagination);
        });
    }

    render() {
        const { networks, pagination, loading } = this.state;

        const user = this.context.global.profile;
        const displayWhiteLabel = (user !== null && user.whiteLabel_id === null);

        return (
            <div>
                <PageHeader title="Réseaux" subTitle="Liste de vos réseaux" />
                <Content style={{ margin: '0 16px 16px 16px' }}>
                    <div className="site-layout-background" style={{ padding: 24, minHeight: 360 }}>
                        <h2 style={{ color: '#2FAC66' }}><UnorderedListOutlined /> LISTE</h2>
                        <div className="button-add">
                            <Space>
                                <Link to="/network/add">
                                    <Button icon={<PlusCircleOutlined />}>Ajouter</Button>
                                </Link>
                            </Space>
                        </div>
                        <Divider />
                        <Filter
                            filters={{ ...defaultFilters }}
                            displayWhiteLabel={displayWhiteLabel}
                            updateFilters={this.setFilters}
                        />
                        <Table
                            columns={this.columns}
                            loading={loading}
                            onChange={this.handleTableChange}
                            pagination={pagination}
                            dataSource={networks}
                        />
                    </div>
                </Content>
            </div>
        );
    }
}

export default Networks;

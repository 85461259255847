
export function getFloatPrice(price) {
    if (typeof price == "string" || price instanceof String) {
        let str = price.replace(',', '.');
        price = parseFloat(str);
    }

    price = price.toFixed(2);
    return parseFloat(price);
}

export function roundUpHalfHour(dateTime) {
    let heures = parseInt(dateTime.format('H'));
    let minutes = parseInt(dateTime.format('m'));

    if (minutes > 30) {
        minutes = 0;
        heures += 1;
    } else if (minutes !== 0) {
        minutes = 30;
    }

    return dateTime.clone().set({ hour: heures, minute: minutes });
}

export function roundDownHalfHour(dateTime) {
    let heures = parseInt(dateTime.format('H'));
    let minutes = parseInt(dateTime.format('m'));

    if (minutes < 30) minutes = 0;
    else if (minutes !== 0) minutes = 30;

    return dateTime.clone().set({ hour: heures, minute: minutes });
}
import config from '../Config.js';

/**
 * 
 * @param {*} data 
 * @returns 
 */
export function getManyDrivers(data) {
    return fetch(config.hostApi + 'drivers', {
        method: 'POST',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + localStorage.getItem('token')
        },
        body: JSON.stringify(data)
    })
}

/**
 * 
 * @param {*} id 
 */
export function getOneDriver(id) {
    return fetch(config.hostApi + 'driver/' + id, {
        method: 'GET',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + localStorage.getItem('token')
        },
    })
}

/**
 * 
 * @param {*} data 
 */
export function postDriver(data) {
    return fetch(config.hostApi + 'driver', {
        method: 'POST',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + localStorage.getItem('token')
        },
        body: JSON.stringify(data)
    })
}

/**
 * 
 * @param {*} id 
 * @param {*} data 
 */
export function updateDriver(id, data) {
    return fetch(config.hostApi + 'driver/' + id, {
        method: 'PUT',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + localStorage.getItem('token')
        },
        body: JSON.stringify(data)
    })
}

/**
 * 
 * @param {*} id 
 */
export function deleteDriver(id) {
    return fetch(config.hostApi + 'driver/' + id, {
        method: 'DELETE',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + localStorage.getItem('token')
        },
    })
}

/**
 * 
 * @param {*} id 
 */
export function getDriverCourses(id, data) {
    return fetch(config.hostApi + 'driver/' + id + '/courses', {
        method: 'POST',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + localStorage.getItem('token')
        },
        body: JSON.stringify(data)
    })
}

/**
 * 
 * 
 */
export function trackingDrivers() {
    return fetch(config.hostApi + 'drivers/tracking', {
        method: 'GET',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + localStorage.getItem('token')
        }
    })
}

/**
 * 
 * @param {*} id 
 * @returns 
 */
export function driverValidate(id) {
    return fetch(config.hostApi + 'driver/' + id + '/valider', {
        method: 'GET',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + localStorage.getItem('token')
        }
    })
}

/**
 * 
 * @param {*} id 
 */
export function driverEject(id) {
    return fetch(config.hostApi + 'driver/' + id + '/expulser', {
        method: 'GET',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + localStorage.getItem('token')
        }
    })
}

/**
 * 
 * @param {*} id 
 */
 export function findFilterDayCourses(id, payload) {
    return fetch(config.hostApi + 'driver/' + id + '/courses/findDay', {
        method: 'POST',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': 'Bearer' + localStorage.getItem('token')
        },
        body: JSON.stringify(payload)
    });
}

/**
 * 
 * @param {*} id 
 */
 export function driverTracking(id) {
    return fetch(config.hostApi + 'driver/'+ id +'/tracking', {
        method: 'GET',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + localStorage.getItem('token')
        }
    })
}
import config from '../Config.js';

/**
 * 
 * @param {*} data 
 * @returns 
 */
export function getManyTours(data) {
    return fetch(config.hostApi + 'tours', {
        method: 'POST',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + localStorage.getItem('token')
        },
        body: JSON.stringify(data),
    });
}

/**
 * 
 * @param {*} id 
 */
export function getOneTour(id) {
    return fetch(config.hostApi + 'tour/' + id, {
        method: 'GET',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + localStorage.getItem('token')
        },
    });
}

/**
 *
 * @param {*} data
 */
export function postTourCoursesOrder(id, data) {
    return fetch(config.hostApi + 'tour/' + id + '/courses/order', {
        method: 'POST',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + localStorage.getItem('token')
        },
        body: JSON.stringify(data)
    })
}

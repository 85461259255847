import React from 'react';

import {Form, Space} from 'antd';

import WorkingHours from "../../Components/WorkingHours";

import '../../Components/WorkingHours/WorkingHours.css'

const layout = {
    labelCol: {
        span: 24,
    },
    wrapperCol: {
        span: 24,
    },
};

/**
 * 
 * @param {*} props 
 * @returns 
 */
const FormOpeningScheduleTab = (props) => {

    let { merchant, openingSchedule } = props;

    const [form] = Form.useForm();

    let [data, setData] = React.useState(openingSchedule);

    var days = [
        { key: "0", name: "Dimanche" },
        { key: "1", name: "Lundi" },
        { key: "2", name: "Mardi" },
        { key: "3", name: "Mercredi" },
        { key: "4", name: "Jeudi" },
        { key: "5", name: "Vendredi" },
        { key: "6", name: "Samedi" },
    ];
    
    var fieldName = "location[working_hours]";

    return (
        <div>
            <Space direction="vertical">
                <div>
                    Sélectionnez vos horaires d'ouverture
                </div>
                <WorkingHours days={days} fieldName={fieldName} data={data} merchant={merchant}/>
            </Space>
        </div>
        
    )
}

export default FormOpeningScheduleTab;

import React, {useState} from 'react';

import {Alert, Button, Divider, Layout, message, PageHeader, Space, Upload} from 'antd';
import {UnorderedListOutlined, UploadOutlined} from '@ant-design/icons';
import {importCourses} from '../../Api/Courses';
import {saveAs} from 'file-saver';
import config from '../../Config.js';

const {Content} = Layout;

const CourseImport = () => {

    const TEMPLATE_IMPORT_FILE = config.hostApp + '/import_courses_exemple.csv';

    const [fileList, setFileList] = useState([])
    const [uploading, setUploading] = useState(false)
    const [errors, setErrors] = useState(false)

    const props = {
        onRemove: file => {
            const index = fileList.indexOf(file);
            const newFileList = fileList.slice();
            newFileList.splice(index, 1);
            setFileList(newFileList);
        },
        beforeUpload: file => {
            if (fileList.length === 0) {
                setFileList([...fileList, file])
            } else {
                message.error('Vous ne pouvez importer qu\'un seul fichier.');
                setFileList([...fileList])
            }
            return false;
        },
        fileList,
    };

    /**
     *
     * @param {*} file
     */
    const uploadMedia = () => {

        const formData = new FormData();
        formData.append('document', fileList[0]);

        importCourses(formData)
            .then(response => response.json())
            .then(data => {
                data.map(d => {
                    if (d === null) {
                        setFileList([]);
                        setUploading(false);
                        message.success("Votre fichier a bien été importé.");
                    } else {
                        setErrors(true);
                        setFileList([]);
                        setUploading(false);
                        message.error("Votre fichier comporte des erreurs.");
                    }
                })
            })
    }

    const downloadErrorFile = () => {
        let errorFilePath = config.hostApi + 'courses/downloadimporterrrors'
        const options = {
            headers: {
                'Authorization': 'Bearer ' + localStorage.getItem('token')
            }
        };
        fetch(errorFilePath, options)
            .then(res => res.blob())
            .then(blob => saveAs(blob, 'import-errors.csv'));
    }

    let errorAlert = null
    if (errors === true) {
        errorAlert = (
            <Alert
                showIcon
                type="warning"
                style={{marginTop: '20px', marginBottom: '20px'}}
                message={
                    <div>
                        <p> L'import a généré des erreurs. Toutes les courses n'ont pas pu êtres importées :</p>
                        <Button onClick={downloadErrorFile}>Télécharger les courses avec erreurs</Button>
                    </div>
                }
            />
        )
    }

    return (
        <div>
            <PageHeader title="Courses" subTitle="Importer des courses"/>
            <Content style={{margin: '0 16px 16px 16px'}}>
                <div className="site-layout-background" style={{padding: 24, minHeight: 360}}>
                    <h2 style={{color: "#2FAC66"}}><UnorderedListOutlined/> FICHIER</h2>
                    <Divider></Divider>
                    <Alert
                        description="L'import de courses par fichier est brut. Aucune vérification des données, des distances,
                                des tarifs n'est faite par rapport à un quelconque paramètrage de Network. Le fichier d'import doit
                                respecter le format du fichier ci-dessous."
                        type="info"
                        showIcon
                    />
                    <Space className="download-exemple">
                        <div>
                            <a href={TEMPLATE_IMPORT_FILE} download="import_courses_exemple.csv">Télécharger le modèle
                                du
                                fichier </a>
                        </div>
                    </Space>
                    <div className="download-form" style={{textAlign: 'center'}}>
                        Fichier* (.csv){" "}
                        <Upload {...props} >
                            <Button icon={<UploadOutlined/>}>Choisissez un fichier</Button>
                        </Upload>
                        <Button
                            type="primary"
                            onClick={uploadMedia}
                            disabled={fileList.length === 0}
                            loading={uploading}
                            style={{marginTop: 16}}
                        >
                            {uploading ? 'Uploading' : 'Importer'}
                        </Button>
                    </div>
                    {errorAlert}
                </div>
            </Content>
        </div>

    )
}

export default CourseImport;

import React from 'react';
import {Redirect} from 'react-router-dom';

import {Divider, Layout, notification, PageHeader} from 'antd';
import {InfoCircleOutlined} from '@ant-design/icons';

import {postMerchant} from '../../Api/Merchants.js';
import FormMerchant from './FormMerchant.js';
import {GlobalContext} from '../../GlobalContext.js';

class MerchantAdd extends React.Component {
    static contextType = GlobalContext;
    /**
     * 
     * @param {*} props 
     */
    constructor(props) {
        super(props);

        this.state = {
            value: 1,
            redirection: false,
            id: null,
            merchant: null,
            user: null
        }

        this.onSubmit = this.onSubmit.bind(this);
    }

    /**
     * 
     * @param {*} values 
     */
    onSubmit(values) {
        let newMerchant = {
            name: values.name,
            whiteLabel_id: values.whiteLabel_id,
            address: values.address,
            social_name: values.socialName,
            owner_firstName: values.ownerFirstName,
            owner_lastName: values.ownerLastName,
            owner_phone: values.ownerPhone,
            owner_email: values.ownerEmail,
            phone: values.phone,
            tva: values.tva,
            siret: values.siret,
            legal_form: values.legalForm,
            latitude: values.latitude,
            longitude: values.longitude,
            network_id: values.network_id,
            merchantTheme_id: values.merchantTheme_id,
            price_grid: 1,
            owner_password: values.owner_password,
            owner_confirmation_password: values.owner_confirmation_password,
            attributed_merchant_tags: values.attributed_merchant_tags
        }
        postMerchant(newMerchant)
            .then(function (response) {
                return response.json();
            }).then((data) => {
                this.setState({
                    id: data.merchant.id,
                    redirection: true
                });
                notification['success']({
                    message: 'Le commerçant a bien été enregistré'
                })

            });
    };

    render() {
        const { Content } = Layout;
        const { redirection } = this.state;
        const { id } = this.state;

        if (redirection) {
            return (<Redirect to={'/merchant/' + id} />)
        }

        return (
            <div>
                <PageHeader title="Commerçants" subTitle="Création d'un commerçant" />
                <Content style={{ margin: '0 16px 16px 16px' }}>
                    <div className="site-layout-background" style={{ padding: 24, minHeight: 360 }}>
                        <h2 style={{ color: "#2FAC66" }}><InfoCircleOutlined /> INFORMATIONS</h2>
                        <Divider></Divider>
                        <FormMerchant merchant={this.state.merchant} user={this.context.global.profile} formSubmit={this.onSubmit} />
                    </div>
                </Content>
            </div>
        )
    }
}

export default MerchantAdd;

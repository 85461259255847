import React, { useState } from 'react';
import moment from 'moment';
import { Row, Col, Badge } from 'antd';
import { XAxis, YAxis, Tooltip, CartesianGrid, ResponsiveContainer, LineChart, Line } from 'recharts';
import CustomizedAxisTick from './CustomizedAxisTick';

/**
 *
 * @param {*} active
 * @param {*} payload
 * @param {*} label
 * @returns
 */
const CustomTooltipMerchantPrice = ({ active, payload, label }) => {
    if (active && payload && payload.length) {
        return (
            <div className="custom-tooltip">
                <p className="custom-tooltip-title">{`${moment(payload[0].payload.deliveryDate, 'YYYYMMDD').format('DD/MM/YYYY')} `}</p>
                <p className="custom-tooltip-label" style={{color: "#2FAC66"}}>{`Prix des courses réussies : ${payload[0].payload.success} €`}</p>
                <p className="custom-tooltip-label" style={{color: "#0B4624"}}>{`Prix des courses en échec : ${payload[1].payload.error} €`} </p>
            </div>
        );
    }

    return null;
};

/**
 *
 * @param {*} data
 * @param {*} priceSuccess
 * @param {*} priceError
 * @returns
 */
const ChartCoursesPrices = ({ data, priceSuccess, priceError }) => {
    return (
        <div>
            <Row style={{ height: '45px' }}>
                <Col span={8}><h3>Prix par jour</h3></Col>
                <Col span={16} style={{ textAlign: 'right', paddingRight: '26px' }}>
                    <small>Prix total des courses délivrées</small>
                    <Badge
                        style={{ backgroundColor: '#2FAC66', marginLeft: '5px' }}
                        count={priceSuccess + " €"}
                        showZero={true}
                        overflowCount={99999}
                    />
                    <br />
                    <small>Prix total des courses échouées</small>
                    <Badge
                        style={{ backgroundColor: '#2FAC66', marginLeft: '5px' }}
                        count={priceError + " €"}
                        showZero={true}
                        overflowCount={99999}
                    />
                </Col>
            </Row>
            <ResponsiveContainer width="100%" height={500}>
                <LineChart
                    data={data}
                    margin={{
                        top: 20,
                        right: 30,
                        left: 20,
                        bottom: 5,
                    }}
                >
                    <CartesianGrid strokeDasharray="3 3" />
                    <XAxis dataKey="deliveryDate" minTickGap={2} tick={<CustomizedAxisTick />} height={60} />
                    <YAxis />
                    <Tooltip content={<CustomTooltipMerchantPrice />} />
                    <Line type="monotone" dataKey="success" stroke="#2FAC66" />
                    <Line type="monotone" dataKey="error" stroke="#0B4624" />
                </LineChart>
            </ResponsiveContainer>
        </div>
    );
};

export default ChartCoursesPrices;
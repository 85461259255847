import React, {useEffect} from 'react';
import {Link, Redirect} from 'react-router-dom';
import moment from 'moment';

import {Alert, Button, Modal, Space, Table, Tag} from 'antd';
import {CaretRightOutlined, EyeOutlined, FormOutlined} from '@ant-design/icons';

import DrawerDetailsCourse from '../../Components/Course/DrawerDetailsCourse.js';

import {courseValidate, getOneCourse} from '../../Api/Courses.js';
import ModalDriver from '../../Components/Course/ModalDriver.js';
import {getMerchantCourses} from '../../Api/Merchants.js';
import {translateStatus} from "../../constants";

/**
 *
 * @param {*} props
 * @returns
 */
const FormCoursesTab = (props) => {

    let { merchant } = props;

    let [selectedRowKeys, setSelectedRowKeys] = React.useState([]);
    let [isModalVisible, setIsModalVisible] = React.useState(false);

    let [courseId, setCourseId] = React.useState(null);
    let [courseDetails, setCourseDetails] = React.useState(null);
    let [showDrawerCourseDetails, setShowDrawerCourseDetails] = React.useState(false);
    let [redirection, setRedirection] = React.useState(false);
    let [paginate, setPaginate] = React.useState({ current: 1, pageSize: 10 });
    let [loading, setLoading] = React.useState(false);
    let [courses, setCourses] = React.useState([]);
    let [modalAvailableVisible, setModalAvailable] = React.useState(false);
    let [previousPrice, setPreviousPrice] = React.useState(null);
    let [newPrice, setNewPrice] = React.useState(null);

    const columns = [
        {
            title: '#',
            dataIndex: 'short_uuid',
            key: 'short_uuid',
            width: 50
        },
        {
            title: 'Date de livraison',
            dataIndex: 'deliveryDate',
            key: 'deliveryDate',
            render: deliveryDate => {
                if (deliveryDate !== null) {
                    return moment(deliveryDate).format('DD/MM/YYYY')
                }

                return;
            }
        },
        {
            title: 'A partir de',
            key: 'id',
            render: (row) => (
                <div>{row.collectTime}</div>
            )
        },
        {
            title: 'Avant',
            key: 'id',
            render: (row) => (
                <div>{row.deliveryTime}</div>
            )
        },
        {
            title: 'Client',
            dataIndex: 'customer',
            key: 'customer',
            render: (customer) => {
                if (customer == null) {
                    return (
                        <div></div>
                    )
                } else {
                    return (
                        <Link to={'/customer/' + customer.id}>{customer.firstname} {customer.name}</Link>
                    )
                }
            }
        },
        {
            title: 'Adresse de livraison',
            dataIndex: 'delivery_area',
            key: 'delivery_area',
            render: (delivery_area) => {
                if (delivery_area == null) {
                    return (
                        <div></div>
                    )
                } else {
                    return (
                        <p>{delivery_area.address}</p>
                    )
                }
            }
        },
        {
            title: 'Transporteur',
            dataIndex: 'driver',
            key: 'driver',
            render: (driver) => {
                if (driver == null) {
                    return (
                        <div></div>
                    )
                } else {
                    return (
                        <Link to={'/driver/' + driver.id}>{driver.firstName} {driver.lastName}</Link>
                    )
                }
            }
        },
        {
            title: 'Véhicule',
            dataIndex: 'transports',
            key: 'transports',
            render: (transports) => {
                if (transports == null) {
                    return (
                        <div></div>
                    )
                } else {
                    const translate = {
                        bike: 'Vélo',
                        walk: 'A pied',
                        cargoBike: 'Vélo cargo',
                        motorbike: 'Scooter ou moto',
                        car: 'Voiture',
                        bus: 'Bus',
                        tram: 'Tram',
                        commercialVehicle: 'Véhicule commercial',
                        all: 'Tous',
                    }
                    let transportTranslate = []
                    transports.map((transport) => {
                        transportTranslate.push(translate[transport])
                    })
                    return (
                        <p>{transportTranslate}</p>
                    )

                }
            }
        },
        {
            title: 'Statut',
            dataIndex: 'status',
            key: 'status',
            width: 100,
            render: (status) => {
                return (
                    <Tag color="green" key={status}>
                        {translateStatus[status]}
                    </Tag>
                )
            }
        },
        {
            title: '',
            key: 'course',
            width: 250,
            render: (course) => {
                return (
                    <div>
                        <Space wrap>
                            <Button type='primary' onClick={() => showDrawerCourseInfo(course.id)}>
                                <EyeOutlined />
                            </Button>
                            <Button type='primary' >
                                <Link to={'/course/' + course.uuid}><FormOutlined /> </Link>
                            </Button>
                            <Button type='primary' onClick={() => onClickValidate(course.id)}>
                                <CaretRightOutlined />
                            </Button>
                        </Space>
                    </div>
                )
            }
        },
    ];

    /**
     *
     */
    const fetchCourses = () => {
        const merchantId = merchant.id;
        setLoading(true);
        let paginationToSend = {
            pagination: {
                current: paginate.current,
                pageSize: paginate.pageSize
            }
        };
        getMerchantCourses(merchantId, paginationToSend)
            .then((res) => res.json())
            .then((json) => {
                let coursesWithKey = json.results;
                coursesWithKey.map((course) => {
                    course.key = course.id
                })
                setLoading(false);
                setCourses(coursesWithKey);
                setPaginate({ current: paginate.current, pageSize: paginate.pageSize, total: json.totalCount });
            })
    }

    /**
     *
     * @param {*} pagination
     * @param {*} filters
     * @param {*} sorter
     */
    const handleTableChange = (pagination, filters, sorter) => {
        let paginationToSend = {
            pagination: {
                current: pagination.current,
                pageSize: pagination.pageSize
            }
        };
        const merchantId = merchant.id;
        getMerchantCourses(merchantId, paginationToSend)
            .then((res) => res.json())
            .then((json) => {
                let coursesWithKey = json.results;
                coursesWithKey.map((course) => {
                    course.key = course.id
                })
                setLoading(false);
                setCourses(coursesWithKey);
                setPaginate({ current: pagination.current, pageSize: pagination.pageSize, total: json.totalCount });
            })
    };

    /**
     *
     * @param {*} selectedRowKeys
     * @param {*} selectedRows
     */
    const onSelectChange = selectedRows => {
        setSelectedRowKeys(selectedRows);
    };


    /**
     *
     */
    const showModal = () => {
        setIsModalVisible(true)
    };

    /**
     *
     * @param {*} value
     */
    const showModalDriver = (value) => {
        setSelectedRowKeys([]);
        setIsModalVisible(value);
        fetchCourses();
    }

    /**
     *
     */
    const rowSelection = {
        selectedRowKeys,
        onChange: onSelectChange,
        getCheckboxProps: (course) => ({
            disabled: course.price === null
        })
    };

    const hasSelected = selectedRowKeys.length > 0;

    /**
     *
     * @param {*} id
     */
    const onClickValidate = (id) => {
        let confirm = false
        courseValidate(id, confirm)
            .then((res) => res.json())
            .then((data) => {
                if (data.error === null) {
                    fetchCourses();
                } else {
                    setModalAvailable(true);
                    setNewPrice(data.newPrice);
                    setPreviousPrice(data.previousPrice);
                    setCourseDetails(data.course);
                }
            })
    }

    /**
     *
     * @param {*} id
     */
    const showDrawerCourseInfo = (id) => {
        setShowDrawerCourseDetails(true);
        getOneCourse(id)
            .then((res) => res.json())
            .then((json) => {
                setCourseDetails(json.course)
            });
    }

    /**
     *
     * @param {*} value
     */
    const showDrawer = (value) => {
        setShowDrawerCourseDetails(value)
    }

    /**
     * 
     */
    const handleAvailableOk = () => {
        const courseId = courseDetails.id;
        const confirm = true;
        courseValidate(courseId, confirm)
            .then((res) => res.json())
            .then((data) => {
                if (data.error === null) {
                    fetchCourses();
                } else {
                    setModalAvailable(true);
                    setNewPrice(data.newPrice);
                    setPreviousPrice(data.previousPrice);
                }

            })
        setModalAvailable(false);
    }

    /**
     * 
     */
    const handleAvailableCancel = () => {
        setModalAvailable(false);
    }

    /**
     *
     */
    useEffect(() => {
        if (merchant) {
            fetchCourses()
        }
    }, [merchant])

    if (redirection) {
        return (<Redirect to={'/course/' + courseId} />)
    }

    return (
        <div>
            <div style={{ marginBottom: 16 }}>
                <Button type="primary" onClick={showModal} disabled={!hasSelected} >
                    Transporteur
                </Button>
                <ModalDriver selectedRowKeys={selectedRowKeys} showModalDriverVisible={isModalVisible} showModalDriver={showModalDriver} />
            </div>
            <Table
                rowSelection={rowSelection}
                columns={columns}
                loading={loading}
                onChange={handleTableChange}
                pagination={{
                    current: paginate.current,
                    pageSize: paginate.pageSize,
                    showSizeChanger: true,
                    total: paginate.total
                }}
                dataSource={courses}
            />
            <DrawerDetailsCourse course={courseDetails} showDrawerCourseDetails={showDrawerCourseDetails} showDrawer={showDrawer} />
            <Modal visible={modalAvailableVisible} onOk={handleAvailableOk} onCancel={handleAvailableCancel} cancelText='Annuler'>
                <Alert
                    showIcon
                    message="Le prix de la course a changé."
                    description={
                        <div>
                            <p>Ancien prix : {previousPrice} €</p>
                            <p>Nouveau prix : {newPrice} €</p>
                            <p>Confirmez-vous l'émission de la course avec le nouveau prix ?</p>
                        </div>
                    }
                    type="warning"
                    style={{ marginTop: '20px', marginBottom: '20px' }}
                />
            </Modal>
        </div>
    )
}

export default FormCoursesTab;

import React, {useState} from 'react';

import mapboxgl from 'mapbox-gl';
import '@mapbox/mapbox-gl-draw/dist/mapbox-gl-draw.css';

import {Avatar, Button, Col, DatePicker, Divider, Form, Layout, PageHeader, Row, Select, Space, TimePicker} from 'antd';
import {ShoppingCartOutlined} from '@ant-design/icons';

import {getList as getCourses, getOneCourse} from '../../Api/Courses';
import {getManyDrivers as getDrivers, trackingDrivers} from '../../Api/Drivers';
import {getManyMerchants as getMerchants} from '../../Api/Merchants';

import DrawerCourseDeliveryArea from '../../Components/Course/DrawerCourseDeliveryArea';
import DrawerCoursePickupArea from '../../Components/Course/DrawerCoursePickupArea';
import DrawerCoursePositionDriver from '../../Components/Course/DrawerCoursePositionDriver';

import config from '../../Config';
import moment from 'moment';

mapboxgl.accessToken = config.mapboxToken;

const { Content } = Layout;
const { Option } = Select;
const { RangePicker } = DatePicker;

const timeFormat = 'HH:mm';
const dateFormat = 'YYYY-MM-DD';
const layoutForm = {
    style: {
        paddingTop: 10,
    },
    labelAlign: 'left',
    labelCol: {
        span: 6,
    },
    wrapperCol: {
        span: 18,
        style: {
            paddingRight: 20
        }
    },
};
const layoutFormItem = {
    style: {
        marginBottom: 20
    }
};

const defaultFilters = {
    deliveryDates: [moment(), moment()],
    merchantId: null,
    driverId: null,
    pickupTime: [null, null],
    deliveryTime: [null, null],
    status: ['available', 'assigned', 'started', 'inProgress'],
};

/**
 *
 * @param {*} props (filters)
 * @returns
 */
const Filter = (props) => {
    const [form] = Form.useForm();
    const [merchants, setMerchants] = useState([]);
    const [drivers, setDrivers] = useState([]);

    /**
     *
     * @param {*} value
     */
    const fetchMerchants = (payload) => {
        getMerchants(payload)
            .then((res) => {
                if (res.status !== 200) {
                    throw Error();
                }
                return res.json();
            })
            .then((json) => {
                setMerchants(json.results);
            })
            .catch((err) => {});
    };

    /**
     *
     * @param {*} value
     */
    const onSearchMerchants = (value) => {
        if (value.length <= 2) {
            return;
        }

        let payload = { term: value };
        fetchMerchants(payload);
    };

    /**
     *
     * @param {*} value
     */
    const fetchDrivers = (payload) => {
        getDrivers(payload)
            .then((res) => {
                if (res.status !== 200) {
                    throw Error();
                }
                return res.json();
            })
            .then((json) => {
                setDrivers(json.results);
            })
            .catch((err) => {});
    };

    /**
     *
     * @param {*} value
     */
    const onSearchDrivers = (value) => {
        if (value.length <= 2) {
            return;
        }

        let payload = { term: value };
        fetchDrivers(payload);
    };

    /**
     *
     * @param {*} values
     */
    const onFinish = (values) => {
        props.updateFilters(values);
    };

    /**
     *
     */
    const clearFilter = () => {
        form.setFieldsValue({ ...props.filters });
        props.updateFilters({ ...props.filters });
    };

    const driverOptions = drivers.map((d) => <Option key={d.id} value={d.id}>{d.commercialName}</Option>);
    const merchantOptions = merchants.map((d) => <Option key={d.id} value={d.id}>{d.name}</Option>);

    return (
        <Form
            {...layoutForm}
            form={form}
            onFinish={onFinish}
            initialValues={{ ...props.filters }}
        >
            <Row>
                <Col span={12}>
                    <Form.Item
                        {...layoutFormItem}
                        label="Date de livraison entre le "
                        name="deliveryDates"
                    >
                        <RangePicker
                            style={{ width: '100%' }}
                            format={'DD/MM/YYYY'}
                            allowClear={false}
                        />
                    </Form.Item>
                </Col>
            </Row>
            <Row>
                <Col span={12}>
                    <Form.Item {...layoutFormItem} name="merchantId" label="Commercant">
                        <Select
                            showSearch
                            placeholder="Entrer au minimum 3 caractères"
                            showArrow={true}
                            filterOption={false}
                            onSearch={onSearchMerchants}
                            notFoundContent={null}
                            allowClear
                        >
                            {merchantOptions}
                        </Select>
                    </Form.Item>
                </Col>
                <Col span={12}>
                    <Form.Item {...layoutFormItem} name="driverId" label="Transporteur">
                        <Select
                            showSearch
                            placeholder="Entrer au minimum 3 caractères"
                            showArrow={true}
                            filterOption={false}
                            onSearch={onSearchDrivers}
                            notFoundContent={null}
                            allowClear
                        >
                            {driverOptions}
                        </Select>
                    </Form.Item>
                </Col>
            </Row>
            <Row>
                <Col span={12}>
                    <Form.Item {...layoutFormItem} name="pickupTime" label="Heure de retrait">
                        <TimePicker.RangePicker
                            style={{ width: '100%' }}
                            placeholder={['Heure de début', 'Heure de fin']}
                            format={timeFormat}
                        />
                    </Form.Item>
                </Col>
                <Col span={12}>
                    <Form.Item {...layoutFormItem} name="deliveryTime" label="Heure de livraison">
                        <TimePicker.RangePicker
                            style={{ width: '100%' }}
                            placeholder={['Heure de début', 'Heure de fin']}
                            format={timeFormat}
                        />
                    </Form.Item>
                </Col>
            </Row>
            <Row>
                <Col span={12}>
                    <Form.Item {...layoutFormItem} name="status" label="Status des courses">
                        <Select
                            mode="multiple"
                            placeholder="Sélectionner un ou plusieurs statuts"
                        >
                            <Option value="available">Emise</Option>
                            <Option value="assigned">Assignée</Option>
                            <Option value="started">Démarée</Option>
                            <Option value="inProgress">Colis Récupéré</Option>
                            <Option value="isDelivered">Colis livré</Option>
                            <Option value="failed">Echouée (Livraison)</Option>
                            <Option value="pickupFailed">Echouée (Retrait)</Option>
                            <Option value="canceled">Annulée</Option>
                            <Option value="returned">Retournée</Option>
                        </Select>
                    </Form.Item>
                </Col>
            </Row>
            <Row>
                <Col span={24}>
                    <Form.Item>
                        <Space>
                            <Button type="primary" htmlType="submit">
                                Filtrer
                            </Button>
                            <Button onClick={clearFilter}>
                                Annuler
                            </Button>
                        </Space>
                    </Form.Item>
                </Col>
            </Row>
        </Form>
    );
};

class MapDispatch extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            deliveryArea: [],
            pickupArea: [],
            courses: [],
            trackingDrivers: [],
            lng: 2,
            lat: 46.7,
            zoom: 5.4,
            visibleDeliveryAreaDrawer: false,
            visiblePickupAreaDrawer: false,
            visibleDriverDrawer: false,
            course: null,
            driver: null,
            filters: { ...defaultFilters },
            loadInterval: null
        };

        this.map = null;
        this.markers = [];
        this.mapContainer = React.createRef();

        this.setFilters = this.setFilters.bind(this);
        this.onCloseDeliveryAreaDrawer = this.onCloseDeliveryAreaDrawer.bind(this);
        this.onClosePickupAreaDrawer = this.onClosePickupAreaDrawer.bind(this);
        this.onCloseDriverDrawer = this.onCloseDriverDrawer.bind(this);
        this.refresh = this.refresh.bind(this);
    }

    /**
     *
     */
    async componentDidMount() {
        // init map
        const { lng, lat, zoom } = this.state;
        this.map = new mapboxgl.Map({
            container: this.mapContainer.current,
            style: 'mapbox://styles/mapbox/streets-v11',
            center: [lng, lat],
            zoom: zoom,
        });

        await this.loadData();

        let interval = setInterval(() => {
            this.loadData(false); // fitbound = false
        }, 15000);
        this.setState({ loadInterval: interval });
    }

    componentWillUnmount() {
        clearInterval(this.state.loadInterval);
    }

    /**
     *
     */
    getPayload() {
        const filters = this.state.filters;

        let payload = {
            dateStart: filters.deliveryDates[0].format(dateFormat),
            dateEnd: filters.deliveryDates[1].format(dateFormat),
        };

        if (!filters.pickupTime.includes(null)) {
            payload.pickupTimeStart = filters.pickupTime[0].format(timeFormat);
            payload.pickupTimeEnd = filters.pickupTime[1].format(timeFormat);
        }
        if (!filters.deliveryTime.includes(null)) {
            payload.deliveryTimeStart = filters.deliveryTime[0].format(timeFormat);
            payload.deliveryTimeEnd = filters.deliveryTime[1].format(timeFormat);
        }
        if (filters.merchantId) {
            payload.merchantId = filters.merchantId;
        }
        if (filters.driverId) {
            payload.driverId = filters.driverId;
        }
        if (filters.status.length > 0) {
            payload.status = filters.status;
        }

        return payload;
    }

    /**
     *
     */
    async fetchCourses() {
        const page = 1;
        const limit = 0; // no limit

        let payload = this.getPayload();

        return getCourses(page, limit, payload)
            .then((res) => res.json())
            .then((json) => {
                let data = json.courses;
                let geoJsonDelivery = {};
                let featureDelivery = [];
                let featureDetailDelivery = {};
                let geoJsonPickup = {};
                let featurePickup = [];
                let featureDetailPickup = {};
                data.map((d) => {
                    let deliveryLat = null;
                    let deliveryLng = null;
                    let pickupLat = null;
                    let pickupLng = null;
                    if (d.delivery_area !== null) {
                        deliveryLat = d.delivery_area.lat;
                        deliveryLng = d.delivery_area.lng;
                    }
                    if (d.pickup_area !== null) {
                        pickupLat = d.pickup_area.lat;
                        pickupLng = d.pickup_area.lng;
                    }
                    featureDetailDelivery = {
                        type: 'Feature',
                        geometry: {
                            type: 'Point',
                            coordinates: [deliveryLng, deliveryLat]
                        },
                        properties: {
                            course_id: d.id,
                        }
                    };
                    featureDetailPickup = {
                        type: 'Feature',
                        geometry: {
                            type: 'Point',
                            coordinates: [pickupLng, pickupLat]
                        },
                        properties: {
                            course_id: d.id,
                        }
                    };

                    featureDelivery.push(featureDetailDelivery);
                    featurePickup.push(featureDetailPickup);
                })
                geoJsonDelivery = {
                    type: 'featureCollection',
                    features: featureDelivery
                };

                geoJsonPickup = {
                    type: 'featureCollection',
                    features: featurePickup
                };
                this.setState({ courses: data, deliveryArea: geoJsonDelivery, pickupArea: geoJsonPickup });
            });
    }

    /**
     *
     */
    async loadTrackingDrivers() {
        await trackingDrivers()
            .then((res) => res.json())
            .then((json) => {
                let data = json.tracking;
                let geoJsonTracking = {};
                let featureTracking = [];
                let featureDetailTracking = {};

                if (data.length !== 0) {
                    data.forEach((d) => {
                        if(d.longitude && d.latitude) {
                            featureDetailTracking = {
                                type: 'Feature',
                                geometry: {
                                    type: 'Point',
                                    coordinates: [d.longitude, d.latitude]
                                },
                                properties: {
                                    title: d.driver.firstName + ' ' + d.driver.lastName,
                                    phone: d.driver.phone,
                                    lastTracking: d.received_at,
                                    driver_id: d.driver.id
                                }
                            };
                            featureTracking.push(featureDetailTracking);
                        }
                    });

                    geoJsonTracking = {
                        type: 'featureCollection',
                        features: featureTracking
                    };

                    this.setState({ trackingDrivers: geoJsonTracking });
                }
            });
    }

    /**
     *
     */
    async loadData(fitBounds=true) {
        await this.fetchCourses();
        await this.loadTrackingDrivers();
        await this.buildMap(fitBounds);
    }

    /**
     *
     */
    buildMap(fitBounds) {
        for (var i = this.markers.length - 1; i >= 0; i--) {
            this.markers[i].remove();
        }

        if (this.state.deliveryArea.length === 0) {
            return;
        }

        if (this.state.pickupArea.length === 0) {
            return;
        }

        let map = this.map;
        let markers = this.markers;
        let bounds = null;

        let geojsonDelivery = this.state.deliveryArea;
        let geojsonPickup = this.state.pickupArea;
        let geojsonTracking = this.state.trackingDrivers;

        if (geojsonDelivery.features.length > 0 || geojsonPickup.features.length > 0) {

            if (fitBounds) {
                bounds = new mapboxgl.LngLatBounds();
            }

            if (geojsonDelivery.features.length !== 0) {
                geojsonDelivery.features.forEach((markerDelivery) => {
                    // create a HTML element for each feature
                    var el = document.createElement('div');
                    el.className = 'marker-delivery';

                    if (markerDelivery.geometry !== undefined) {
                        if (fitBounds) {
                            bounds.extend(markerDelivery.geometry.coordinates);
                        }

                        // make a marker for each feature and add to the map
                        let marker = new mapboxgl.Marker(el, {
                                offset: [5, -10]
                            })
                            .setLngLat(markerDelivery.geometry.coordinates)
                            .addTo(map);

                        markers.push(marker);

                        marker.getElement('el').addEventListener('click', () => {
                            this.setState({ visibleDeliveryAreaDrawer: true });

                            let courseId = markerDelivery.properties.course_id;
                            getOneCourse(courseId)
                                .then((res) => res.json())
                                .then((json) => {
                                    this.setState({ course: json.course });
                                });
                        });
                    }
                });
            }

            if (geojsonPickup.features.length !== 0) {
                geojsonPickup.features.forEach((markerPickup) => {
                    // create a HTML element for each feature
                    var el = document.createElement('div');
                    el.className = 'marker-pickup';
                    if (markerPickup.geometry !== undefined) {

                        if (fitBounds) {
                            bounds.extend(markerPickup.geometry.coordinates);
                        }

                        // make a marker for each feature and add to the map
                        let marker = new mapboxgl.Marker(el, {
                                offset: [5, -10]
                            })
                            .setLngLat(markerPickup.geometry.coordinates)
                            .addTo(map);

                        markers.push(marker);

                        marker.getElement('el').addEventListener('click', () => {
                            this.setState({ visiblePickupAreaDrawer: true });

                            let courseId = markerPickup.properties.course_id;
                            getOneCourse(courseId)
                                .then((res) => res.json())
                                .then((json) => {
                                    this.setState({ course: json.course });
                                });
                        });
                    }
                });
            }

            if (geojsonTracking.length !== 0 && geojsonTracking.features !== undefined) {
                geojsonTracking.features.forEach((markerDriver) => {

                    let lastTrackingDateTime = moment(markerDriver.properties.lastTracking).format('DD/MM/YYYY HH:mm:ss');
                    // create a HTML element for each feature
                    var el = document.createElement('div');
                    el.className = 'marker-driver';

                    if (markerDriver.geometry !== undefined) {
                        if (fitBounds) {
                            bounds.extend(markerDriver.geometry.coordinates)
                        }

                        // make a marker for each feature and add to the map
                        let marker = new mapboxgl.Marker(el, {
                                offset: [5, -10]
                            })
                            .setLngLat(markerDriver.geometry.coordinates)
                            .addTo(map);

                        markers.push(marker);

                        marker.getElement('el').addEventListener('click', () => {
                            this.setState({
                                visibleDriverDrawer: true,
                                driver: {
                                    id: markerDriver.properties.driver_id,
                                    identity: markerDriver.properties.title,
                                    phone: markerDriver.properties.phone,
                                    lastTrackingDateTime: lastTrackingDateTime
                                }
                            });
                        });
                    }
                });
            }
        }

        if (bounds !== null && fitBounds) {
            map.fitBounds(bounds, { padding: 100, animate: false });
        }

        map.scrollZoom.disable();
        map.scrollZoom.setWheelZoomRate(0.02); // Default 1/450

        map.on("wheel", event => {
            if (event.originalEvent.ctrlKey) { // Check if CTRL key is pressed
                event.originalEvent.preventDefault(); // Prevent chrome/firefox default behavior
                if (!map.scrollZoom._enabled) map.scrollZoom.enable(); // Enable zoom only if it's disabled
            } else {
                if (map.scrollZoom._enabled) map.scrollZoom.disable(); // Disable zoom only if it's enabled
            }
        });

        // Clean up on unmount
        return () => map.remove();
    }

    /**
     * 
     */
    onCloseDeliveryAreaDrawer() {
        this.setState({
            visibleDeliveryAreaDrawer: false,
            course: null
        });
    }

    /**
     * 
     */
    onClosePickupAreaDrawer() {
        this.setState({
            visiblePickupAreaDrawer: false,
            course: null
        });
    }

    /**
     * 
     */
    onCloseDriverDrawer() {
        this.setState({
            visibleDriverDrawer: false,
            course: null
        });
    }

    /**
     *
     * @param {*} values
     */
    setFilters(values) {
        this.setState({ filters: values }, () => {
            this.loadData();
        });
    }

    /*
     *
     */
    refresh() {
        this.fetchCourses().then(() => {
            const selectedCourseId = this.state.course.id;
            getOneCourse(selectedCourseId)
                .then((res) => res.json())
                .then((json) => {
                    this.setState({ course: json.course });
                });
        });
    }

    render() {
        const { course, driver } = this.state;

        return (
            <Content>
                <div style={{ margin: '16px 16px' }}>
                    <div className="site-layout-background" style={{ padding: '24px', minHeight: '360px' }}>
                        <PageHeader
                            className="site-page-header"
                            avatar={{ src: <Avatar icon={<ShoppingCartOutlined />} /> }}
                            title="VUE D'ENSEMBLE DE L'ACTIVITE DE LA JOURNEE"
                            subTitle="Mise à jour automatique toutes les 15s"
                        />
                        <Divider />
                        <Filter filters={defaultFilters} updateFilters={this.setFilters} />
                        <Divider />
                        <div className="map-page-container">
                            <div ref={this.mapContainer} className="map-container" />
                        </div>
                    </div>
                </div>
                <DrawerCourseDeliveryArea
                    course={course}
                    visible={this.state.visibleDeliveryAreaDrawer}
                    fromView={'map'}
                    onClose={this.onCloseDeliveryAreaDrawer}
                    refresh={this.refresh}
                />
                <DrawerCoursePickupArea
                    course={course}
                    visible={this.state.visiblePickupAreaDrawer}
                    fromView={'map'}
                    onClose={this.onClosePickupAreaDrawer}
                    refresh={this.refresh}
                />
                <DrawerCoursePositionDriver
                    driver={driver}
                    visible={this.state.visibleDriverDrawer}
                    fromView={'map'}
                    onClose={this.onCloseDriverDrawer}
                />
            </Content>
        );
    }
};

export default MapDispatch;

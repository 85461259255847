import React from "react";

import {Button, DatePicker, Space} from 'antd';
import moment from 'moment';

const { RangePicker } = DatePicker;

/**
 *
 * @param {*} props
 * @returns
 */
const FormInvoiceTab = (props) => {

    let { merchant } = props;

    let [dateStart, setDateStart] = React.useState(moment());
    let [dateEnd, setDateEnd] = React.useState(moment());

    /**
     *
     */
    const onClickInvoice = () => {
        let data = {
            dateStart: dateStart,
            dateEnd: dateEnd
        }
        props.getInvoice(data);
    }

    /**
    *
    * @param {*} values
    */
    const onChangeDate = (values) => {
        setDateStart(values[0].format('YYYY-MM-DD'));
        setDateEnd(values[1].format('YYYY-MM-DD'));
    }

    const dateFormat = 'DD/MM/YYYY';
    return (
        <div style={{marginTop: '20px'}}>
            <Space>
                <div>Date de livraison :  </div>
                <RangePicker defaultValue={[moment(), moment()]} format={dateFormat} onChange={onChangeDate} />
                <Button type="primary" onClick={onClickInvoice}>Générer</Button>
            </Space>
        </div>
    )
}

export default FormInvoiceTab;

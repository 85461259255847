
export const translateStatus = {
    canceled: 'Annulée',
    available: 'Emise',
    assigned: 'Assignée',
    started: 'Démarée',
    inProgress: 'Colis récupéré',
    isDelivered: 'Colis livré',
    returned: 'Retournée',
    draft: 'Brouillon',
    failed: 'Echouée',
    pickupFailed: 'Impossible de récupérer le colis'
};

export const translateTransport = {
    walk: 'A pied',
    bike: 'Vélo',
    cargoBike: 'Vélo cargo',
    motorbike: 'Scooter',
    car: 'Voiture',
    commercialVehicle: 'Véhicule commercial'
};

export const colorsCourse = {
    draft: 'default',
    available: 'blue',
    assigned: 'purple',
    started: 'orange',
    inProgress: 'orange',
    isDelivered: 'green',
    returned: 'red',
    canceled: 'red',
    failed: 'red',
    pickupFailed: 'red'
};
import React, {useEffect} from 'react';

import {Button, Checkbox, Divider, notification, Popconfirm, Space, Table} from 'antd';
import {DeleteOutlined, EditOutlined} from '@ant-design/icons';

import DeliveryOptionsDrawer from '../../Components/DeliveryOptions/DeliveryOptionsDrawer.js';

import {
    deleteDeliveryOption,
    deliveryOptionVisibility,
    getDeliveryOptions,
    saveDeliveryOption
} from '../../Api/DeliveryOptions.js';

/**
 * 
 * @param {*} props 
 * @returns 
 */
const FormDeliveryOptions = (props) => {
    let { network } = props;

    let [loading, setLoading] = React.useState(false);
    let [visibleDeliveryOptionsDrawer, setVisibleDeliveryOptionsDrawer] = React.useState(false);
    let [options, setOptions] = React.useState([]);
    let [id, setId] = React.useState(null);
    let [actionOption, setActionOption] = React.useState('save');

    const columns = [
        {
            title: 'Nom',
            dataIndex: '',
            key: 'id',
            render: (row) => {
                if (row.option_name === null && row.parent !== null) {
                    return (
                        <div>
                            {row.parent.option_name}
                        </div>
                    )
                } else {
                    return (
                        <div>
                            {row.option_name}
                        </div>
                    )
                }
            }
        },
        {
            title: 'Prix',
            dataIndex: 'option_price',
            key: 'option_price',
        },
        {
            title: 'Visible',
            dataIndex: '',
            key: '',
            render: (row) => {
                let checked = true;
                if (row.visible === 0) {
                    checked = false;
                }
                return (
                    <div>
                        <Checkbox checked={checked} onChange={(e) => onChangeVisible(row, e)}></Checkbox>
                    </div>
                )
            }
        },
        {
            title: '',
            dataIndex: '',
            key: 'id',
            width: 250,
            render: (row) => {
                let deleteButton = null;
                if (row.is_admin === 0 && row.parent_id === null) {
                    deleteButton = (
                        <Popconfirm
                            title="Etes-vous sûr de vouloir supprimer cette option ?"
                            onConfirm={(e) => onClickDeleteOption(row.id, e)}
                            okText="Oui"
                            cancelText="Non"
                        >
                            <Button type='primary' danger className='button-table'>
                                <DeleteOutlined />
                            </Button>
                        </Popconfirm>
                    )
                }
                return (<div>
                    <Space wrap>
                        <Button type='primary' onClick={(e) => onClickEditOption(row.id, e)}>
                            <EditOutlined />
                        </Button>
                        {deleteButton}
                    </Space>
                </div>
                )
            }
        },
    ];

    /**
     * 
     */
    const showDeliveryOptionsDrawer = () => {
        setVisibleDeliveryOptionsDrawer(true);
    }

    /**
     *
     * @param {*} value
     */
    const showDrawer = (value) => {
        fetchDeliveryOptions();
        setVisibleDeliveryOptionsDrawer(value);
        setActionOption('save');
    }

    /**
     * 
     * @param {*} id 
     * @param {*} e 
     */
    const onClickDeleteOption = (id, e) => {
        e.preventDefault();
        deleteDeliveryOption(id)
            .then(res => res.json())
            .then(() => {
                notification['success']({
                    message: 'L\'option a bien été supprimée'
                });
                fetchDeliveryOptions();
            })
    }

    /**
     * 
     * @param {*} id 
     */
    const onClickEditOption = (id) => {
        setId(id);
        setActionOption('update');
        setVisibleDeliveryOptionsDrawer(true);
    }

    /**
     * 
     */
    const fetchDeliveryOptions = async () => {
        let data = {
            network_id: network.id
        }
        getDeliveryOptions(data)
            .then(res => res.json())
            .then(json => {
                setOptions(json);
            })

    }

    /**
     * 
     * @param {*} id 
     * @param {*} e 
     */
    const onChangeVisible = (row, e) => {
        let data = {
            network_id: network.id,
            visible: e.target.checked
        }
        if (row.origin_name !== 'network') {
            data.parent_id = row.id;
            data.option_name = null;
            data.id = null;
            data.option_price = row.option_price;
            data.is_admin = false;
            saveDeliveryOption(data)
                .then(res => res.json())
                .then(() => {
                    fetchDeliveryOptions();
                })
        } else {
            deliveryOptionVisibility(row.id, data)
                .then(res => res.json())
                .then(() => {
                    fetchDeliveryOptions()
                })
        }
    }

    /**
     * 
     */
    useEffect(() => {
        fetchDeliveryOptions();
    }, []);

    return (
        <div>
            <Button type='primary' onClick={showDeliveryOptionsDrawer}>Ajouter une option</Button>
            <Divider></Divider>
            <Table
                columns={columns}
                loading={loading}
                dataSource={options}
            />
            <DeliveryOptionsDrawer network={network} id={id} showDeliveryOptionsDrawer={visibleDeliveryOptionsDrawer} showDrawer={showDrawer} action={actionOption} />
        </div>
    )
}

export default FormDeliveryOptions;

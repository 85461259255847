import React from 'react';
import {Redirect} from 'react-router-dom';

import {Divider, Layout, notification, PageHeader} from 'antd';
import {InfoCircleOutlined} from '@ant-design/icons';
import {postMerchantTheme} from '../../Api/MerchantThemes.js';
import FormMerchantTheme from './FormMerchantTheme.js';
import {GlobalContext} from '../../GlobalContext.js';

class MerchantThemeAdd extends React.Component {
    static contextType = GlobalContext;
    /**
     * 
     * @param {*} props 
     */
    constructor(props) {
        super(props);

        this.state = {
            value: 1,
            redirection: false,
            id: null,
            merchanttheme: null,
            user: null
        }

        this.onSubmit = this.onSubmit.bind(this);
    }

    /**
     * 
     * @param {*} values 
     */
    onSubmit(values) {
        let newMerchantTheme = { ...values };
        newMerchantTheme.price_grid = 1;
        postMerchantTheme(newMerchantTheme)
            .then(function (response) {
                return response.json();
            }).then((data) => {
                notification['success']({
                    message: 'Le thème commerçant a bien été enregistré'
                })
                this.setState({
                    id: data.merchantTheme.id,
                    redirection: true
                });
            });
    };

    render() {
        const { Content } = Layout;
        const { redirection } = this.state;
        const { id } = this.state;

        if (redirection) {
            return (<Redirect to={'/merchanttheme/' + id} />)
        }

        return (
            <div>
                <PageHeader title="Thèmes commerçants" subTitle="Création d'un thème commerçant" />
                <Content style={{ margin: '0 16px 16px 16px' }}>
                    <div className="site-layout-background" style={{ padding: 24, minHeight: 360 }}>
                        <h2 style={{ color: "#2FAC66" }}><InfoCircleOutlined /> INFORMATIONS</h2>
                        <Divider></Divider>
                        <FormMerchantTheme merchanttheme={this.state.merchanttheme} user={this.context.global.profile} formSubmit={this.onSubmit} />
                    </div>
                </Content>
            </div>

        )
    }
}

export default MerchantThemeAdd;

import React, {useEffect} from 'react';

import {Button, Divider, notification, Popconfirm, Space, Table} from 'antd';
import {DeleteOutlined, EyeOutlined} from '@ant-design/icons';

import StoreDrawer from '../../Components/Stores/StoreDrawer.js';

import {deleteNetworkStore, getNetworkStores} from '../../Api/Networks.js';

/**
 * 
 * @param {*} props 
 * @returns 
 */
const FormStoresTab = (props) => {
    let { network } = props;

    let [loading, setLoading] = React.useState(false);
    let [visibleStoreDrawer, setVisibleStoreDrawer] = React.useState(false);
    let [stores, setStores] = React.useState([]);
    let [id, setId] = React.useState(null);
    let [actionStore, setActionStore] = React.useState('save');

    const columns = [
        {
            title: '#',
            dataIndex: 'id',
            key: 'id',
            width: 50
        },
        {
            title: 'Nom',
            dataIndex: 'store_name',
            key: 'store_name',
        },
        {
            title: 'Adresse',
            dataIndex: 'address',
            key: 'address',
        },
        {
            title: '',
            dataIndex: 'id',
            key: 'id',
            width: 250,
            render: (id) => (
                <div>
                    <Space wrap>
                        <Button type='primary' onClick={(e) => onClickEditStore(id, e)}>
                            <EyeOutlined />
                        </Button>
                        <Popconfirm
                            title="Etes-vous sûr de vouloir supprimer cet entrepôt ?"
                            onConfirm={(e) => onClickDeleteStore(id, e)}
                            okText="Oui"
                            cancelText="Non"
                        >
                            <Button type='primary' danger className='button-table'>
                                <DeleteOutlined /> 
                            </Button>
                        </Popconfirm>
                    </Space>
                </div>
            )
        },
    ];


    const showStoreDrawer = () => {
        setVisibleStoreDrawer(true);
    }

    /**
     *
     * @param {*} value
     */
     const showDrawer = (value) => {
        fetchStores();
        setVisibleStoreDrawer(value);
        setActionStore('save');
    }

    const onClickDeleteStore = (id, e) => {
        e.preventDefault();
        const network_id = network.id;
        deleteNetworkStore(network_id, id)
            .then((res) => res.json())
            .then(() => {
                notification['success']({
                    message: 'L\'entrepot a bien été supprimé'
                })
                fetchStores();
            })
    }

    const onClickEditStore = (id) => {
        setId(id);
        setActionStore('update');
        setVisibleStoreDrawer(true);
    }

    const fetchStores = async () => {
        let network_id = network.id;
        await getNetworkStores(network_id)
            .then((res) => res.json())
            .then((data) => {
                setStores(data)
            })
    }

    useEffect(() => {
        fetchStores();
    }, []);

    return (
        <div>
            <h2>Liste des entrepôts</h2>
            <Table
                columns={columns}
                loading={loading}
                dataSource={stores}
            />
            <Divider></Divider>
            <Button type='primary' onClick={showStoreDrawer}>Ajouter un entrepôt</Button>
            <StoreDrawer network={network} id={id} showStoreDrawer={visibleStoreDrawer} showDrawer={showDrawer} action={actionStore}/>
        </div>
    )
}

export default FormStoresTab;

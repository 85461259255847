import config from '../Config.js';

export function getManyMerchantThemes(data) {
    return fetch(config.hostApi + 'merchantthemes', {
        method: 'POST',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + localStorage.getItem('token')
        },
        body: JSON.stringify(data)
    })
}

/**
 * 
 * @param {*} id 
 */
export function getOneMerchantTheme(id) {
    return fetch(config.hostApi + 'merchanttheme/' + id, {
        method: 'GET',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + localStorage.getItem('token')
        },
    })
}

/**
 * 
 * @param {*} data 
 */
export function postMerchantTheme(data) {
    return fetch(config.hostApi + 'merchanttheme', {
        method: 'POST',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + localStorage.getItem('token')
        },
        body: JSON.stringify(data)
    })
}

/**
 * 
 * @param {*} id 
 * @param {*} data 
 */
export function updateMerchantTheme(id, data) {
    return fetch(config.hostApi + 'merchanttheme/' + id, {
        method: 'PUT',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + localStorage.getItem('token')
        },
        body: JSON.stringify(data)
    })
}

/**
 * 
 * @param {*} id 
 */
export function deleteMerchantTheme(id) {
    return fetch(config.hostApi + 'merchanttheme/' + id, {
        method: 'DELETE',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + localStorage.getItem('token')
        },
    })
}

/**
 * 
 * @param {*} id 
 */
 export function duplicateMerchantTheme(id) {
    return fetch(config.hostApi + 'merchanttheme/' + id + '/duplicate', {
        method: 'GET',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + localStorage.getItem('token')
        },
    })
}

/**
 * 
 * @param {*} id 
 * @param {*} data 
 * @returns 
 */
 export function saveMerchantThemeBoundaries(id, data) {
    return fetch(config.hostApi + 'merchanttheme/' + id + '/boundaries', {
        method: 'POST',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + localStorage.getItem('token')
        },
        body: JSON.stringify(data)
    })
}

/**
 * 
 * @param {*} id 
 * @returns 
 */
 export function getMerchantThemeBoundaries(id) {
    return fetch(config.hostApi + 'merchanttheme/' + id + '/getBoundaries', {
        method: 'GET',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + localStorage.getItem('token')
        },
    })
}
/**
 * 
 * @param {*} id 
 */
 export function merchantThemeOpeningSchedule(id, data) {
    return fetch(config.hostApi + 'merchanttheme/' + id + '/saveOpeningSchedule', {
        method: 'POST',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': 'Bearer' + localStorage.getItem('token')
        },
        body: JSON.stringify({
            'opening_schedules' : data
        })
    })
}

/**
 * 
 * @param {*} id 
 */
 export function getMerchantThemeOpeningSchedule(id) {
    return fetch(config.hostApi + 'merchanttheme/' + id + '/getOpeningSchedule', {
        method: 'GET',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': 'Bearer' + localStorage.getItem('token')
        }
    })
}

import React, {useEffect, useRef, useState} from 'react';

import {Button, Divider, notification, Popconfirm, Space, Table} from 'antd';
import {CloseOutlined, FormOutlined} from '@ant-design/icons';

import mapboxgl from 'mapbox-gl';
import '@mapbox/mapbox-gl-draw/dist/mapbox-gl-draw.css';

import config from '../../Config.js';
import {saveMerchantBoundaries} from '../../Api/Merchants.js';

import DrawerInfoBoundaries from '../../Components/InfoBoundaries/DrawerInfoBoundaries.js';

mapboxgl.accessToken = config.mapboxToken;

/**
 * 
 * @param {*} props 
 * @returns 
 */
const FormBoundariesTab = (props) => {

    const { merchant, boundaries } = props;

    const mapContainer = useRef();
    const [lng, setLng] = useState(1.4437);
    const [lat, setLat] = useState(43.6043);
    const [zoom, setZoom] = useState(12);
    let [visibleInfoDrawer, setVisibleInfoDrawer] = React.useState(false);
    let [dataBoundaries, setDataBoundaries] = React.useState([]);
    let [infoBoundaries, setInfoBoundaries] = React.useState([]);
    let [selectedId, setSelectedId] = React.useState(null);

    const columns = [
        {
            title: 'Nom',
            dataIndex: 'zone_name',
            key: 'zone_name',
        },
        {
            title: 'Type de zone',
            dataIndex: 'zone_type',
            key: 'zone_type',
        },
        {
            title: '',
            dataIndex: 'id',
            key: 'id',
            width: 270,
            render: (id) => (
                <Space size="middle" wrap>
                    <Button type='primary' className='button-table' onClick={() => showEditDrawer(id)}>
                        <FormOutlined /> Editer
                    </Button>
                    <Popconfirm
                        title="Etes-vous sûr de vouloir supprimer cette zone ?"
                        onConfirm={(e) => confirmDeleteZone(id, e)}
                        okText="Oui"
                        cancelText="Non"
                    >
                        <Button type='primary' danger className='button-table'>
                            <CloseOutlined /> Supprimer
                        </Button>
                    </Popconfirm>
                </Space>
            )
        },
    ];

    /**
     * 
     */
    const onClickNewBoundarie = () => {
        setVisibleInfoDrawer(true);
        setSelectedId(null);
    }

    /**
     * 
     * @param {*} id 
     */
    const showEditDrawer = (id) => {
        setVisibleInfoDrawer(true);
        setSelectedId(id);
    }

    /**
     * 
     * @param {*} id 
     */
    const confirmDeleteZone = (id) => {
        let merchantId = merchant.id;
        let deleteId = id;
        let boundariesToSave = [];
        dataBoundaries.forEach(databoundarie => {
            if (databoundarie.id !== deleteId) {
                if (databoundarie.zone_type === 'zone de livraison') {
                    databoundarie.zone_type = 'deliveryArea'
                }
                if (databoundarie.zone_type === 'zone de retrait') {
                    databoundarie.zone_type = 'pickupArea'
                }
                boundariesToSave.push(databoundarie)
            }
        })
        let newBoundaries = {
            boundaries: boundariesToSave
        }
        saveMerchantBoundaries(merchantId, newBoundaries)
            .then((res) => res.json())
            .then(() => {
                notification['success']({
                    message: 'La zone d\'activité a bien été mise à jour'
                })
                props.reloadBoundaries();
            })

    }

    /**
     * 
     * @param {*} value 
     */
    const showDrawer = (value) => {
        setVisibleInfoDrawer(value);
        setSelectedId(null);
        props.reloadBoundaries();
    }

    /**
     * 
     */
    useEffect(() => {
        setDataBoundaries(boundaries)
        const map = new mapboxgl.Map({
            container: mapContainer.current,
            style: 'mapbox://styles/mapbox/streets-v11',
            center: [lng, lat],
            zoom: zoom
        });

        if (merchant !== null) {
            setLng(merchant.longitude);
            setLat(merchant.latitude);
            map.setCenter([
                merchant.longitude, merchant.latitude
            ]);

            var el = document.createElement('div');
            el.className = 'marker-pickup';

            new mapboxgl.Marker(el)
                .setLngLat([merchant.longitude, merchant.latitude])
                .addTo(map);
        }

        if (boundaries.length !== 0) {
            //affiche les boundaries dans le tableau
            setInfoBoundaries(boundaries);

            let geoJsonDelivery = {};
            let featureDelivery = [];
            let featureDetailDelivery = {};
            let geoJsonPickup = {};
            let featurePickup = [];
            let featureDetailPickup = {};

            boundaries.map((boundarie, i) => {
                if (boundarie.zone_type === 'zone de livraison') {
                    featureDetailDelivery = {
                        "type": 'Feature',
                        "geometry": {
                            "type": 'Polygon',
                            "coordinates": boundarie.coordinates,
                        },
                        "properties": {
                            "id": boundarie.id,
                            'zone_type': boundarie.zone_type,
                            'zone_name': boundarie.zone_name
                        },

                    };
                    featureDelivery.push(featureDetailDelivery);
                }

                if (boundarie.zone_type === 'zone de retrait') {
                    featureDetailPickup = {
                        "type": 'Feature',
                        "geometry": {
                            "type": 'Polygon',
                            "coordinates": boundarie.coordinates,
                        },
                        "properties": {
                            "id": boundarie.id,
                            'zone_type': boundarie.zone_type,
                            'zone_name': boundarie.zone_name
                        },

                    };
                    featurePickup.push(featureDetailPickup);
                }

            })

            geoJsonDelivery = {
                type: 'FeatureCollection',
                features: featureDelivery
            };

            geoJsonPickup = {
                type: 'FeatureCollection',
                features: featurePickup
            };

            map.on('load', function () {

                map.addSource('delivery', {
                    "type": "geojson",
                    "data": geoJsonDelivery
                });
                map.addLayer({
                    id: 'delivery-layer',
                    type: 'fill',
                    source: 'delivery',
                    paint: {
                        'fill-color': 'rgba(47, 172, 102, 0.4)',
                        'fill-outline-color': 'rgba(48, 171, 102, 1)'
                    }
                });
                map.addLayer({
                    id: 'delivery-layer-text',
                    type: 'symbol',
                    source: 'delivery',
                    layout: {
                        'text-field': [
                            'format',
                            ['upcase', ['get', 'zone_name']],
                            { 'font-scale': 0.7 },
                            '\n',
                            {},
                            ['downcase', ['get', 'zone_type']],
                            { 'font-scale': 0.5 }
                        ],
                        'text-font': ['Open Sans Semibold', 'Arial Unicode MS Bold']
                    }
                });
                map.addSource('pickup', {
                    "type": "geojson",
                    "data": geoJsonPickup
                });
                map.addLayer({
                    id: 'pickup-layer',
                    type: 'fill',
                    source: 'pickup',
                    paint: {
                        'fill-color': 'rgba(178, 34, 34, 0.4)',
                        'fill-outline-color': 'rgba(139, 0, 0, 1)'
                    }
                });
                map.addLayer({
                    id: 'pickup-layer-text',
                    type: 'symbol',
                    source: 'pickup',
                    layout: {
                        'text-field': [
                            'format',
                            ['upcase', ['get', 'zone_name']],
                            { 'font-scale': 0.7 },
                            '\n',
                            {},
                            ['downcase', ['get', 'zone_type']],
                            { 'font-scale': 0.5 }
                        ],
                        'text-font': ['Open Sans Semibold', 'Arial Unicode MS Bold'],
                        'text-padding': 1
                    }
                });
            });

        } else {

        }

        map.scrollZoom.disable();
        map.scrollZoom.setWheelZoomRate(0.02); // Default 1/450

        map.on("wheel", event => {
            if (event.originalEvent.ctrlKey) { // Check if CTRL key is pressed
                event.originalEvent.preventDefault(); // Prevent chrome/firefox default behavior
                if (!map.scrollZoom._enabled) map.scrollZoom.enable(); // Enable zoom only if it's disabled
            } else {
                if (map.scrollZoom._enabled) map.scrollZoom.disable(); // Disable zoom only if it's enabled
            }
        });

        //return () => map.remove();
    }, [merchant, boundaries]);

    return (
        <div>
            <div>
                <Space>
                    <Button type="primary" onClick={onClickNewBoundarie}>
                        Ajouter une nouvelle zone
                    </Button>
                </Space>
                <Divider orientation="left">Liste des zones d'activité</Divider>
                <Table
                    columns={columns}
                    dataSource={dataBoundaries}
                    pagination={{ position: ['none', "none"] }}
                />
            </div>
            <Divider orientation="left">Vue des zones d'activité</Divider>
            <div className="map-page-container">
                <div className="map-container" ref={mapContainer} />
            </div>
            <Divider></Divider>
            <DrawerInfoBoundaries merchant={merchant} boundaries={infoBoundaries} selectedId={selectedId} showInfoDrawer={visibleInfoDrawer} showDrawer={showDrawer} />
        </div>
    )
}

export default FormBoundariesTab;

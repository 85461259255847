import React from 'react';
import {Tabs} from 'antd';
import {
    BuildOutlined,
    CarOutlined,
    ClockCircleOutlined,
    DeploymentUnitOutlined,
    EuroCircleOutlined,
    InfoCircleOutlined,
    RadarChartOutlined
} from '@ant-design/icons';

import FormGeneralTab from './FormGeneralTab';
import FormConfigurationTab from './FormConfigurationTab';
import FormOpeningScheduleTab from './FormOpeningScheduleTab';
import FormBoundariesTab from './FormBoundariesTab';
import FormStoresTab from './FormStoresTab';
import FormTransportRulesTab from './FormTransportRulesTab';
import FormPricingTab from './FormPricingTab';

const { TabPane } = Tabs;

/**
 * 
 * @param {*} props 
 * @returns 
 */
const FormNetwork = (props) => {

    const { network, user, openingSchedule, boundaries } = props;
    const disable = (network === null);

    /**
     * 
     * @param {*} values 
     */
    const onFinish = (values) => {
        props.formSubmit(values);
    };

    /**
     * 
     */
    const reloadBoundaries = () => {
        props.reloadBoundaries();
    }

    return (
        <div>
            <Tabs defaultActiveKey="1" type="card">
                <TabPane tab={<span><InfoCircleOutlined />Général</span>} key="1">
                    <FormGeneralTab network={network} user={user} formSubmit={onFinish} />
                </TabPane>
                <TabPane tab={<span><EuroCircleOutlined />Tarification</span>} disabled={disable} key="2">
                    <FormPricingTab network={network} formSubmit={onFinish} />
                </TabPane>
                <TabPane tab={<span><BuildOutlined />Configuration</span>} disabled={disable} key="4">
                    <FormConfigurationTab network={network} formSubmit={onFinish} />
                </TabPane>
                <TabPane tab={<span><ClockCircleOutlined />Horaires d'ouverture</span>} disabled={disable} key="5">
                    <FormOpeningScheduleTab network={network} openingSchedule={openingSchedule} formSubmit={onFinish} />
                </TabPane>
                <TabPane tab={<span><RadarChartOutlined />Zones d'activité</span>} disabled={disable} key="6">
                    <FormBoundariesTab network={network} boundaries={boundaries} reloadBoundaries={reloadBoundaries} />
                </TabPane>
                <TabPane tab={<span><DeploymentUnitOutlined />Entrepôts</span>} disabled={disable} key="7">
                    <FormStoresTab network={network} />
                </TabPane>
                <TabPane tab={<span><CarOutlined />Transport</span>} disabled={disable} key="9">
                    <FormTransportRulesTab network={network} />
                </TabPane>
            </Tabs>
        </div>
    );
}

export default FormNetwork;

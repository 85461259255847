import React from 'react';
import {Redirect} from 'react-router-dom';
import moment from 'moment';

import {Button, Divider, Layout, notification, PageHeader, Tabs} from 'antd';
import {GlobalOutlined, InfoCircleOutlined} from '@ant-design/icons';
import {postDriver} from '../../Api/Drivers.js';
import FormDriver from './FormDriver.js';
import {GlobalContext} from '../../GlobalContext.js';

class DriverAdd extends React.Component {
    static contextType = GlobalContext;
    /**
     * 
     * @param {*} props 
     */
    constructor(props) {
        super(props);

        this.state = {
            value: 1,
            redirection: false,
            id: null,
            driver: null,
            user: null,
            courses: []
        }

        this.onSubmit = this.onSubmit.bind(this);
    }

    /**
     * 
     * @param {*} values 
     */
    onSubmit(values) {
        values.subscription_date = moment().format('YYYY-MM-DD');
        postDriver(values)
            .then(function (response) {
                return response.json();
            }).then((data) => {
                notification['success']({
                    message: 'Le transporteur a bien été enregistré'
                })
                this.setState({
                    id: data.driver.id,
                    redirection: true
                });
            });
    };

    render() {
        const { TabPane } = Tabs;
        const { Content } = Layout;
        const { redirection } = this.state;
        const { id } = this.state;

        if (redirection) {
            return (<Redirect to={'/driver/' + id} />)
        }

        return (
            <div>
                <PageHeader title="Transporteurs" subTitle="Création d'un transporteur" />
                <Content>
                    <div style={{ margin: '0 16px 16px 16px' }}>
                        <div className="site-layout-background" style={{ padding: 24, minHeight: 360 }}>
                            <h2 style={{ color: "#2FAC66" }}><InfoCircleOutlined /> INFORMATIONS</h2>
                            <Divider></Divider>
                            <FormDriver courses={this.state.courses} driver={this.state.driver} user={this.context.global.profile} formSubmit={this.onSubmit} />
                        </div>
                    </div>
                    <div style={{ margin: '16px 16px' }}>
                        <div className="site-layout-background" style={{ padding: 24, minHeight: 360 }}>
                            <h2 style={{ color: "#2FAC66" }}><GlobalOutlined /> STATUT</h2>
                            <Divider></Divider>
                            <Tabs defaultActiveKey="1" type="card">
                                <TabPane tab={<span><InfoCircleOutlined />Actuel</span>} key="1">
                                    <div>Statut actuel</div>
                                    <Button type="primary" style={{ backgroundcolor: '#2FAC66', marginBottom: '10px', marginTop: '10px' }} disabled>
                                        Brouillon
                                    </Button>
                                </TabPane>
                            </Tabs>
                        </div>
                    </div>
                </Content>
            </div>
        )
    }
}

export default DriverAdd;

import config from '../Config.js';

/**
 * 
 * @param {*} data 
 */
export function getManyTags(data) {
    return fetch(config.hostApi + 'merchanttags', {
        method: 'POST',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + localStorage.getItem('token')
        },
        body: JSON.stringify(data)
    })
}

/**
 * 
 * @param {*} id 
 */
export function getOneTag(id) {
    return fetch(config.hostApi + 'merchanttag/' + id, {
        method: 'GET',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + localStorage.getItem('token')
        },
    })
}

/**
 * 
 * @param {*} id 
 * @param {*} data 
 */
export function updateTag(id, data) {
    return fetch(config.hostApi + 'merchanttag/' + id, {
        method: 'PUT',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + localStorage.getItem('token')
        },
        body: JSON.stringify(data)
    })
}

/**
 * 
 * @param {*} data 
 */
export function postTag(data) {
    return fetch(config.hostApi + 'merchanttag', {
        method: 'POST',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + localStorage.getItem('token')
        },
        body: JSON.stringify(data)
    })
}

/**
 * 
 * @param {*} id 
 */
export function deleteTag(id) {
    return fetch(config.hostApi + 'merchanttag/' + id, {
        method: 'DELETE',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + localStorage.getItem('token')
        },
    })
}
import React from 'react';

import {Tabs} from 'antd';
import {
    EuroCircleOutlined,
    HistoryOutlined,
    HourglassOutlined,
    MoneyCollectOutlined,
    ToolOutlined
} from '@ant-design/icons';

import FormPriceGridTab from './FormPriceGridTab.js';
import FormCommissionTab from './FormCommissionTab.js';
import FormDeliveryOptions from './FormDeliveryOptions.js';
import DeliveryRulesTab from '../../Components/DeliveryRules/DeliveryRulesTab.js';

const { TabPane } = Tabs;

/**
 * 
 * @param {*} props 
 * @returns 
 */
const FormPricingTab = (props) => {

    let { merchant } = props;

    /**
     * 
     * @param {*} values 
     */
    const onFinish = (values) => {
        props.formSubmit(values);
    };

    return (
        <div style={{ marginTop: 20 }}>
            <Tabs defaultActiveKey="1" tabPosition="left">
                <TabPane tab={<span><MoneyCollectOutlined />Commission</span>} key="1">
                    <FormCommissionTab merchant={merchant} formSubmit={onFinish} />
                </TabPane>
                <TabPane tab={<span><EuroCircleOutlined />Grille </span>} key="2">
                    <FormPriceGridTab merchant={merchant} formSubmit={onFinish} />
                </TabPane>
                <TabPane tab={<span><ToolOutlined />Options </span>} key="3">
                    <FormDeliveryOptions merchant={merchant} />
                </TabPane>
                <TabPane tab={<span><HistoryOutlined />Créneaux</span>} key="4">
                    <DeliveryRulesTab merchant={merchant} ruleType="slots" />
                </TabPane>
                <TabPane tab={<span><HourglassOutlined />Délais</span>} key="5">
                    <DeliveryRulesTab merchant={merchant} ruleType="time_limit" />
                </TabPane>
            </Tabs>
        </div>
    )
}

export default FormPricingTab;

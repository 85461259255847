import React, { useEffect } from 'react';

import { Modal, Space, Select, Checkbox, Button } from 'antd';

import { coursesDriverUnlink, coursesDriverLink} from '../../Api/Courses.js';
import { getManyDrivers } from '../../Api/Drivers.js';

const { Option } = Select;

/**
 * 
 * @param {*} props 
 * @returns 
 */
const ModalDriver = (props) => {

    const { showModalDriverVisible, selectedRowKeys } = props;

    const [dataDriver, setDataDriver] = React.useState([]);
    const [driverId, setDriverId] = React.useState(null);
    const [visible, setVisible] = React.useState(false);

    const optionDriver = dataDriver.map(d => <Option key={d.id} value={d.id}>{d.firstName} {d.lastName}</Option>);

    useEffect(() => {
        if (showModalDriverVisible) {
            setVisible(true);
        }
    }, [showModalDriverVisible]);

    /**
     *
     * @param {*} value
     */
    const handleSearchDriver = (value) => {
        if (value.length < 2) {
            return;
        }

        let data = {
            term: value,
            status: ['valid']
        };

        getManyDrivers(data)
            .then((res) => res.json())
            .then((json) => {
                setDataDriver(json.results);
            });
    };

    /**
     *
     * @param {*} value
     */
    const handleChangeDriver = (value) => {
        value = (value !== undefined) ? value : null;
        setDriverId(value);
    };

    /**
     *
     */
    const assignDriver = () => {
        let coursesIds = selectedRowKeys;

        let payload = {
            coursesIds: coursesIds,
            driverId: driverId
        };

        coursesDriverLink(payload)
            .then((res) => res.json())
            .then(() => {
                setDriverId(null);
                setVisible(false);
                props.showModalDriver(false);

                if (props.refresh) {
                    props.refresh();
                }
            });
    };

    /**
     *
     */
    const unassignDriver = () => {
        let coursesIds = selectedRowKeys;

        let payload = {
            coursesIds: coursesIds,
            driverId: null
        };

        coursesDriverUnlink(payload)
            .then((res) => res.json())
            .then(() => {
                setDriverId(null);
                setVisible(false);
                props.showModalDriver(false);

                if (props.refresh) {
                    props.refresh();
                }
            });
    };

    /**
     *
     */
    const handleCancel = () => {
        setDriverId(null);
        setVisible(false);
        props.showModalDriver(false);
    };

    
    return (
        <div style={{ marginBottom: '16px' }}>
            <Modal
                title="Assigner un transporteur"
                visible={visible}
                onCancel={handleCancel}
                footer={[
                    <Button key="back" onClick={handleCancel}>
                        Annuler
                    </Button>,
                    <Button key="unassign" type="primary" onClick={unassignDriver} danger>
                        Désassigner
                    </Button>,
                    <Button key="submit" type="primary" onClick={assignDriver} disabled={(!driverId)}>
                        Assigner
                    </Button>,
                ]}
            >
                <Space direction="vertical">
                    <Select
                        style={{ width: '300px' }}
                        showSearch
                        placeholder="Choisir un transporteur"
                        filterOption={false}
                        value={driverId}
                        onSearch={handleSearchDriver}
                        onChange={setDriverId}
                        notFoundContent={null}
                    >
                        {optionDriver}
                    </Select>
                </Space>
            </Modal>
        </div>
    )
}

export default ModalDriver;
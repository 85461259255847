import React from "react";
import moment from "moment";

import {Divider, Layout, notification, PageHeader,} from "antd";
import {InfoCircleOutlined,} from "@ant-design/icons";

import mapboxgl from "mapbox-gl";
import "@mapbox/mapbox-gl-draw/dist/mapbox-gl-draw.css";
import config from "../../Config.js";
import {
    driverEject,
    driverTracking,
    driverValidate,
    findFilterDayCourses,
    getOneDriver,
    updateDriver
} from "../../Api/Drivers.js";
import FormDriver from "./FormDriver.js";
import {GlobalContext} from "../../GlobalContext.js";

mapboxgl.accessToken = config.mapboxToken;

const dateFormat = 'YYYY-MM-DD';

/**
 * 
 * @param {*} lastPoint 
 * @param {*} firstName 
 * @param {*} lastName 
 * @param {*} driver 
 * @returns 
 */
function createGeoJsonLastPoint(lastPoint, firstName, lastName, driver) {
  let geoJsonLastPoint = {};
  let featureLastPoint = [];
  let featureDetailLastPoint = {};

  featureDetailLastPoint = {
    type: "Feature",
    geometry: {
      type: "Point",
      coordinates: [lastPoint.longitude, lastPoint.latitude],
    },
    properties: {
      title: firstName + " " + lastName,
      lastTracking: moment(lastPoint.received_at).format(
        "DD/MM/YYYY HH:mm:ss"
      ),
      driver_id: driver.id,
    },
  };
  featureLastPoint.push(featureDetailLastPoint);

  geoJsonLastPoint = {
    type: "FeatureCollection",
    features: featureLastPoint,
  };

  return geoJsonLastPoint;
}

/**
 * 
 * @param {*} data 
 * @returns 
 */
function createFeatureDetail(data) {
  let delivery = {
    lat: null,
    lng: null
  };
  let pickup = {
    lat: null,
    lng: null
  };

  if (data.delivery_area !== null) {
    delivery.lat = data.delivery_area.lat;
    delivery.lng = data.delivery_area.lng;
  }

  if (data.pickup_area !== null) {
    pickup.lat = data.pickup_area.lat;
    pickup.lng = data.pickup_area.lng;
  }

  let featureDetailDelivery = {
    type: "Feature",
    geometry: {
      type: "Point",
      coordinates: [delivery.lng, delivery.lat],
    },
    properties: {
        course_id: data.id,
    },
  };

  let featureDetailPickup = {
    type: "Feature",
    geometry: {
      type: "Point",
      coordinates: [pickup.lng, pickup.lat],
    },
    properties: {
      course_id: data.id,
    },
  };

  return [featureDetailDelivery, featureDetailPickup];
}

export class DriverEdit extends React.Component {
  static contextType = GlobalContext;
  /**
   *
   * @param {*} props
   */
  constructor(props) {
    super(props);

    this.state = {
      value: 1,
      deliveryDates: [moment(), moment()],
      deliveryArea: [],
      pickupArea: [],
      lng: 1.4437,
      lat: 43.6043,
      zoom: 12.5,
      driver: null,
      user: null,
      lastPoint: [],
    };

    this.mapContainer = React.createRef();
    this.onSubmit = this.onSubmit.bind(this);
    this.onClickEject = this.onClickEject.bind(this);
    this.onClickValidate = this.onClickValidate.bind(this);
  }

  /**
   *
   */
  async loadOneDriver() {
    const id = this.props.match.params.id;
    await getOneDriver(id)
      .then((response) => {
        return response.json();
      })
      .then((data) => {
        this.setState({ driver: data.driver });
      });
  }

  /**
   *
   */
  async loadDriverTracking() {
    const id = this.props.match.params.id;
    await driverTracking(id)
      .then((res) => res.json())
      .then((json) => {
        let data = json;
        if (data.length !== 0) {

          let lastPoint = data[data.length - 1];
          let driver = this.state.driver;

          let firstName = driver.firstName ? driver.firstName : "";
          let lastName = driver.lastName ? driver.lastName : "";

          let geoJsonLastPoint = createGeoJsonLastPoint(lastPoint, firstName, lastName, driver);
          this.setState({
            lastPoint: geoJsonLastPoint,
            lat: lastPoint.latitude,
            lng: lastPoint.longitude,
          });
        }
      });
  }

  /**
   *
   */
  async loadCourses() {
    const id = this.props.match.params.id;
    const deliveryDates = this.state.deliveryDates;

    let payload = {
      dateStart: deliveryDates[0].format(dateFormat),
      dateEnd: deliveryDates[1].format(dateFormat),
    };

    await findFilterDayCourses(id, payload)
      .then((res) => res.json())
      .then((json) => {
        let data = json.courses;
        let geoJsonDelivery = {};
        let featureDelivery = [];

        let geoJsonPickup = {};
        let featurePickup = [];

        data.map((d) => {
          let featureDetail = createFeatureDetail(d);

          featureDelivery.push(featureDetail[0]);
          featurePickup.push(featureDetail[1]);
        });

        geoJsonDelivery = {
          type: "FeatureCollection",
          features: featureDelivery,
        };
        geoJsonPickup = {
          type: "FeatureCollection",
          features: featurePickup,
        };
        this.setState({
          deliveryArea: geoJsonDelivery,
          pickupArea: geoJsonPickup,
        });
      });
  }

  /**
   *
   * @param {*} params
   */
  async loadData(params = {}) {
    this.setState({ loading: true });
    await this.loadOneDriver();
    await this.loadDriverTracking();
    await this.loadCourses();
  }

  /**
   *
   */
  async componentDidMount() {
    const { pagination } = this.state;
    await this.loadData({ pagination });
  }

  /**
   *
   * @param {*} e
   */
  onChangeDeliveryDates = (e) => {
    this.setState({ deliveryDates: e }, () => {
        this.loadCourses();
    });
  };

  /**
   *
   * @param {*} values
   */
  onSubmit(values) {
    const driverId = this.props.match.params.id;
    let data = { ...values };
    if (data.password === undefined ) {
      data.password = '';
    }
    if (data.password_confirmation === undefined) {
      data.password_confirmation = '';
    }
    data.id = driverId;
    updateDriver(driverId, data)
      .then((res) => res.json())
      .then((data) => {
        notification["success"]({
          message: "Le transporteur a bien été mis à jour",
        });
        this.loadOneDriver();
      });
  }

  /**
 *
 */
  onClickValidate = () => {
    const driverId = this.props.match.params.id;
    driverValidate(driverId)
      .then((res) => res.json())
      .then((data) => {
        this.setState({ driver: data });
      });
  }

  /**
 *
 */
  onClickEject = () => {
    const driverId = this.props.match.params.id;
    driverEject(driverId)
      .then((res) => res.json())
      .then((data) => {
        this.setState({ driver: data });
      });
  }


  render() {
    const { Content } = Layout;

    let formDriver = (<label>Chargement...</label>);
    if (this.state.driver) {
      formDriver = (
        <FormDriver
          driver={this.state.driver}
          deliveryArea={this.state.deliveryArea}
          pickupArea={this.state.pickupArea}
          lastPoint={this.state.lastPoint}
          user={this.context.global.profile}
          formSubmit={this.onSubmit}
          trackingChange={this.onChangeDeliveryDates}
          ejectDriver={this.onClickEject}
          validateDriver={this.onClickValidate}
        />
      );
    }

    return (
      <div>
        <PageHeader
          title="Transporteurs"
          subTitle="Edition d'un transporteur"
        />
        <Content style={{ margin: "0 16px 16px 16px" }}>
          <div
            className="site-layout-background"
            style={{ padding: 24, minHeight: 360 }}
          >
            <h2 style={{ color: "#2FAC66" }}>
              <InfoCircleOutlined /> INFORMATIONS{" "}
            </h2>
            <Divider></Divider>
            {formDriver}
          </div>
        </Content>
      </div>
    );
  }
}

export default DriverEdit;

import React, {useContext, useEffect, useState} from 'react';
import {Link} from 'react-router-dom';

import {Button, Divider, Input, Layout, PageHeader, Popconfirm, Select, Space, Table} from 'antd';
import {FormOutlined, InboxOutlined, PlusCircleOutlined, UnorderedListOutlined} from '@ant-design/icons';

import {archive as archiveCustomer, deleteCustomer, getManyCustomers} from '../../Api/Customers.js';
import {getManyWhiteLabels} from "../../Api/WhiteLabels.js";
import {GlobalContext} from '../../GlobalContext.js';
import {getManyMerchants} from '../../Api/Merchants.js';

const {Content} = Layout;
const {Option} = Select;

const Customers = () => {

    let contextType = useContext(GlobalContext);

    const [customers, setCustomers] = useState([])
    const [pagination, setPagination] = useState({
        current: 1,
        pageSize: 10,
    })
    const [loading, setLoading] = useState(false)
    const [dataWhiteLabel, setDataWhiteLabel] = useState([])
    const [whiteLabel_id, setWhiteLabel_id] = useState(null)
    const [merchant_id, setMerchant_id] = useState(null)
    const [dataMerchant, setDataMerchant] = useState([])
    const [customer_name, setCustomer_name] = useState(null)

    const columns = [
        {
            title: '#',
            dataIndex: 'id',
            key: 'id',
            width: 50
        },
        {
            title: 'Nom',
            dataIndex: 'name',
            sorter: true,
            key: 'name',
        },
        {
            title: 'Adresse',
            dataIndex: 'address',
            key: 'address',
        },
        {
            title: '',
            dataIndex: 'id',
            key: 'id',
            width: 100,
            render: (id) => (
                <Space size="middle">
                    <Button type='primary'>
                        <Link to={'/customer/' + id}><FormOutlined/> Editer</Link>
                    </Button>
                    <Popconfirm
                        title="Etes-vous sûr de vouloir archiver ce client ?"
                        onConfirm={(e) => confirmArchiveCustomer(id, e)}
                        okText="Oui"
                        cancelText="Non"
                        placement="topRight"
                    >
                        <Button type='primary' danger className='button-table'>
                            <InboxOutlined/> Archiver
                        </Button>
                    </Popconfirm>
                </Space>
            )
        }
    ];

    /**
     *
     * @param {*} params
     */
    const loadCustomers = (params = {}) => {
        setLoading(true)
        getManyCustomers(params)
            .then((res) => res.json())
            .then((json) => {
                setLoading(false)
                setCustomers(json.results)
                setPagination({
                    ...params.pagination,
                    total: json.totalCount,
                })
            });
    }

    useEffect(() => {
        loadCustomers({pagination});
    }, []);

    /**
     *
     * @param {*} pagination
     * @param {*} filters
     * @param {*} sorter
     */
    const handleTableChange = (pagination, filters, sorter) => {
        loadCustomers({
            whiteLabel_id: whiteLabel_id,
            merchant_id: merchant_id,
            term: customer_name,
            sortField: sorter.field,
            sortOrder: sorter.order,
            pagination,
        });
    };

    /**
     *
     */
    const onClickFilterCustomers = () => {
        let data = {
            pagination: pagination,
            whiteLabel_id: whiteLabel_id,
            merchant_id: merchant_id,
            term: customer_name
        };

        getManyCustomers(data)
            .then((res) => res.json())
            .then((json) => {
                setCustomers(json.results)
                setPagination({
                    ...pagination,
                    total: json.totalCount,
                })
            });
    }

    /**
     *
     */
    const onClickDeleteFilter = () => {
        setWhiteLabel_id(null)
        setMerchant_id(null)
        setCustomer_name(null)

        const pagination = {
            current: 1,
            pageSize: 10
        };

        loadCustomers({pagination});
    }

    /**
     *
     * @param {*} value
     */
    const handleSearchWhiteLabel = (value) => {
        if (value.length > 2) {
            getManyWhiteLabels({
                term: value
            })
                .then((res) => res.json())
                .then((json) => {
                    setDataWhiteLabel(json.results)
                });
        }
    };

    /**
     *
     * @param {*} value
     */
    const onChangeWhiteLabel = (value) => {
        setWhiteLabel_id(value)
    }

    /**
     *
     * @param {*} value
     */
    const handleSearchMerchant = (value) => {
        if (value.length > 2) {
            let data = {
                term: value
            };
            getManyMerchants(data)
                .then((res) => res.json())
                .then((json) => {
                    setDataMerchant(json.results)
                });
        }
    }

    /**
     *
     * @param {*} values
     */
    const handleChangeMerchant = (v) => {
        setMerchant_id(v)
    }

    /**
     *
     * @param {*} e
     */
    const onSearchCustomerName = (e) => {
        if (e.target.value.length > 2) {
            setCustomer_name(e.target.value)
        }
    }

    /**
     *
     * @param {*} id
     * @param {*} e
     */
    const confirmDeleteCustomer = (id, e) => {
        e.preventDefault();
        deleteCustomer(id)
            .then((res) => res.json())
            .then(() => {
                const pagination = {
                    current: 1,
                    pageSize: 10
                };
                loadCustomers({pagination});
            });
    }

    /**
     *
     * @param {*} id
     * @param {*} e
     */
    const confirmArchiveCustomer = (id, e) => {
        e.preventDefault();
        archiveCustomer(id)
            .then((res) => res.json())
            .then(() => {
                const pagination = {
                    current: 1,
                    pageSize: 10
                };
                loadCustomers({pagination});
            });
    }


    const user = contextType.global.profile;

    let filterWhiteLabel = null;
    if (user !== null && user.whiteLabel_id === null) {
        filterWhiteLabel = (
            <div>
                <Space>
                    <div className="filter-title">Marques blanches</div>
                    <Select
                        className="filter"
                        showSearch
                        value={whiteLabel_id}
                        placeholder="Entrer au minimum 3 caractères"
                        showArrow={true}
                        filterOption={false}
                        onSearch={handleSearchWhiteLabel}
                        onChange={onChangeWhiteLabel}
                        notFoundContent={null}
                        allowClear
                    >
                        {dataWhiteLabel.map((d) => (
                            <Option key={d.id}>{d.name}</Option>
                        ))}
                    </Select>
                </Space>
            </div>
        );
    }

    return (
        <div>
            <PageHeader title="Clients" subTitle="Liste des clients"/>
            <Content style={{margin: '0 16px 16px 16px'}}>
                <div className="site-layout-background" style={{padding: 24, minHeight: 360}}>
                    <h2 style={{color: "#2FAC66"}}><UnorderedListOutlined/> LISTE</h2>
                    <div className="button-add">
                        <Space>
                            <Button>
                                <Link to='/customer/add'>
                                    <PlusCircleOutlined style={{paddingRight: "5px"}}/> Ajouter
                                </Link>
                            </Button>
                        </Space>
                    </div>
                    <Divider/>
                    <Space direction="vertical">
                        {filterWhiteLabel}
                        <Space>
                            <div className="filter-title">Commerçant</div>
                            <Select
                                className="filter"
                                showSearch
                                value={merchant_id}
                                placeholder="Entrer au minimum 3 caractères"
                                showArrow={true}
                                filterOption={false}
                                onSearch={handleSearchMerchant}
                                onChange={handleChangeMerchant}
                                notFoundContent={null}
                                allowClear
                            >
                                {dataMerchant.map((d) => (
                                    <Option key={d.id}>{d.name}</Option>
                                ))}
                            </Select>
                        </Space>
                        <Space>
                            <div className="filter-title">Nom</div>
                            <Input
                                placeholder="Entrer au minimum 3 caractères"
                                allowClear
                                enterButton={false}
                                onChange={onSearchCustomerName}
                                className="filter"
                            />
                        </Space>
                        <Space>
                            <Button
                                className="filter-button"
                                type="primary"
                                onClick={onClickFilterCustomers}
                            >
                                Filtrer
                            </Button>
                            <Button
                                style={{backgroundcolor: "#2FAC66"}}
                                onClick={onClickDeleteFilter}
                            >
                                Annuler
                            </Button>
                        </Space>
                    </Space>
                    <Divider/>
                    <Table
                        columns={columns}
                        loading={loading}
                        onChange={handleTableChange}
                        pagination={pagination}
                        dataSource={customers}
                    />
                </div>
            </Content>
        </div>
    );
}

export default Customers;

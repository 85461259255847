import React from 'react';

import moment from 'moment';

import {Divider, Layout, notification, PageHeader} from 'antd';
import {InfoCircleOutlined} from '@ant-design/icons';

import {
    getMerchantThemeBoundaries,
    getMerchantThemeOpeningSchedule,
    getOneMerchantTheme,
    updateMerchantTheme
} from '../../Api/MerchantThemes.js';
import FormMerchantTheme from './FormMerchantTheme.js';
import {GlobalContext} from '../../GlobalContext.js';

class MerchantThemeEdit extends React.Component {
    static contextType = GlobalContext;
    /**
     * 
     * @param {*} props 
     */
    constructor(props) {
        super(props);

        this.state = {
            value: 1,
            merchanttheme: null,
            user: null,
            openingSchedule: {
                "0": [[0, 24]],
                "1": [[0, 24]],
                "2": [[0, 24]],
                "3": [[0, 24]],
                "4": [[0, 24]],
                "5": [[0, 24]],
                "6": [[0, 24]],
            },
            boundaries: [],
        }

        this.onSubmit = this.onSubmit.bind(this);
    }

    /**
     * 
     */
    async loadOneMerchantTheme() {
        const id = this.props.match.params.id;
        await getOneMerchantTheme(id)
            .then((response) => {
                return response.json();
            }).then((data) => {
                this.setState({
                    merchanttheme: data.merchantTheme
                })
            })
    }

    async loadOpeningSchedules() {
        const id = this.props.match.params.id;
        await getMerchantThemeOpeningSchedule(id)
            .then((response) => {
                return response.json();
            }).then((data) => {
                if (data.length !== 0) {
                    let openingSchedules = data;
                    let openingScheduleByDay = {};
                    let openingScheduleByDay0 = [];
                    let openingScheduleByDay1 = [];
                    let openingScheduleByDay2 = [];
                    let openingScheduleByDay3 = [];
                    let openingScheduleByDay4 = [];
                    let openingScheduleByDay5 = [];
                    let openingScheduleByDay6 = [];
                    let formatSchedules = [];
                    openingSchedules.map((openingSchedule) => {
                        let openingMinutes = openingSchedule.opening_minutes;
                        if (openingMinutes === 30) {
                            openingMinutes = .5
                        }
                        let closingMinutes = moment(openingSchedule.closing_minutes, "mm").add(1, 'minutes').get('minutes');
                        if (closingMinutes === 30) {
                            closingMinutes = .5
                        }
                        openingSchedule = {
                            day: openingSchedule.day,
                            hourFrom: openingSchedule.opening_hours + openingMinutes,
                            hourTo: openingSchedule.closing_hours + closingMinutes
                        }
                        formatSchedules.push(openingSchedule);
                    })
                    formatSchedules.map((formatSchedule) => {
                        let hoursTable = [formatSchedule.hourFrom, formatSchedule.hourTo]
                        if (formatSchedule.day === 0) {
                            openingScheduleByDay0.push(hoursTable)
                        } else if (formatSchedule.day === 1) {
                            openingScheduleByDay1.push(hoursTable)
                        } else if (formatSchedule.day === 2) {
                            openingScheduleByDay2.push(hoursTable)
                        } else if (formatSchedule.day === 3) {
                            openingScheduleByDay3.push(hoursTable)
                        } else if (formatSchedule.day === 4) {
                            openingScheduleByDay4.push(hoursTable)
                        } else if (formatSchedule.day === 5) {
                            openingScheduleByDay5.push(hoursTable)
                        } else {
                            openingScheduleByDay6.push(hoursTable)
                        }
                    })
                    openingScheduleByDay = {
                        "0": openingScheduleByDay0,
                        "1": openingScheduleByDay1,
                        "2": openingScheduleByDay2,
                        "3": openingScheduleByDay3,
                        "4": openingScheduleByDay4,
                        "5": openingScheduleByDay5,
                        "6": openingScheduleByDay6,
                    }
                    this.setState({
                        openingSchedule: openingScheduleByDay
                    })
                }
            })
    }

    async loadBoundaries() {
        const id = this.props.match.params.id;
        await getMerchantThemeBoundaries(id)
            .then((response) => {
                return response.json();
            }).then((data) => {
                let translate = {
                    deliveryArea: 'zone de livraison',
                    pickupArea: 'zone de retrait'
                }
                data.map((d) => {
                    d.zone_type = translate[d.zone_type]
                })
                this.setState({
                    boundaries: data
                })
            })
    }

    /**
     * 
     */
    async loadData() {
        await this.loadOneMerchantTheme();
        await this.loadOpeningSchedules();
        await this.loadBoundaries();
    }

    /**
     * 
     */
    componentDidMount() {
        this.loadData();
    }

    /**
     * 
     */
    reloadBoundaries = () => {
        this.loadBoundaries();
    }

    /**
     * 
     * @param {*} values 
     */
    onSubmit(values) {
        const merchantThemeId = this.props.match.params.id;
        let newMerchantTheme = {
            id: merchantThemeId,
            name: values.name,
            whiteLabel_id: values.whiteLabel_id,
            price_grid: values.price_grid,
            detailed_prices: values.detailed_prices,
        }
        if (values.fixed_price !== null) {
            newMerchantTheme.fixed_price = values.fixed_price.price
            if (values.fixed_price.id !== null) {
                newMerchantTheme.price_id = values.fixed_price.id
            }
        }
        if (values.configuration !== null) {
            newMerchantTheme.max_distance = values.configuration.max_distance;
            newMerchantTheme.max_weigth = values.configuration.max_weigth;
            newMerchantTheme.max_volume = values.configuration.max_volume;
            newMerchantTheme.slack_webhook = values.configuration.slack_webhook;
            newMerchantTheme.max_length = values.configuration.max_length;
            newMerchantTheme.max_width = values.configuration.max_width;
            newMerchantTheme.max_height = values.configuration.max_height;
            newMerchantTheme.min_delay_to_start = values.configuration.min_delay_to_start;
        }
        if (values.commission !== null) {
            newMerchantTheme.commission_price = values.commission.commission_price
        }
        updateMerchantTheme(merchantThemeId, newMerchantTheme)
            .then(function (response) {
                return response.json();
            }).then((data) => {
                notification['success']({
                    message: 'Le Thème commerçant a bien été mis à jour'
                })
                this.loadOneMerchantTheme();
            })
    };

    render() {
        const { Content } = Layout;

        return (
            <div>
                <PageHeader title="Thèmes commerçants" subTitle="Edition d'un thème commerçant" />
                <Content style={{ margin: '0 16px 16px 16px' }}>
                    <div className="site-layout-background" style={{ padding: 24, minHeight: 360 }}>
                        <h2 style={{ color: "#2FAC66" }}><InfoCircleOutlined /> INFORMATIONS</h2>
                        <Divider></Divider>
                        <FormMerchantTheme merchanttheme={this.state.merchanttheme} boundaries={this.state.boundaries} user={this.context.global.profile}
                            openingSchedule={this.state.openingSchedule} reloadBoundaries={this.reloadBoundaries} formSubmit={this.onSubmit} />
                    </div>
                </Content>
            </div>
        )
    }
}

export default MerchantThemeEdit;

import React from 'react';
import {Link} from 'react-router-dom';
import moment from 'moment';

import {Button, Divider, Input, Layout, PageHeader, Select, Space, Table, Tag} from 'antd';
import {FormOutlined, PlusCircleOutlined, UnorderedListOutlined} from '@ant-design/icons';

import {getManyDrivers} from '../../Api/Drivers.js';
import {getManyWhiteLabels} from "../../Api/WhiteLabels.js";
import {GlobalContext} from '../../GlobalContext.js';

class Drivers extends React.Component {
    static contextType = GlobalContext;
    /**
     * 
     * @param {*} props 
     */
    constructor(props) {
        super(props);

        this.state = {
            drivers: [],
            pagination: {
                current: 1,
                pageSize: 10,
            },
            loading: false,
            dataWhiteLabel: [],
            whiteLabel_id: null,
            driver_name: "",
            status: ["valid"]
        };

        this.columns = [
            {
                title: '#',
                dataIndex: 'id',
                key: 'id',
                width: 50
            },
            {
                title: 'Nom',
                sorter: true,
                key: 'name',
                render: (row) => (
                    <div>{row.firstName} {row.lastName}</div>
                )
            },
            {
                title: 'Téléphone',
                dataIndex: 'phone',
                key: 'phone',
            },
            {
                title: 'Adresse',
                dataIndex: 'address',
                key: 'address',
            },
            {
                title: 'Date de création',
                dataIndex: 'created_at',
                key: 'created_at',
                render: (created_at) => (
                    <div>{moment(created_at, 'YYYY-MM-DD hh:mm:ss').format('DD/MM/YYYY')}</div>
                )
            },
            {
                title: 'Dernière mise à jour',
                dataIndex: 'updated_at',
                key: 'updated_at',
                render: (updated_at) => (
                    <div>{moment(updated_at, 'YYYY-MM-DD hh:mm:ss').format('DD/MM/YYYY à hh:mm')}</div>
                )
            },
            {
                title: 'Statut',
                dataIndex: 'status',
                key: 'status',
                render: (status) => {
                    if (status == 'draft') {
                        return (
                            <Tag color="green" key={status}>
                                Brouillon
                            </Tag>
                        )
                    } else if (status == 'valid') {
                        return (
                            <Tag color="green" key={status}>
                                Validé
                            </Tag>
                        )
                    } else {
                        return (
                            <Tag color="green" key={status}>
                                Expulsé
                            </Tag>
                        )
                    }
                }
            },
            {
                title: '',
                dataIndex: 'id',
                key: 'id',
                width: 100,
                render: (id) => (
                    <Space size="middle">
                        <Button type='primary'>
                            <Link to={'/driver/' + id}><FormOutlined /> Editer</Link>
                        </Button>
                    </Space>
                )
            }
        ];

        this.handleSearchWhiteLabel = this.handleSearchWhiteLabel.bind(this);
        this.onClickFilter = this.onClickFilter.bind(this);
        this.onChangeWhiteLabel = this.onChangeWhiteLabel.bind(this);
        this.onClickDeleteFilter = this.onClickDeleteFilter.bind(this);
        this.onSearchDriverName = this.onSearchDriverName.bind(this);
        this.handleChangeStatus = this.handleChangeStatus.bind(this);
    }

    /**
     * 
     * @param {*} params 
     */
    loadDrivers() {
        this.setState({ loading: true })
        let data = {
            pagination: {
                current: 1,
                pageSize: 10,
            },
            status: this.state.status
        }
        getManyDrivers(data)
            .then((res) => res.json())
            .then((json) => {
                this.setState({
                    loading: false,
                    drivers: json.results,
                    pagination: {
                        current: this.state.pagination.current,
                        pageSize: this.state.pagination.pageSize,
                        total: json.totalCount,
                    },
                })
            })
    }

    /**
     * 
     */
    componentDidMount() {
        const { pagination } = this.state;
        this.loadDrivers({ pagination });
    }

    /**
     * 
     * @param {*} pagination 
     * @param {*} filters 
     * @param {*} sorter 
     */
    handleTableChange = (pagination, filters, sorter) => {
        let data = {
            whiteLabel_id: this.state.whiteLabel_id,
            term: this.state.driver_name,
            status: this.state.status,
            sortField: sorter.field,
            sortOrder: sorter.order,
            pagination,
        }
        getManyDrivers(data)
            .then((res) => res.json())
            .then((json) => {
                this.setState({
                    loading: false,
                    drivers: json.results,
                    pagination: {
                        current: pagination.current,
                        pageSize: pagination.pageSize,
                        total: json.totalCount,
                    },
                })
            })
    };

    /**
     * 
     */
    onClickFilter() {
        const pagination = this.state.pagination;
        let whiteLabel_id = this.state.whiteLabel_id;
        let driver_name = this.state.driver_name;
        let status = this.state.status;
        let data = {
            pagination: pagination,
            whiteLabel_id: whiteLabel_id,
            term: driver_name,
            status: status
        }
        getManyDrivers(data)
            .then((res) => res.json())
            .then((json) => {
                this.setState({
                    drivers: json.results,
                    pagination: {
                        current: pagination.current,
                        pageSize: pagination.pageSize,
                        total: json.totalCount,
                    },
                });
            });
    }

    /**
     * 
     */
    onClickDeleteFilter() {
        this.setState({
            driver_name: "",
            whiteLabel_id: null,
            status: ["valid"]
        })
        const pagination = {
            current: 1,
            pageSize: 10
        };
        let data = {
            driver_name: null,
            whiteLabel_id: null,
            pagination: pagination,
            status: ["valid"]
        }
        getManyDrivers(data)
            .then((res) => res.json())
            .then((json) => {
                this.setState({
                    drivers: json.results,
                    pagination: {
                        current: pagination.current,
                        pageSize: pagination.pageSize,
                        total: json.totalCount,
                    },
                });
            });
    }

    /**
     *
     * @param {*} value
     */
    handleSearchWhiteLabel = (value) => {
        if (value.length > 2) {
            getManyWhiteLabels({
                term: value
            })
                .then((res) => res.json())
                .then((json) => {
                    this.setState({ dataWhiteLabel: json.results });
                });
        }
    };

    /**
     * 
     * @param {*} value 
     */
    onChangeWhiteLabel(value) {
        this.setState({ whiteLabel_id: value })
    }

    onSearchDriverName(e) {
        if (e.target.value.length > 2) {
            this.setState({ driver_name: e.target.value })
        }
    }

    handleChangeStatus(value) {
        this.setState({ status: value });
    }

    render() {
        const { Content } = Layout;
        const { Option } = Select;
        const { drivers, pagination, loading } = this.state;

        const user = this.context.global.profile;

        let filterWhiteLabel = null;
        if (user !== null && user.whiteLabel_id === null) {
            filterWhiteLabel = (
                <div>
                    <Space>
                        <div className="filter-title">Marques blanches </div>
                        <Select
                            className="filter"
                            showSearch
                            value={this.state.whiteLabel_id}
                            placeholder="Entrer au minimum 3 caractères"
                            showArrow={true}
                            filterOption={false}
                            onSearch={this.handleSearchWhiteLabel}
                            onChange={this.onChangeWhiteLabel}
                            notFoundContent={null}
                        >
                            {this.state.dataWhiteLabel.map((d) => (
                                <Option key={d.id}>{d.name}</Option>
                            ))}
                        </Select>
                    </Space>
                </div>
            )
        }

        return (
            <div>
                <PageHeader title="Transporteurs" subTitle="Liste des transporteurs" />
                <Content style={{ margin: '0 16px 16px 16px' }}>
                    <div className="site-layout-background" style={{ padding: 24, minHeight: 360 }}>
                        <h2 style={{ color: "#2FAC66" }}><UnorderedListOutlined /> LISTE</h2>
                        <div className="button-add">
                            <Space>
                                <Button>
                                    <Link to='/driver/add'><PlusCircleOutlined style={{ paddingRight: "5px" }} /> Ajouter</Link>
                                </Button>
                            </Space>
                        </div>
                        <Divider></Divider>
                        <Space direction="vertical">
                            {filterWhiteLabel}
                            <Space>
                                <div className="filter-title">Nom</div>
                                <Input
                                    placeholder="Entrer au minimum 3 caractères"
                                    allowClear
                                    enterButton={false}
                                    onChange={this.onSearchDriverName}
                                    className="filter"
                                />
                            </Space>
                            <Space>
                                <div className="filter-title">Statut du transporteur</div>
                                <Select
                                    mode="multiple"
                                    className="filter"
                                    value={this.state.status}
                                    onChange={this.handleChangeStatus}
                                    placeholder='Sélectionner un statut de course'>
                                    <Option value="draft">Brouillon</Option>
                                    <Option value="valid">Validé</Option>
                                    <Option value="deportee">Expulsé</Option>
                                </Select>
                            </Space>
                            <Space>
                                <Button className="filter-button" type="primary" onClick={this.onClickFilter}>
                                    Filtrer
                                </Button>
                                <Button style={{ backgroundcolor: "#2FAC66" }} onClick={this.onClickDeleteFilter}>
                                    Annuler
                                </Button>
                            </Space>
                        </Space>
                        <Divider></Divider>
                        <Table
                            columns={this.columns}
                            loading={loading}
                            onChange={this.handleTableChange}
                            pagination={{
                                ...pagination,
                                showSizeChanger: true
                            }}
                            dataSource={drivers}
                        />
                    </div>
                </Content>
            </div>

        )
    }
}

export default Drivers;

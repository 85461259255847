import React, {useEffect} from 'react';
import {ReactComponent as IconLogo} from "../../map.svg";

import {Tabs} from 'antd';
import Icon, {
    BuildOutlined,
    CarOutlined,
    ClockCircleOutlined,
    DeploymentUnitOutlined,
    EuroCircleOutlined,
    FormOutlined,
    HomeOutlined,
    MoneyCollectOutlined,
    PieChartOutlined,
    RadarChartOutlined,
    SafetyCertificateOutlined,
    UnorderedListOutlined
} from '@ant-design/icons';

import FormCoursesTab from './FormCoursesTab.js';
import FormCommerceTab from './FormCommerceTab.js';
import FormRemarqueTab from './FormRemarqueTab.js';
import FormChartsCourses from './FormChartsCourses.js';
import FormConfigurationTab from './FormConfigurationTab.js';
import FormAPITab from './FormAPITab.js';
import FormOpeningScheduleTab from './FormOpeningScheduleTab.js';
import FormBoundariesTab from './FormBoundariesTab.js';
import FormStoresTab from './FormStoresTab.js';
import FormTransportRulesTab from './FormTransportRulesTab.js';
import FormMapTab from './FormMapTab.js';
import FormInvoiceTab from './FormInvoiceTab.js';
import FormPricingTab from './FormPricingTab.js';

const { TabPane } = Tabs;

/**
 *
 */
const FormMerchant = (props) => {

    const { merchant, user, userToken, openingSchedule, boundaries,  deliveryArea, pickupArea, latitude, longitude } = props;

    let [disable, setDisable] = React.useState(true);
    let tabKey = merchant ? "1" : "3";

    /**
     *
     * @param {*} values
     */
    const onFinish = (values) => {
        props.formSubmit(values)
    };

    /**
     *
     */
    const reloadBoundaries = () => {
        props.reloadBoundaries();
    };

    /**
     * 
     * @param {*} value 
     */
     const onChangeTracking = (value) => {
        props.trackingChange(value);
    };

    /**
     * 
     * @param {*} values 
     */
    const onClickInvoice = (values) => {
        props.getInvoice(values);
    };

    /**
     *
     */
    useEffect(() => {
        if (merchant !== null) {
            setDisable(false);
        }
    }, [merchant]);

    let tabAPIToken = null;
    if (user !== null) {
        if (user.whiteLabel_id === null) {
            tabAPIToken = (
                <TabPane tab={<span><SafetyCertificateOutlined />API</span>} disabled={disable} key="7">
                    <FormAPITab merchant={merchant} userToken={userToken} formSubmit={onFinish} />
                </TabPane>
            );
        }
    }

    let coursesTab = null;
    let mapTab = null;
    if (merchant) {
        mapTab = (
            <TabPane tab={<span><Icon component={IconLogo} style={{ fontSize: 15 }} />Carte</span>} key="1">
                <FormMapTab
                    deliveryArea={deliveryArea}
                    pickupArea={pickupArea}
                    trackingChange={onChangeTracking}
                    latitude={latitude}
                    longitude={longitude}
                />
            </TabPane>
        );
        coursesTab = (
            <TabPane tab={<span><UnorderedListOutlined />Courses</span>} key="2" disabled={disable}>
                <FormCoursesTab merchant={merchant} />
            </TabPane>
        );
    }

    return (
        <div>
            <Tabs defaultActiveKey={tabKey} type="card">
                {mapTab}
                {coursesTab}
                <TabPane tab={<span><HomeOutlined />Commerce</span>} key="3">
                    <FormCommerceTab merchant={merchant} user={user} formSubmit={onFinish} />
                </TabPane>
                <TabPane tab={<span><FormOutlined />Remarques</span>} disabled={disable} key="4">
                    <FormRemarqueTab merchant={merchant} formSubmit={onFinish} />
                </TabPane>
                <TabPane tab={<span><PieChartOutlined />Dashboard</span>} disabled={disable} key="13">
                    <FormChartsCourses merchant={merchant} />
                </TabPane>
                <TabPane tab={<span><EuroCircleOutlined />Tarification</span>} disabled={disable} key="5">
                    <FormPricingTab merchant={merchant} formSubmit={onFinish} />
                </TabPane>
                <TabPane tab={<span><BuildOutlined />Configuration</span>} disabled={disable} key="6">
                    <FormConfigurationTab merchant={merchant} formSubmit={onFinish} />
                </TabPane>
                <TabPane tab={<span><ClockCircleOutlined />Horaires d'ouverture</span>} disabled={disable} key="8">
                    <FormOpeningScheduleTab merchant={merchant} openingSchedule={openingSchedule} formSubmit={onFinish} />
                </TabPane>
                {tabAPIToken}
                <TabPane tab={<span><RadarChartOutlined />Zones d'activité</span>} disabled={disable} key="9">
                    <FormBoundariesTab merchant={merchant} boundaries={boundaries} reloadBoundaries={reloadBoundaries}/>
                </TabPane>
                <TabPane tab={<span><DeploymentUnitOutlined />Entrepôts</span>} disabled={disable} key="10">
                    <FormStoresTab merchant={merchant} />
                </TabPane>
                <TabPane tab={<span><CarOutlined />Transport</span>} disabled={disable} key="11">
                    <FormTransportRulesTab merchant={merchant} />
                </TabPane>
                <TabPane tab={<span><MoneyCollectOutlined />Facturation</span>} disabled={disable} key="12">
                    <FormInvoiceTab merchant={merchant} getInvoice={onClickInvoice}/>
                </TabPane>
            </Tabs>
        </div>
    );
}

export default FormMerchant;

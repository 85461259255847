import React, { useState } from 'react';
import moment from 'moment';
import { Row, Col, Badge } from 'antd';
import { PieChart, Pie, Sector, Cell, ResponsiveContainer } from 'recharts';
import CustomizedAxisTick from './CustomizedAxisTick';

/**
 *
 * @param {*} props
 * @returns
 */
const renderActiveShape = (props) => {
    const RADIAN = Math.PI / 180;
    const { cx, cy, midAngle, innerRadius, outerRadius, startAngle, endAngle, fill, payload, percent, value } = props;
    const sin = Math.sin(-RADIAN * midAngle);
    const cos = Math.cos(-RADIAN * midAngle);
    const sx = cx + (outerRadius + 10) * cos;
    const sy = cy + (outerRadius + 10) * sin;
    const mx = cx + (outerRadius + 30) * cos;
    const my = cy + (outerRadius + 30) * sin;
    const ex = mx + (cos >= 0 ? 1 : -1) * 22;
    const ey = my;
    const textAnchor = cos >= 0 ? 'start' : 'end';
    let translateStatut = null;
    if (payload.status === 'canceled') {
        translateStatut = "Annulée"
    } else if (payload.status === 'available') {
        translateStatut = "Emise"
    } else if (payload.status === 'assigned') {
        translateStatut = "Assignée"
    } else if (payload.status === 'started') {
        translateStatut = "Démarée"
    } else if (payload.status === 'inProgress') {
        translateStatut = "Colis Récupéré"
    } else if (payload.status === 'isDelivered') {
        translateStatut = "Livrée"
    } else if (payload.status === 'draft') {
        translateStatut = "Brouillon"
    } else if (payload.status === 'failed') {
        translateStatut = "Echouée"
    } else {
        translateStatut = "Impossible"
    }

    return (
        <g>
            <text x={cx} y={cy} dy={8} textAnchor="middle" fill={fill}>
                {translateStatut}
            </text>
            <Sector
                cx={cx}
                cy={cy}
                innerRadius={innerRadius}
                outerRadius={outerRadius}
                startAngle={startAngle}
                endAngle={endAngle}
                fill={fill}
            />
            <Sector
                cx={cx}
                cy={cy}
                startAngle={startAngle}
                endAngle={endAngle}
                innerRadius={outerRadius + 6}
                outerRadius={outerRadius + 10}
                fill={fill}
            />
            <path d={`M${sx},${sy}L${mx},${my}L${ex},${ey}`} stroke={fill} fill="none" />
            <circle cx={ex} cy={ey} r={2} fill={fill} stroke="none" />
            <text x={ex + (cos >= 0 ? 1 : -1) * 12} y={ey} textAnchor={textAnchor} fill="#333">{`${translateStatut} : ${payload.total_status}`}</text>
        </g>
    );
};

/**
 *
 * @param {*} data
 * @param {*} total
 * @returns
 */
const ChartCoursesStatus = ({ data, total }) => {

    const [activeIndex, setActiveIndex] = useState(0);
    const COLORS = ['#2FAC66', '#268E50', '#0B4624', '#75D89D', '#23DB73', '#75B567', '#3BAE64', '#ABFBCE'];

    /**
     *
     * @param {*} _
     * @param {*} index
     */
    const onPieEnter = (_, index) => {
        setActiveIndex(index);
    };

    return (
        <div>
            <Row>
                <Col span={16}>
                    <h3>
                        Statuts des courses du jour
                        <br />
                        <small>Total des courses</small>
                        <Badge
                            style={{ backgroundColor: '#2FAC66', marginLeft: '5px' }}
                            count={total}
                            showZero={true}
                            overflowCount={999999999}
                        />
                    </h3>
                </Col>
            </Row>
            <ResponsiveContainer width="100%" height={500}>
                <PieChart >
                    <Pie
                        data={data}
                        activeIndex={activeIndex}
                        activeShape={renderActiveShape}
                        dataKey="total_status"
                        cx="50%"
                        cy="50%"
                        innerRadius={80}
                        outerRadius={140}
                        fill="#2FAC66"
                        onMouseEnter={onPieEnter}
                    >
                        {
                            data.map((entry, index) => <Cell key={index} fill={COLORS[index % COLORS.length]} />)
                        }
                    </Pie>
                </PieChart>
            </ResponsiveContainer>
        </div>
    );
};

export default ChartCoursesStatus;
import React from 'react';

import {Tabs} from 'antd';
import {
    BuildOutlined,
    CarOutlined,
    ClockCircleOutlined,
    EuroCircleOutlined,
    InfoCircleOutlined,
    RadarChartOutlined
} from '@ant-design/icons';

import FormGeneralTab from './FormGeneralTab.js';
import FormConfigurationTab from './FormConfigurationTab.js';
import FormBoundariesTab from './FormBoundariesTab.js';
import FormOpeningScheduleTab from './FormOpeningScheduleTab.js';
import FormTransportRulesTab from './FormTransportRulesTab.js';
import FormPricingTab from './FormPricingTab.js';

const { TabPane } = Tabs;

/**
 * 
 * @param {*} props 
 * @returns 
 */
const FormMerchantTheme = (props) => {

    let { merchanttheme, user, boundaries, openingSchedule } = props;

    /**
     * 
     * @param {*} values 
     */
    const onFinish = (values) => {
        props.formSubmit(values);
    };

    /**
    * 
    */
    const reloadBoundaries = () => {
        props.reloadBoundaries();
    }

    let disable = false;
    if (merchanttheme === null) {
        disable = true;
    }

    return (
        <div>
            <Tabs defaultActiveKey="1" type="card">
                <TabPane tab={<span><InfoCircleOutlined />Général</span>} key="1">
                    <FormGeneralTab merchanttheme={merchanttheme} user={user} formSubmit={onFinish} />
                </TabPane>
                <TabPane tab={<span><EuroCircleOutlined />Tarification</span>} disabled={disable} key="2">
                    <FormPricingTab merchanttheme={merchanttheme} formSubmit={onFinish} />
                </TabPane>
                <TabPane tab={<span><BuildOutlined />Configuration</span>} disabled={disable} key="3">
                    <FormConfigurationTab merchanttheme={merchanttheme} formSubmit={onFinish} />
                </TabPane>
                <TabPane tab={<span><ClockCircleOutlined />Horaires d'ouverture</span>} disabled={disable} key="4">
                    <FormOpeningScheduleTab merchanttheme={merchanttheme} openingSchedule={openingSchedule} formSubmit={onFinish} />
                </TabPane>
                <TabPane tab={<span><RadarChartOutlined />Zones d'activité</span>} disabled={disable} key="5">
                    <FormBoundariesTab merchanttheme={merchanttheme} boundaries={boundaries} reloadBoundaries={reloadBoundaries} />
                </TabPane>
                <TabPane tab={<span><CarOutlined />Transport</span>} disabled={disable} key="6">
                    <FormTransportRulesTab merchanttheme={merchanttheme} />
                </TabPane>
            </Tabs>
        </div>
    )
}

export default FormMerchantTheme;

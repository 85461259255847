import config from '../Config.js';

/**
 * 
 * @param {*} data 
 * @returns 
 */
export function getManyMerchants(data) {
    return fetch(config.hostApi + 'merchants', {
        method: 'POST',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + localStorage.getItem('token')
        },
        body: JSON.stringify(data)
    })
}

/**
 * 
 * @param {*} id 
 */
export function getOneMerchant(id) {
    return fetch(config.hostApi + 'merchant/' + id, {
        method: 'GET',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + localStorage.getItem('token')
        },
    })
}

/**
 * 
 * @param {*} data 
 */
export function postMerchant(data) {
    return fetch(config.hostApi + 'merchant', {
        method: 'POST',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + localStorage.getItem('token')
        },
        body: JSON.stringify(data)
    })
}

/**
 * 
 * @param {*} id 
 * @param {*} data 
 */
export function updateMerchant(id, data) {
    return fetch(config.hostApi + 'merchant/' + id, {
        method: 'PUT',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + localStorage.getItem('token')
        },
        body: JSON.stringify(data)
    })
}

/**
 * 
 * @param {*} id 
 */
export function deleteMerchant(id) {
    return fetch(config.hostApi + 'merchant/' + id, {
        method: 'DELETE',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + localStorage.getItem('token')
        },
    })
}

/**
 * 
 * @param {*} id 
 */
export function getMerchantCourses(id, data) {
    return fetch(config.hostApi + 'merchant/' + id + '/courses', {
        method: 'POST',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + localStorage.getItem('token')
        },
        body: JSON.stringify(data)
    })
}


/**
 * 
 * @param {*} dateStart 
 * @param {*} dateEnd 
 */
export function getInvoice(dateStart, dateEnd, id) {
    return fetch(config.hostApi + 'merchant/' + id + '/invoice', {
        method: 'POST',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + localStorage.getItem('token')
        },
        body: JSON.stringify({
            start: dateStart,
            end: dateEnd
        }),
        responseType: "blob",
    })
}

/**
 * 
 * @param {*} id 
 */
export function getMerchantUser(id) {
    return fetch(config.hostApi + 'merchant/' + id + '/userToken', {
        method: 'GET',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + localStorage.getItem('token')
        },
    })
}

/**
 * 
 * @param {*} id 
 */
export function getExternalAPIToken(id) {
    return fetch(config.hostApi + 'merchant/' + id + '/token', {
        method: 'PUT',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': 'Bearer' + localStorage.getItem('token')
        },
    })
}

/**
 * 
 * @param {*} id 
 */
 export function findFilterDayCourses(id, payload) {
    return fetch(config.hostApi + 'merchant/' + id + '/courses/findDay', {
        method: 'POST',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': 'Bearer' + localStorage.getItem('token')
        },
        body: JSON.stringify(payload)
    })
}

/**
 * 
 * @param {*} id 
 */
 export function merchantOpeningSchedule(id, data) {
    return fetch(config.hostApi + 'merchant/' + id + '/saveOpeningSchedule', {
        method: 'POST',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': 'Bearer' + localStorage.getItem('token')
        },
        body: JSON.stringify({
            'opening_schedules' : data
        })
    })
}

/**
 * 
 * @param {*} id 
 */
 export function getMerchantOpeningSchedule(id) {
    return fetch(config.hostApi + 'merchant/' + id + '/getOpeningSchedule', {
        method: 'GET',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': 'Bearer' + localStorage.getItem('token')
        }
    })
}

/**
 * 
 * @param {*} id 
 * @param {*} data 
 * @returns 
 */
 export function saveMerchantBoundaries(id, data) {
    return fetch(config.hostApi + 'merchant/' + id + '/boundaries', {
        method: 'POST',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + localStorage.getItem('token')
        },
        body: JSON.stringify(data)
    })
}

/**
 * 
 * @param {*} id 
 * @returns 
 */
 export function getMerchantBoundaries(id) {
    return fetch(config.hostApi + 'merchant/' + id + '/getBoundaries', {
        method: 'GET',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + localStorage.getItem('token')
        },
    })
}

/**
 * 
 * @param {*} id 
 * @param {*} data 
 * @returns 
 */
 export function saveMerchantStore(id, data) {
    return fetch(config.hostApi + 'merchant/' + id + '/store', {
        method: 'POST',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + localStorage.getItem('token')
        },
        body: JSON.stringify(data)
    })
}

/**
 * 
 * @param {*} id 
 * @param {*} data 
 * @returns 
 */
 export function updateMerchantStore(id, data) {
    return fetch(config.hostApi + 'merchant/' + id + '/store', {
        method: 'PUT',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + localStorage.getItem('token')
        },
        body: JSON.stringify(data)
    })
}

/**
 * 
 * @param {*} id 
 * @returns 
 */
 export function getMerchantStores(id) {
    return fetch(config.hostApi + 'merchant/' + id + '/getStores', {
        method: 'GET',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + localStorage.getItem('token')
        },
    })
}

/**
 * 
 * @param {*} id 
 * @returns 
 */
 export function deleteMerchantStore(id, store_id) {
    return fetch(config.hostApi + 'merchant/' + id + '/store/' + store_id, {
        method: 'DELETE',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + localStorage.getItem('token')
        },
    })
}

/**
 * 
 * @param {*} id 
 * @returns 
 */
 export function getMerchantOneStore(id, store_id) {
    return fetch(config.hostApi + 'merchant/' + id + '/store/' + store_id, {
        method: 'GET',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + localStorage.getItem('token')
        },
    })
}
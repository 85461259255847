import React, { useRef, useEffect, useState } from 'react';
import mapboxgl, { Popup } from 'mapbox-gl';
import '@mapbox/mapbox-gl-draw/dist/mapbox-gl-draw.css';
import MapboxDraw from "@mapbox/mapbox-gl-draw";

import * as turf from '@turf/turf';

import config from '../../Config.js';

mapboxgl.accessToken = config.mapboxToken;

/**
 * 
 * @param {*} props 
 * @returns 
 */
export default function MapForDrawer(props) {

    let { merchant, boundaries, editBoundaries } = props;

    const mapContainer = useRef(null);
    let map = useRef(null);
    const [lng, setLng] = useState(2);
    const [lat, setLat] = useState(46.7);
    const [zoom, setZoom] = useState(5.4);

    /**
     * 
     */
    useEffect(() => {
        if (map.current) return; //initialize map only once
        map = map.current;
        map = new mapboxgl.Map({
            container: mapContainer.current,
            style: 'mapbox://styles/mapbox/streets-v11',
            center: [lng, lat],
            zoom: zoom
        });

        let draw = new MapboxDraw({
            displayControlsDefault: false,
            controls: {
                polygon: true,
                trash: true
            },
        });

        map.addControl(draw);

        if (merchant !== null && merchant !== undefined) {
            setLng(merchant.longitude);
            setLat(merchant.latitude);
            setZoom(12);
            map.setCenter([
                merchant.longitude, merchant.latitude
            ]);

            var el = document.createElement('div');
            el.className = 'marker-pickup';

            new mapboxgl.Marker(el)
                .setLngLat([merchant.longitude, merchant.latitude])
                .addTo(map);
        }

        let geoJsonDelivery = {};
        let featureDelivery = [];
        let featureDetailDelivery = {};

        let geoJsonPickup = {};
        let featurePickup = [];
        let featureDetailPickup = {};

        let geoJsonEdit = {};
        let featureEdit = [];
        let featureDetailEdit = {};

        let geoJson = {};
        let feature = [];

        let bounds = null;

        //permet d'ajouter ou de modifier une zone
        if (editBoundaries.length !== 0) {
            editBoundaries.map((editBoundarie) => {
                featureDetailEdit = {
                    "type": 'Feature',
                    "geometry": {
                        "type": 'Polygon',
                        "coordinates": editBoundarie.coordinates,
                    },
                    "properties": {
                        "id": editBoundarie.id,
                        'zone_type': editBoundarie.zone_type,
                        'zone_name': editBoundarie.zone_name
                    },

                };
                featureEdit.push(featureDetailEdit);
                feature.push(featureDetailEdit);
            })

            geoJsonEdit = {
                type: 'FeatureCollection',
                features: featureEdit
            };

            bounds = turf.bbox(geoJsonEdit);

            map.on('draw.create', sourceRefresh);
            map.on('draw.delete', sourceRefresh);
            map.on('draw.update', sourceRefresh);

            function sourceRefresh(e) {
                var data = draw.getAll();
                map.getSource('editBoundarie').setData(data);
                let features = data.features;
                let formatBoundaries = [];
                features.map((feature, i) => {
                    if (feature.properties.zone_name === undefined && feature.properties.zone_type === undefined) {
                        feature.geometry.id = feature.id
                        feature.geometry.type = "polygon"
                        formatBoundaries.push(feature.geometry)
                    } else {
                        feature.geometry.id = feature.properties.id
                        feature.geometry.zone_name = feature.properties.zone_name
                        feature.geometry.zone_type = feature.properties.zone_type
                        feature.geometry.type = "polygon"
                        formatBoundaries.push(feature.geometry)
                    }
                })
                props.boundariesCoordinates(formatBoundaries);

            };

        } else {

            map.on('draw.create', updateArea);
            map.on('draw.delete', updateArea);
            map.on('draw.update', updateArea);

            function updateArea(e) {
                let data = draw.getAll();
                let features = data.features;
                let formatBoundaries = [];
                features.map((feature, i) => {
                    feature.geometry.id = feature.id
                    feature.geometry.type = "polygon"
                    formatBoundaries.push(feature.geometry)
                })
                props.boundariesCoordinates(formatBoundaries)
            }
        }

        //affiche les zones déjà déssinées sans pouvoir les modifier
        if (boundaries.length !== 0) {
            boundaries.map((boundarie, i) => {
                if (boundarie.zone_type === 'zone de livraison') {
                    featureDetailDelivery = {
                        "type": 'Feature',
                        "geometry": {
                            "type": 'Polygon',
                            "coordinates": boundarie.coordinates,
                        },
                        "properties": {
                            "id": boundarie.id,
                            'zone_type': boundarie.zone_type,
                            'zone_name': boundarie.zone_name
                        },

                    };
                    featureDelivery.push(featureDetailDelivery);
                    feature.push(featureDetailDelivery);
                }

                if (boundarie.zone_type === 'zone de retrait') {
                    featureDetailPickup = {
                        "type": 'Feature',
                        "geometry": {
                            "type": 'Polygon',
                            "coordinates": boundarie.coordinates,
                        },
                        "properties": {
                            "id": boundarie.id,
                            'zone_type': boundarie.zone_type,
                            'zone_name': boundarie.zone_name
                        },

                    };
                    featurePickup.push(featureDetailPickup);
                    feature.push(featureDetailPickup);
                }

            })

            geoJsonDelivery = {
                type: 'FeatureCollection',
                features: featureDelivery
            };

            geoJsonPickup = {
                type: 'FeatureCollection',
                features: featurePickup
            };

            geoJson = {
                type: 'FeatureCollection',
                features: feature
            };

            if (geoJson.features.length > 0) {
                bounds = turf.bbox(geoJson);
            }
            

        }

        map.on('load', function () {
            draw.deleteAll();
            if (Object.keys(geoJsonEdit).length !== 0) {
                map.addSource('editBoundarie', {
                    "type": "geojson",
                    "data": geoJsonEdit
                });
                map.addLayer({
                    id: 'editBoundarie-layer',
                    type: 'fill',
                    source: 'editBoundarie',
                    paint: {
                        'fill-color': 'rgba(135, 206, 235, 0.4)',
                        'fill-outline-color': 'rgba(30, 144, 255, 1)'
                    }
                })
                draw.add(geoJsonEdit);
            }
            if (Object.keys(geoJsonDelivery).length !== 0) {
                map.addSource('delivery', {
                    "type": "geojson",
                    "data": geoJsonDelivery
                });
                map.addLayer({
                    id: 'delivery-layer',
                    type: 'fill',
                    source: 'delivery',
                    paint: {
                        'fill-color': 'rgba(169, 169, 169, 0.5)',
                        'fill-outline-color': 'rgba(128, 128, 128, 1)'
                    },

                })
                map.addLayer({
                    id: 'delivery-layer-text',
                    type: 'symbol',
                    source: 'delivery',
                    layout: {
                        'text-field': [
                            'format',
                            ['upcase', ['get', 'zone_name']],
                            { 'font-scale': 0.7 },
                            '\n',
                            {},
                            ['downcase', ['get', 'zone_type']],
                            { 'font-scale': 0.5 }
                        ],
                        'text-font': ['Open Sans Semibold', 'Arial Unicode MS Bold']
                    }
                })
            }
            if (Object.keys(geoJsonPickup).length !== 0) {
                map.addSource('pickup', {
                    "type": "geojson",
                    "data": geoJsonPickup
                });
                map.addLayer({
                    id: 'pickup-layer',
                    type: 'fill',
                    source: 'pickup',
                    paint: {
                        'fill-color': 'rgba(169, 169, 169, 0.5)',
                        'fill-outline-color': 'rgba(128, 128, 128, 1)'
                    }
                });
                map.addLayer({
                    id: 'pickup-layer-text',
                    type: 'symbol',
                    source: 'pickup',
                    layout: {
                        'text-field': [
                            'format',
                            ['upcase', ['get', 'zone_name']],
                            { 'font-scale': 0.7 },
                            '\n',
                            {},
                            ['downcase', ['get', 'zone_type']],
                            { 'font-scale': 0.5 }
                        ],
                        'text-font': ['Open Sans Semibold', 'Arial Unicode MS Bold']
                    }
                });
            }

            if (bounds !== null) {
                map.fitBounds(bounds, {padding: 50});
            }
        });

        map.scrollZoom.disable();
        map.scrollZoom.setWheelZoomRate(0.02); // Default 1/450

        map.on("wheel", event => {
            if (event.originalEvent.ctrlKey) { // Check if CTRL key is pressed
                event.originalEvent.preventDefault(); // Prevent chrome/firefox default behavior
                if (!map.scrollZoom._enabled) map.scrollZoom.enable(); // Enable zoom only if it's disabled
            } else {
                if (map.scrollZoom._enabled) map.scrollZoom.disable(); // Disable zoom only if it's enabled
            }
        });
    }, [merchant, boundaries, editBoundaries]);

    return (
        <div className="map-page-container">
            <div ref={mapContainer} className="map-container" />
        </div>
    );
}
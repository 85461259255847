import React from 'react';
import {useHistory} from 'react-router-dom';

import {Button, Form, Input, message} from 'antd';

import loginFetch from '../../Api/Login.js'

const layout = {
    labelCol: { span: 20 },
    wrapperCol: { span: 24 },
};
const tailLayout = {
    wrapperCol: { offset: 0, span: 1 },
};

/**
 * 
 * @param {*} props 
 * @returns 
 */
const FormLogin = (props) => {

    let history = useHistory();

    /**
     * 
     * @param {*} values 
     */
    const onFinish = (values) => {
        loginFetch(values)
            .then(response => {

                if (response.status === 200) {
                    return response.json();
                }

                throw new Error('Something went wrong');
                
            }).then(function (data) {
                localStorage.setItem('token', data);
                history.push("/");
            }).catch(() => {
                message.error('Identifiants de connexion invalides');
                history.push("/login")
            })
    }
    return (
        <div>
            <Form {...layout} onFinish={onFinish} >
                <Form.Item name="email" rules={[{ required: true, message: 'Please input your username!' }]} >
                    <Input placeholder="Email" />
                </Form.Item>
                <Form.Item name="password" rules={[{ required: true, message: 'Please input your password!' }]} >
                    <Input.Password placeholder="Mot de passe" />
                </Form.Item>
                <Form.Item {...tailLayout}>
                    <Button type="primary" htmlType="submit" >
                        CONNEXION
                    </Button>
                </Form.Item>
                <div style={{ textAlign: "left" }}>
                    <a href="https://www.applicolis.com/transporteurs/#itt_solution_form">Pas de compte, contactez-nous</a>
                </div>
            </Form>
        </div>
    )
};
export default FormLogin;

import React, {useEffect} from 'react';
import {ReactComponent as IconLogo} from "../../map.svg";

import {Tabs} from 'antd';
import Icon, {GlobalOutlined, InfoCircleOutlined, UnorderedListOutlined} from '@ant-design/icons';

import FormCoursesTab from './FormCoursesTab.js';
import FormGeneralTab from './FormGeneralTab.js';
import FormMapTab from './FormMapTab.js';
import FormStatutTab from './FormStatutTab.js';

const { TabPane } = Tabs;

/**
 *
 * @param {*} props
 * @returns
 */
const FormDriver = (props) => {

    let { user, driver, deliveryArea, pickupArea, lastPoint } = props;

    let tabKey = driver ? "1" : "3";

    /**
     *
     * @param {*} values
     */
    const onFinish = (values) => {
        props.formSubmit(values);
    };

    /**
     * 
     * @param {*} value 
     */
    const onChangeTracking = (value) => {
        props.trackingChange(value);
    };

    /**
     * 
     */
    const onClickValidate = () => {
        props.validateDriver();
    }

    /**
     * 
     */
    const onClickEject = () => {
        props.ejectDriver();
    }

    /**
     *
     */
    useEffect(() => { }, [driver])

    let coursesTab = null;
    let mapTab = null;
    if (driver) {
        mapTab = (
            <TabPane tab={<span><Icon component={IconLogo} style={{ fontSize: 15 }} />Carte</span>} key="1">
                <FormMapTab
                    deliveryArea={deliveryArea}
                    pickupArea={pickupArea}
                    lastPoint={lastPoint}
                    trackingChange={onChangeTracking}
                />
            </TabPane>
        )
        coursesTab = (
            <TabPane tab={<span><UnorderedListOutlined />Courses</span>} key="2">
                <FormCoursesTab
                    driver={driver}
                    deliveryArea={deliveryArea}
                    pickupArea={pickupArea}
                    lastPoint={lastPoint}
                    trackingChange={onChangeTracking}
                />
            </TabPane>
        )
    }

    return (
        <div>
            <Tabs defaultActiveKey={tabKey} type="card">
                {mapTab}
                {coursesTab}
                <TabPane tab={<span><InfoCircleOutlined />Général</span>} key="3">
                    <FormGeneralTab driver={driver} user={user} formSubmit={onFinish} />
                </TabPane>
                <TabPane tab={<span><GlobalOutlined />Statut</span>} key="4">
                    <FormStatutTab driver={driver} validateDriver={onClickValidate} ejectDriver={onClickEject} />
                </TabPane>
            </Tabs>
        </div>
    )
}

export default FormDriver;

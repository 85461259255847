import React from 'react';
import {Link} from 'react-router-dom';
import moment from 'moment';

import {Button, Card, Col, DatePicker, Divider, Layout, Row, Space} from 'antd';
import {CarFilled, FlagFilled, HomeFilled, RightCircleTwoTone} from '@ant-design/icons';

import mapboxgl from 'mapbox-gl';
import '@mapbox/mapbox-gl-draw/dist/mapbox-gl-draw.css';

import config from '../../Config.js';
import {getDashboard} from '../../Api/Dashboard.js';
import {
    coursesDeliveredByDay,
    coursesFailedByDay,
    coursesMerchantPrice,
    coursesPlannedByDay,
    coursesStatusByDay
} from '../../Api/Courses.js';

import ChartCoursesDelivered from '../../Components/Charts/ChartCoursesDelivered';
import ChartCoursesFailed from '../../Components/Charts/ChartCoursesFailed';
import ChartCoursesPlanned from '../../Components/Charts/ChartCoursesPlanned';
import ChartCoursesPrices from '../../Components/Charts/ChartCoursesPrices';
import ChartCoursesStatus from '../../Components/Charts/ChartCoursesStatus';

mapboxgl.accessToken = config.mapboxToken;

const { Content } = Layout;
const { RangePicker } = DatePicker;
const dateFormat = 'DD/MM/YYYY';

/**
 *
 */
class Dashboard extends React.Component {

    /**
     * 
     * @param {*} props 
     */
    constructor(props) {
        super(props);

        this.state = {
            counters: null,
            coursesDelivered: [],
            coursesFailed: [],
            coursesStatus: [],
            coursesPlanned: [],
            coursesMerchantPrice: [],
            total: {
                delivered: 0,
                failed: 0,
                planned: 0,
                status: 0,
                priceSuccess: 0,
                priceError: 0
            },
            dateEnd: moment().format('YYYY-MM-DD'),
            dateStart: moment().subtract(7, 'days').format('YYYY-MM-DD')
        };

        this.mapContainer = React.createRef();
        this.onDateChange = this.onDateChange.bind(this);
        this.onDateSend = this.onDateSend.bind(this);
    }

    /**
     *
     */
    componentDidMount() {
        this.loadData();
    }

    /**
     *
     */
    async loadData() {
        await this.loadDashboard();
        await this.loadCoursesDelivered();
        await this.loadCoursesPlanned();
        await this.loadCoursesFailed();
        await this.loadCoursesStatus();
        await this.loadCoursesMerchantPrice();
    }

    /**
     *
     */
    async loadDashboard() {
        await getDashboard()
            .then((res) => res.json())
            .then((json) => {
                this.setState({ counters: json });
            });
    }

    /**
     *
     */
    async loadCoursesDelivered() {
        let data = {
            dateStart: this.state.dateStart,
            dateEnd: this.state.dateEnd
        };

        coursesDeliveredByDay(data)
            .then((res) => res.json())
            .then((json) => {
                this.setState({
                    coursesDelivered: json.data,
                    total: {
                        ...this.state.total,
                        delivered: json.total
                    }
                });
            });
    }

    /**
     *
     */
    async loadCoursesPlanned() {
        let data = {
            dateStart: this.state.dateStart,
            dateEnd: this.state.dateEnd
        };

        coursesPlannedByDay(data)
            .then((res) => res.json())
            .then((json) => {
                this.setState({
                    coursesPlanned: json.data,
                    total: {
                        ...this.state.total,
                        planned: json.total
                    }
                });
            });
    }

    /**
     *
     */
    async loadCoursesFailed() {
        let data = {
            dateStart: this.state.dateStart,
            dateEnd: this.state.dateEnd
        };

        coursesFailedByDay(data)
            .then((res) => res.json())
            .then((json) => {
                this.setState({
                    coursesFailed: json.data,
                    total: {
                        ...this.state.total,
                        failed: json.total
                    }
                });
            });
    }

    /**
     *
     */
    async loadCoursesStatus() {
        coursesStatusByDay({})
            .then((res) => res.json())
            .then((json) => {
                this.setState({
                    coursesStatus: json.data,
                    total: {
                        ...this.state.total,
                        status: json.total
                    }
                });
            });
    }

    /**
     *
     */
    async loadCoursesMerchantPrice() {
        let data = {
            dateStart: this.state.dateStart,
            dateEnd: this.state.dateEnd
        };

        coursesMerchantPrice(data)
            .then((res) => res.json())
            .then((json) => {
                this.setState({
                    coursesMerchantPrice: json.data,
                    total: {
                        ...this.state.total,
                        priceSuccess: json.total.success,
                        priceError: json.total.error,
                    }
                });
            });
    }

    /**
     *
     * @param {*} values
     */
    onDateChange(values) {
        if (values !== null ) {
            this.setState({
                dateStart: values[0].format('YYYY-MM-DD'),
                dateEnd: values[1].format('YYYY-MM-DD')
            });
        }
    }

    /**
     *
     */
    onDateSend() {
        this.loadCoursesDelivered();
        this.loadCoursesPlanned();
        this.loadCoursesFailed();
        this.loadCoursesMerchantPrice();
    }

    render() {
        let nbCourses = 0;
        let nbMerchants = 0;
        let nbDrivers = 0;

        if (this.state.counters !== null) {
            nbCourses = this.state.counters.coursesCount;
            nbMerchants = this.state.counters.merchantsCount;
            nbDrivers = this.state.counters.driversCount;
        }

        return (
            <Content>
                <div style={{ margin: '16px 16px' }} className="site-card-wrapper" >
                    <Row gutter={16}>
                        <Col span={6}>
                            <Card
                                size="small"
                                bordered={false}
                                style={{
                                    textAlign: 'right',
                                    fontSize: '16px',
                                    backgroundColor: '#DDEBDF',
                                    overflow: 'hidden'
                                }}
                            >
                                <div className="visual"><FlagFilled className="visual-icon flag" /></div>
                                <div className="details">
                                    <span style={{ fontSize: '34px' }}>{nbCourses}</span>
                                    <br />
                                    Nombre de Courses
                                </div>
                                <Divider style={{ margin: '10px 0' }} ></Divider>
                                <Row gutter={24} style={{ fontSize: '14px' }} >
                                    <Col span={20} style={{ textAlign: 'left' }}>
                                        <Link to='/Courses' >Voir tout</Link>
                                    </Col>
                                    <Col span={2}>
                                        <RightCircleTwoTone twoToneColor="#2fac66" />
                                    </Col>
                                </Row>
                            </Card>
                        </Col>
                        <Col span={6}>
                            <Card
                                size="small"
                                bordered={false}
                                style={{
                                    textAlign: 'right',
                                    fontSize: '16px',
                                    backgroundColor: '#DDEBDF',
                                    overflow: 'hidden'
                                }}
                            >
                                <div className="visual"><HomeFilled className="visual-icon" /></div>
                                <div className="details">
                                    <span style={{ fontSize: '34px' }}>{nbMerchants}</span>
                                    <br />
                                    Nombre de Commerçants
                                </div>
                                <Divider style={{ margin: '10px 0' }} ></Divider>
                                <Row gutter={24} style={{ fontSize: '14px' }}>
                                    <Col span={20} style={{ textAlign: 'left', }}>
                                        <Link to='/Merchants'>Voir tout</Link>
                                    </Col>
                                    <Col span={2}>
                                        <RightCircleTwoTone twoToneColor="#2fac66" />
                                    </Col>
                                </Row>
                            </Card>
                        </Col>
                        <Col span={6}>
                            <Card
                                size="small"
                                bordered={false}
                                style={{
                                    textAlign: 'right',
                                    fontSize: '16px',
                                    backgroundColor: '#DDEBDF',
                                    overflow: 'hidden'
                                }}
                            >
                                <div className="visual"><CarFilled className="visual-icon" /></div>
                                <div className="details">
                                    <span style={{ fontSize: '34px' }}>{nbDrivers}</span>
                                    <br />
                                    Nombre de transporteurs
                                </div>
                                <Divider style={{ margin: '10px 0' }} ></Divider>
                                <Row gutter={24} style={{ fontSize: '14px' }}>
                                    <Col span={20} style={{ textAlign: 'left' }}>
                                        <Link to='/Drivers' >Voir tout</Link>
                                    </Col>
                                    <Col span={2}>
                                        <RightCircleTwoTone twoToneColor="#2fac66" />
                                    </Col>
                                </Row>
                            </Card>
                        </Col>
                    </Row>
                </div>
                <Content style={{ backgroundColor: "white", margin: '16px 16px', padding: "24px 24px" }}>
                    <h2 style={{ color: "#2FAC66" }}>STATISTIQUES</h2>
                    <Divider></Divider>
                    <Space>
                        <div>Sélectionnez une période pour obtenir vos statistiques :</div>
                        <RangePicker
                            defaultValue={[moment(), moment().subtract(7, 'days')]}
                            format={dateFormat}
                            onChange={this.onDateChange}
                            allowClear={false}
                        />
                        <Button type="primary" onClick={this.onDateSend}>
                            Envoyer
                        </Button>
                    </Space>
                    <Divider></Divider>
                    <div style={{ margin: '40px 0' }}>
                        <Row gutter={[32, 40]}>
                            <Col span={12}>
                                <ChartCoursesDelivered
                                    data={this.state.coursesDelivered}
                                    total={this.state.total.delivered}
                                />
                            </Col>
                            <Col span={12}>
                                <ChartCoursesFailed
                                    data={this.state.coursesFailed}
                                    total={this.state.total.failed}
                                />
                            </Col>
                            <Col span={12}>
                                <ChartCoursesPlanned
                                    data={this.state.coursesPlanned}
                                    total={this.state.total.planned}
                                />
                            </Col>
                            <Col span={12}>
                                <ChartCoursesPrices
                                    data={this.state.coursesMerchantPrice}
                                    priceSuccess={this.state.total.priceSuccess}
                                    priceError={this.state.total.priceError}
                                />
                            </Col>
                            <Col span={24}>
                                <ChartCoursesStatus
                                    data={this.state.coursesStatus}
                                    total={this.state.total.status}
                                />
                            </Col>
                        </Row>
                    </div>
                </Content>
            </Content>
        );
    }
}

export default Dashboard;

import React, {useEffect} from 'react';

import {Button, Form, Input} from 'antd';

import {getExternalAPIToken} from '../../Api/Merchants.js';

const layoutToken = {
    labelCol: {
        span: 6,
    },
    wrapperCol: {
        span: 16,
    },
};

/**
 * 
 * @param {*} props 
 * @returns 
 */
const FormAPITab = (props) => {

    let { merchant, userToken } = props;

    const [form] = Form.useForm();

    /**
     * 
     */
    const onClickToken = () => {
        let id = merchant.id;
        getExternalAPIToken(id)
            .then((res) => res.json())
            .then((json) => {
                let formValues = {
                    inputExternalApiToken: json.token,
                };
                form.setFieldsValue(formValues);
            })
    }

    /**
     * 
     */
    useEffect(() => {
        if (userToken !== null) {
            let formValues = {
                inputExternalApiToken: userToken,
            };

            form.setFieldsValue(formValues);
        }
    })

    return (
        <div>
            <Form form={form} name="layout" layout='vertical'>
                <Form.Item {...layoutToken} label="Token" name="inputExternalApiToken">
                    <Input />
                </Form.Item>
                <Button type="primary" onClick={onClickToken}>
                    Générer un token
                </Button>
            </Form>
        </div>
    )
}

export default FormAPITab;

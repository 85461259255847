import React from 'react';
import {Divider, Layout, notification, PageHeader} from 'antd';
import {InfoCircleOutlined} from '@ant-design/icons';
import moment from 'moment';

import {getNetworkBoundaries, getNetworkOpeningSchedule, getOneNetwork, updateNetwork} from '../../Api/Networks';
import FormNetwork from './FormNetwork';
import {GlobalContext} from '../../GlobalContext';

const { Content } = Layout;

class NetworkEdit extends React.Component {
    static contextType = GlobalContext;

    /**
     * 
     * @param {*} props 
     */
    constructor(props) {
        super(props);

        this.state = {
            network: null,
            user: null,
            openingSchedule: {
                "0": [[0, 24]],
                "1": [[0, 24]],
                "2": [[0, 24]],
                "3": [[0, 24]],
                "4": [[0, 24]],
                "5": [[0, 24]],
                "6": [[0, 24]],
            },
            boundaries: []
        };

        this.onSubmit = this.onSubmit.bind(this);
        this.reloadBoundaries = this.reloadBoundaries.bind(this);
    }

    /**
     *
     */
    componentDidMount() {
        this.loadNetwork();
        this.loadOpeningSchedules();
        this.loadBoundaries();
    }

    /**
     * 
     */
    async loadNetwork() {
        const id = this.props.match.params.id;
        await getOneNetwork(id)
            .then((res) => res.json())
            .then((data) => {
                this.setState({ network: data.network });
            });
    }

    /**
     *
     */
    async loadOpeningSchedules() {
        const id = this.props.match.params.id;

        await getNetworkOpeningSchedule(id)
            .then((res) => res.json())
            .then((data) => {
                if (data.length === 0) {
                    return;
                }

                let openingSchedules = data;
                let openingScheduleByDay = {};
                let openingScheduleByDay0 = [];
                let openingScheduleByDay1 = [];
                let openingScheduleByDay2 = [];
                let openingScheduleByDay3 = [];
                let openingScheduleByDay4 = [];
                let openingScheduleByDay5 = [];
                let openingScheduleByDay6 = [];
                let formatSchedules = [];

                openingSchedules.map((openingSchedule) => {
                    let openingMinutes = openingSchedule.opening_minutes;
                    if (openingMinutes === 30) {
                        openingMinutes = .5;
                    }

                    let closingMinutes = moment(openingSchedule.closing_minutes, "mm").add(1, 'minutes').get('minutes');
                    if (closingMinutes === 30) {
                        closingMinutes = .5;
                    }

                    openingSchedule = {
                        day: openingSchedule.day,
                        hourFrom: openingSchedule.opening_hours + openingMinutes,
                        hourTo: openingSchedule.closing_hours + closingMinutes
                    }

                    formatSchedules.push(openingSchedule);
                });

                formatSchedules.map((formatSchedule) => {
                    let hoursTable = [formatSchedule.hourFrom, formatSchedule.hourTo];

                    if (formatSchedule.day === 0) {
                        openingScheduleByDay0.push(hoursTable)
                    } else if (formatSchedule.day === 1) {
                        openingScheduleByDay1.push(hoursTable);
                    } else if (formatSchedule.day === 2) {
                        openingScheduleByDay2.push(hoursTable);
                    } else if (formatSchedule.day === 3) {
                        openingScheduleByDay3.push(hoursTable);
                    } else if (formatSchedule.day === 4) {
                        openingScheduleByDay4.push(hoursTable);
                    } else if (formatSchedule.day === 5) {
                        openingScheduleByDay5.push(hoursTable);
                    } else {
                        openingScheduleByDay6.push(hoursTable);
                    }
                });

                openingScheduleByDay = {
                    "0": openingScheduleByDay0,
                    "1": openingScheduleByDay1,
                    "2": openingScheduleByDay2,
                    "3": openingScheduleByDay3,
                    "4": openingScheduleByDay4,
                    "5": openingScheduleByDay5,
                    "6": openingScheduleByDay6,
                };

                this.setState({ openingSchedule: openingScheduleByDay });
            });
    }

    /**
     *
     */
    async loadBoundaries() {
        const id = this.props.match.params.id;

        await getNetworkBoundaries(id)
            .then((res) => res.json())
            .then((data) => {
                let translate = {
                    deliveryArea: 'zone de livraison',
                    pickupArea: 'zone de retrait'
                };

                data.forEach((d) => { d.zone_type = translate[d.zone_type]; });

                this.setState({ boundaries: data });
            });
    }

    /**
     * 
     */
    reloadBoundaries() {
        this.loadBoundaries();
    }

    /**
     * 
     * @param {*} values 
     */
    onSubmit(values) {
        const networkId = this.props.match.params.id;

        let data = {
            id: networkId,
            name: values.name,
            whiteLabel_id: values.whiteLabel_id,
            price_grid: values.price_grid,
            detailed_prices: values.detailed_prices,
        };

        if (values.fixed_price) {
            data.fixed_price = values.fixed_price.price;
            if (values.fixed_price.id) {
                data.price_id = values.fixed_price.id;
            }
        }

        if (values.configuration) {
            data.max_distance = values.configuration.max_distance;
            data.max_weigth = values.configuration.max_weigth;
            data.max_volume = values.configuration.max_volume;
            data.slack_webhook = values.configuration.slack_webhook;
            data.max_length = values.configuration.max_length;
            data.max_width = values.configuration.max_width;
            data.max_height = values.configuration.max_height;
            data.min_delay_to_start = values.configuration.min_delay_to_start;
        }

        if (values.commission) {
            data.commission_price = values.commission.commission_price;
        }

        updateNetwork(networkId, data)
            .then((res) => res.json())
            .then((data) => {
                notification['success']({
                    message: 'Le réseau bien mis à jour'
                });
                this.loadNetwork();
            })
    };

    render() {
        const user = this.context.global.profile;
        const { network, boundaries, openingSchedule } = this.state;

        return (
            <div>
                <PageHeader title="Réseaux" subTitle="Edition d'un réseau" />
                <Content style={{ margin: '0 16px 16px 16px' }}>
                    <div className="site-layout-background" style={{ padding: 24, minHeight: 360 }}>
                        <h2 style={{ color: '#2FAC66' }}><InfoCircleOutlined /> INFORMATIONS</h2>
                        <Divider />
                        <FormNetwork
                            network={network}
                            user={user}
                            boundaries={this.state.boundaries}
                            openingSchedule={this.state.openingSchedule}
                            reloadBoundaries={this.reloadBoundaries}
                            formSubmit={this.onSubmit}
                        />
                    </div>
                </Content>
            </div>
        );
    }
}

export default NetworkEdit;

import config from '../Config.js';

/**
 * 
 * @returns 
 */
export function getDashboard() {
    return fetch(config.hostApi, {
        method: 'GET',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': 'Bearer  ' + localStorage.getItem('token')
        }
    })
}
import React, { useState } from 'react';
import moment from 'moment';
import { Row, Col, Badge } from 'antd';
import { XAxis, YAxis, Tooltip, CartesianGrid, ResponsiveContainer, LineChart, Line } from 'recharts';
import CustomizedAxisTick from './CustomizedAxisTick';

/**
 *
 * @param {*} active
 * @param {*} payload
 * @param {*} label
 * @returns
 */
const CustomTooltipDelivered = ({ active, payload, label }) => {
    if (active && payload && payload.length) {
        return (
            <div className="custom-tooltip">
                <p className="custom-tooltip-title">{`${moment(payload[0].payload.deliveryDate, 'YYYYMMDD').format('DD/MM/YYYY')} `}</p>
                <p className="custom-tooltip-label">{`Courses délivrées : ${payload[0].value}`}</p>
            </div>
        );
    }

    return null;
};

/**
 *
 * @param {*} data
 * @param {*} total
 * @returns
 */
const ChartCoursesDelivered = ({ data, total }) => {
    return (
        <div>
            <Row style={{ height: '45px' }}>
                <Col span={16}><h3>Nombre de course délivrées par jour</h3></Col>
                <Col span={8} style={{ textAlign: 'right', paddingRight: '26px' }}>
                    <small>Total</small>
                    <Badge
                        style={{ backgroundColor: '#2FAC66', marginLeft: '5px' }}
                        count={total}
                        showZero={true}
                        overflowCount={999999999}
                    />
                </Col>
            </Row>
            <ResponsiveContainer width="100%" height={500}>
                <LineChart
                    data={data}
                    margin={{
                        top: 20,
                        right: 30,
                        left: 20,
                        bottom: 5,
                    }}
                >
                    <CartesianGrid strokeDasharray="3 3" />
                    <XAxis dataKey="deliveryDate" minTickGap={2} tick={<CustomizedAxisTick />} height={60} />
                    <YAxis />
                    <Tooltip content={<CustomTooltipDelivered />} />
                    <Line type="monotone" dataKey="total_delivered" stroke="#2FAC66" />
                </LineChart>
            </ResponsiveContainer>
        </div>
    );
};

export default ChartCoursesDelivered;
import React, {useContext, useEffect, useRef, useState} from 'react';
import {Link, Redirect} from 'react-router-dom';
import {saveAs} from 'file-saver';
import moment from 'moment';

import {
    Alert,
    Avatar,
    Button,
    Col,
    DatePicker,
    Divider,
    Form,
    Layout,
    message,
    Modal,
    PageHeader,
    Row,
    Select,
    Space,
    Table,
    Tag,
    TimePicker,
    Tooltip
} from 'antd';
import {
    CaretRightOutlined,
    ClockCircleOutlined,
    DeleteOutlined,
    DownloadOutlined,
    EyeOutlined,
    FormOutlined,
    PlusCircleOutlined,
    UnorderedListOutlined
} from '@ant-design/icons';

import {courseCanceled, courseValidate, exportCourses, getList as getCourses, getOneCourse} from '../../Api/Courses';
import {getManyWhiteLabels} from '../../Api/WhiteLabels';
import {GlobalContext} from '../../GlobalContext';

import ModalDriver from '../../Components/Course/ModalDriver';
import DrawerDetailsCourse from '../../Components/Course/DrawerDetailsCourse';
import {translateStatus} from "../../constants";

const {Option} = Select;
const {RangePicker} = DatePicker;
const {Content} = Layout;

const timeFormat = 'HH:mm';
const dateFormat = 'YYYY-MM-DD';
const layoutForm = {
    style: {
        paddingTop: 10,
    },
    labelAlign: 'left',
    labelCol: {
        span: 6,
    },
    wrapperCol: {
        span: 18,
        style: {
            paddingRight: 20
        }
    },
};
const layoutFormItem = {
    style: {
        marginBottom: 20
    }
};

const defaultFilters = {
    deliveryDates: [moment(), moment()],
    status: ['available', 'assigned', 'started', 'inProgress'],
    pickupTime: [null, null],
    deliveryTime: [null, null],
    whiteLabelId: null
};

/**
 *
 * @param {*} props (filters, user)
 * @returns
 */
const Filter = (props) => {
    const [form] = Form.useForm();
    const [whiteLabels, setWhiteLabels] = useState([]);

    /**
     *
     * @param {*} value
     */
    const fetchWhiteLabels = (payload) => {
        getManyWhiteLabels(payload)
            .then((res) => {
                if (res.status !== 200) {
                    throw Error();
                }
                return res.json();
            })
            .then((json) => {
                setWhiteLabels(json.results);
            })
            .catch((err) => {
            });
    };

    /**
     *
     * @param {*} value
     */
    const onSearchWhiteLabel = (value) => {
        if (value.length <= 2) {
            return;
        }

        let payload = {term: value};
        fetchWhiteLabels(payload);
    };

    /**
     *
     * @param {*} values
     */
    const onFinish = (values) => {
        props.updateFilters(values);
    };

    /**
     *
     */
    const clearFilter = () => {
        form.setFieldsValue({...props.filters});
        props.updateFilters({...props.filters});
    };

    let itemWhiteLabel = null;
    if (props.user && !props.user.whiteLabel_id) {
        const whiteLabelOptions = whiteLabels.map((d) => <Option key={d.id} value={d.id}>{d.name}</Option>);

        itemWhiteLabel = (
            <Form.Item {...layoutFormItem} name="whiteLabelId" label="Marques blanches">
                <Select
                    showSearch
                    showArrow
                    allowClear
                    filterOption={false}
                    notFoundContent={null}
                    placeholder="Entrer au minimum 3 caractères"
                    onSearch={onSearchWhiteLabel}
                >
                    {whiteLabelOptions}
                </Select>
            </Form.Item>
        );
    }

    const handleDeliveryDatesRangeChange = (dates, dateStrings) => {
        let daysNumber = dates[1].endOf('day').diff(dates[0].startOf('day'), 'days') + 1;
        if (daysNumber > 7) {
            form.setFieldsValue({
                deliveryDates: [
                    dates[0],
                    moment(dates[0].start).add(6, 'days')
                ]
            });

            message.warning('La sélection maximale est de 7 jours !');
        }
    };

    return (
        <Form
            {...layoutForm}
            form={form}
            layout="horizontal"
            onFinish={onFinish}
            initialValues={{...props.filters}}
        >
            <Row>
                <Col span={12}>
                    <Form.Item
                        {...layoutFormItem}
                        label="Date de livraison entre le "
                        name="deliveryDates"
                    >
                        <RangePicker
                            style={{width: '100%'}}
                            format={'DD/MM/YYYY'}
                            allowClear={false}
                            onChange={handleDeliveryDatesRangeChange}
                        />
                    </Form.Item>
                </Col>
            </Row>
            <Row>
                <Col span={12}>
                    <Form.Item {...layoutFormItem} name="pickupTime" label="Heure de retrait">
                        <TimePicker.RangePicker
                            style={{width: '100%'}}
                            placeholder={['Heure de début', 'Heure de fin']}
                            format={timeFormat}
                        />
                    </Form.Item>
                </Col>
                <Col span={12}>
                    <Form.Item {...layoutFormItem} name="deliveryTime" label="Heure de livraison">
                        <TimePicker.RangePicker
                            style={{width: '100%'}}
                            placeholder={['Heure de début', 'Heure de fin']}
                            format={timeFormat}
                        />
                    </Form.Item>
                </Col>
            </Row>
            <Row>
                <Col span={12}>
                    <Form.Item {...layoutFormItem} name="status" label="Status des courses">
                        <Select
                            mode="multiple"
                            placeholder="Sélectionner un ou plusieurs statuts"
                        >
                            <Option value="draft">Brouillon</Option>
                            <Option value="available">Emise</Option>
                            <Option value="assigned">Assignée</Option>
                            <Option value="started">Démarée</Option>
                            <Option value="inProgress">Colis Récupéré</Option>
                            <Option value="isDelivered">Colis livré</Option>
                            <Option value="failed">Echouée (Livraison)</Option>
                            <Option value="pickupFailed">Echouée (Retrait)</Option>
                            <Option value="canceled">Annulée</Option>
                            <Option value="returned">Retournée</Option>
                        </Select>
                    </Form.Item>
                </Col>
                <Col span={12}>
                    {itemWhiteLabel}
                </Col>
            </Row>
            <Row>
                <Col span={24}>
                    <Form.Item>
                        <Space>
                            <Button type="primary" htmlType="submit">
                                Filtrer
                            </Button>
                            <Button onClick={clearFilter}>
                                Annuler
                            </Button>
                        </Space>
                    </Form.Item>
                </Col>
            </Row>
        </Form>
    );
};

const AUTORELOAD_INTERVAL = 15;

const Courses = () => {
    let contextType = useContext(GlobalContext);


    const [autoReloadStart, setAutoReloadStart] = useState(true)
    const [intervalCoursesCounter, setIntervalCoursesCounter] = useState(AUTORELOAD_INTERVAL)
    const [courses, setCourses] = useState([])
    const [pagination, setPagination] = useState({
        current: 1,
        pageSize: 50,
    })
    const [loading, setLoading] = useState(false)
    const [dataWhiteLabel, setDataWhiteLabel] = useState([])
    const [visibleDrawerCourseInfo, setVisibleDrawerCourseInfo] = useState(false)
    const [course, setCourse] = useState(null)
    const [selectedRowKeys, setSelectedRowKeys] = useState([])
    const [selectedRows, setSelectedRows] = useState([])
    const [isModalVisible, setIsModalVisible] = useState(false)
    const [uuid, setUuid] = useState(null)
    const [redirection, setRedirection] = useState(false)
    const [modalAvailableVisible, setModalAvailableVisible] = useState(false)
    const [previousPrice, setPreviousPrice] = useState(null)
    const [newPrice, setNewPrice] = useState(null)
    const [filters, setFilters] = useState({...defaultFilters})

    let intervalCoursesCounterRef = useRef()

    let columns = [
        {
            title: '#',
            dataIndex: 'short_uuid',
            key: 'short_uuid',
            width: 50
        },
        {
            title: 'Date de livraison',
            dataIndex: 'deliveryDate',
            key: 'deliveryDate',
            render: deliveryDate => {
                return moment(deliveryDate).format('DD/MM/YYYY');
            }
        },
        {
            title: 'A partir de',
            dataIndex: 'collectTime',
            key: 'collectTime'
        },
        {
            title: 'Avant',
            dataIndex: 'deliveryTime',
            key: 'deliveryTime'
        },
        {
            title: 'Commerçant',
            dataIndex: 'merchant',
            key: 'merchant',
            render: (merchant) => {
                if (merchant === null) {
                    return;
                }

                return <Link to={'/merchant/' + merchant.id}>{merchant.name}</Link>;
            }
        },
        {
            title: 'Client',
            dataIndex: 'customer',
            key: 'customer',
            render: (customer) => {
                if (customer === null) {
                    return;
                }

                if (customer.archived_at !== null) {
                    return (
                        <Tooltip title="client archivé">
                            <span>{customer.firstname} {customer.name}</span>
                        </Tooltip>
                    );
                }

                return <Link to={'/customer/' + customer.id}>{customer.firstname} {customer.name}</Link>;
            }
        },
        {
            title: 'Adresse de livraison',
            dataIndex: 'delivery_area',
            key: 'delivery_area',
            render: (delivery_area) => {
                if (delivery_area === null) {
                    return;
                }

                return <p>{delivery_area.address}</p>;
            }
        },
        {
            title: 'Véhicule',
            dataIndex: 'transports',
            key: 'transports',
            render: (transports) => {
                if (transports === null) {
                    return;
                }

                const translate = {
                    bike: 'Vélo',
                    walk: 'A pied',
                    cargoBike: 'Vélo cargo',
                    motorbike: 'Scooter ou moto',
                    car: 'Voiture',
                    bus: 'Bus',
                    tram: 'Tram',
                    commercialVehicle: 'Véhicule commercial',
                    all: 'Tous',
                };

                let transportTranslate = [];
                transports.map((transport) => {
                    transportTranslate.push(translate[transport]);
                });

                return <p>{transportTranslate}</p>;
            }
        },
        {
            title: 'Transporteur',
            dataIndex: 'driver',
            key: 'driver',
            render: (driver) => {
                if (driver === null) {
                    return;
                }

                return <Link to={'/driver/' + driver.id}>{driver.firstName} {driver.lastName}</Link>;
            }
        },
        {
            title: 'Statut',
            dataIndex: 'status',
            key: 'status',
            render: (status) => {
                return (
                    <Tag color="green" key={status}>
                        {translateStatus[status]}
                    </Tag>
                );
            }
        },
        {
            title: '',
            key: 'course',
            width: 250,
            render: (course) => {
                return (
                    <div>
                        <Space wrap>
                            <Button type='primary' onClick={() => showDrawerCourseInfo(course.id)}>
                                <EyeOutlined/>
                            </Button>
                            <Button type='primary'>
                                <Link to={'/course/' + course.uuid}><FormOutlined/></Link>
                            </Button>
                            <Button type='primary' onClick={() => onClickValidate(course.id)}>
                                <CaretRightOutlined/>
                            </Button>
                            <Button type='primary' onClick={() => onClickCanceled(course.id)}>
                                <DeleteOutlined/>
                            </Button>
                        </Space>
                    </div>
                );
            }
        }
    ];

    useEffect(() => {
        startAutoreload();
        return () => clearInterval(intervalCoursesCounterRef.current);
    }, []);

    useEffect(() => {
        if (intervalCoursesCounter === AUTORELOAD_INTERVAL && !loading) {
            fetchCourses(pagination).catch(console.error);
        }
    }, [intervalCoursesCounter]);

    const startAutoreload = () => {
        setIntervalCoursesCounter(AUTORELOAD_INTERVAL)
        setAutoReloadStart(true)
        intervalCoursesCounterRef.current = setInterval(() => {
            setIntervalCoursesCounter((prev) => {
                let counter = prev - 1;

                if (counter <= 0) {
                    counter = AUTORELOAD_INTERVAL;
                }

                return counter;
            })
        }, 1000);
    }

    const stopAutoreload = () => {
        setAutoReloadStart(false)
        clearInterval(intervalCoursesCounterRef.current)
    }

    /**
     *
     */
    const getPayload = (filters) => {
        let payload = {
            dateStart: filters.deliveryDates[0].format(dateFormat),
            dateEnd: filters.deliveryDates[1].format(dateFormat),
        };

        if (!filters.pickupTime.includes(null)) {
            payload.pickupTimeStart = filters.pickupTime[0].format(timeFormat);
            payload.pickupTimeEnd = filters.pickupTime[1].format(timeFormat);
        }
        if (!filters.deliveryTime.includes(null)) {
            payload.deliveryTimeStart = filters.deliveryTime[0].format(timeFormat);
            payload.deliveryTimeEnd = filters.deliveryTime[1].format(timeFormat);
        }
        if (filters.whiteLabelId) {
            payload.whiteLabelId = filters.whiteLabelId;
        }
        if (filters.status.length > 0) {
            payload.status = filters.status;
        }

        return payload;
    }

    /**
     *
     * @param {*} pagination (from Ant Table)
     * @param {*} filter (useless from Ant Table)
     * @param {*} sorter (useless from Ant Table)
     * @param {*} dataSource (useless from Ant Table)
     * @param {*} newFilter (if new filter, return page 1)
     */
    const fetchCourses = async (pagination, filter, sorter, dataSource, newFilter = false) => {
        setLoading(true)
        const page = (newFilter) ? 1 : pagination.current;
        const limit = pagination.pageSize;

        const payload = getPayload(filter || filters);

        await getCourses(page, limit, payload)
            .then((res) => res.json())
            .then((json) => {
                let coursesWithKey = json.courses;
                coursesWithKey.forEach((course) => {
                    course.key = course.id
                });

                setLoading(false)
                setCourses(coursesWithKey)
                setPagination({
                    current: pagination.current,
                    pageSize: pagination.pageSize,
                    total: json.totalCount,
                })
            }).catch(() => {
                setLoading(false)
            });
    }

    /**
     *
     * @param {*} id
     */
    const onClickValidate = (id) => {
        let confirm = false
        courseValidate(id, confirm)
            .then((res) => res.json())
            .then((data) => {
                if (data.error === null) {
                    fetchCourses(pagination);
                } else {
                    setModalAvailableVisible(true)
                    setPreviousPrice(data.previousPrice)
                    setNewPrice(data.newPrice)
                    setUuid(data.course.uuid)
                    setCourse(data.course)
                }
            });
    }

    /**
     *
     * @param {*} id
     */
    const onClickCanceled = (id) => {
        courseCanceled(id)
            .then((res) => res.json())
            .then((data) => {
                setUuid(data.uuid)
                setRedirection(true)
            });
    }

    /**
     *
     */
    const onClickExport = () => {
        const dateStartFilter = filters.deliveryDates[0].format(dateFormat);
        const dateEndFilter = filters.deliveryDates[1].format(dateFormat);

        exportCourses(dateStartFilter, dateEndFilter)
            .then((res) => res.blob())
            .then(blob => saveAs(blob, 'export-courses.xls'));
    }

    /**
     *
     * @param {*} id
     */
    const showDrawerCourseInfo = (id) => {
        setVisibleDrawerCourseInfo(true)

        getOneCourse(id)
            .then((res) => res.json())
            .then((json) => {
                setCourse(json.course)
            });
    }

    /**
     *
     */
    const onCloseCourseInfo = () => {
        setVisibleDrawerCourseInfo(false)
    }

    /**
     *
     * @param {*} value
     */
    const showDrawer = (value) => {
        setVisibleDrawerCourseInfo(value)
    }

    /**
     *
     * @param {*} selectedRowKeys
     * @param {*} selectedRows
     */
    const onSelectChange = (selectedRowKeys) => {
        setSelectedRowKeys(selectedRowKeys)
    };


    /**
     *
     */
    const showModal = () => {
        setIsModalVisible(true)
    };

    /**
     *
     * @param {*} value
     */
    const showModalDriver = (value) => {
        setIsModalVisible(value)
        setSelectedRowKeys([])
        fetchCourses(pagination);
    }

    /**
     *
     */
    const handleAvailableOk = () => {
        const confirm = true;

        courseValidate(course.id, confirm)
            .then((res) => res.json())
            .then((data) => {
                if (data.error === null) {
                    fetchCourses(pagination);
                } else {
                    setModalAvailableVisible(true)
                    setPreviousPrice(data.previousPrice)
                    setNewPrice(data.newPrice)
                }
            });

        setModalAvailableVisible(false)
    }

    /**
     *
     */
    const handleAvailableCancel = () => {
        setModalAvailableVisible(false)
    }

    /**
     *
     * @param {*} values
     */
    const editFilters = (values) => {
        setFilters(values)
        stopAutoreload()
        startAutoreload()
    }

    if (redirection) {
        return (<Redirect to={'/course/' + uuid}/>);
    }

    const rowSelection = {
        selectedRowKeys,
        onChange: onSelectChange,
        getCheckboxProps: (course) => ({
            disabled: course.price === null
        })
    };

    const hasSelected = selectedRowKeys.length > 0;
    const user = contextType.global.profile;

    return (
        <Content>
            <div style={{margin: '16px 16px'}}>
                <div className="site-layout-background" style={{padding: 24, minHeight: 360}}>
                    <PageHeader
                        className="site-page-header"
                        avatar={{src: <Avatar icon={<UnorderedListOutlined/>}/>}}
                        title="LISTE DES COURSES"
                        extra={[
                            <Link to="/course/add">
                                <Button icon={<PlusCircleOutlined/>}>Ajouter</Button>
                            </Link>,
                            <Button type="button" onClick={onClickExport} icon={<DownloadOutlined/>}>
                                Exporter
                            </Button>,
                        ]}
                    />
                    <Divider/>
                    <Filter filters={defaultFilters} user={user} updateFilters={editFilters}/>
                    <Divider/>
                    <Row style={{marginBottom: 16}}>
                        <Col span={12}>
                            <Button type="primary" onClick={showModal} disabled={!hasSelected}>
                                Transporteur
                            </Button>
                        </Col>
                        <Col span={12} style={{
                            textAlign: "right"
                        }}><Tooltip title="rafraîchissement automatique" placement={"left"}>
                            <div style={{
                                display: "inline",
                                cursor: "pointer",
                                opacity: autoReloadStart ? 1 : 0.4
                            }} onClick={() => {
                                autoReloadStart ? stopAutoreload() : startAutoreload();
                            }}><ClockCircleOutlined/>{' '}
                                <div style={{
                                    display: "inline-block",
                                    width: "20px"
                                }}> {intervalCoursesCounter}s
                                </div>
                            </div>
                        </Tooltip></Col>
                    </Row>

                    <ModalDriver
                        selectedRowKeys={selectedRowKeys}
                        showModalDriverVisible={isModalVisible}
                        showModalDriver={showModalDriver}
                    />
                    <Table
                        rowSelection={rowSelection}
                        columns={columns}
                        loading={loading}
                        onChange={(pagination) => {
                            stopAutoreload()
                            setPagination(prev => {
                                return {
                                    ...prev,
                                    ...pagination
                                }
                            })
                            startAutoreload()
                        }}
                        pagination={{
                            ...pagination,
                            showSizeChanger: true,
                            pageSizeOptions: ["10", "20", "50"]
                        }}
                        dataSource={courses}
                    />
                    <DrawerDetailsCourse
                        course={course}
                        showDrawerCourseDetails={visibleDrawerCourseInfo}
                        showDrawer={showDrawer}
                    />
                </div>
            </div>
            <Modal
                visible={modalAvailableVisible}
                onOk={handleAvailableOk}
                onCancel={handleAvailableCancel}
                cancelText="Annuler"
            >
                <Alert
                    showIcon
                    message="Le prix de la course a changé."
                    description={
                        <div>
                            <p>Ancien prix : {previousPrice} €</p>
                            <p>Nouveau prix : {newPrice} €</p>
                            <p>Confirmez-vous l'émission de la course avec le nouveau prix ?</p>
                        </div>
                    }
                    type="warning"
                    style={{marginTop: '20px', marginBottom: '20px'}}
                />
            </Modal>
        </Content>
    );
}

export default Courses;

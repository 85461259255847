import React, {useState} from 'react';
import {Redirect} from 'react-router-dom';

import {Divider, Layout, notification, PageHeader} from 'antd';
import {InfoCircleOutlined} from '@ant-design/icons';

import {postCustomer} from '../../Api/Customers.js';
import FormCustomer from './FormCustomer.js';

const {Content} = Layout;

const CustomerAdd = () => {

    const [redirection, setRedirection] = useState(false)
    const [id, setId] = useState(null)
    const [customer, setCustomer] = useState(null)

    /**
     *
     * @param {*} values
     */
    const onSubmit = (values) => {
        postCustomer(values)
            .then(function (response) {
                return response.json();
            })
            .then((data) => {
                notification['success']({
                    message: 'Le client a bien été enregistré'
                })

                setId(data.customer.id);
                setRedirection(true);
            });
    };

    if (redirection) {
        return (<Redirect to={'/customer/' + id}/>)
    }

    return (
        <div>
            <PageHeader title="Clients" subTitle="Création d'un client"/>
            <Content style={{margin: '0 16px 16px 16px'}}>
                <div className="site-layout-background" style={{padding: 24, minHeight: 360}}>
                    <h2 style={{color: "#2FAC66"}}><InfoCircleOutlined/> INFORMATIONS</h2>
                    <Divider></Divider>
                    <FormCustomer customer={customer} formSubmit={onSubmit}/>
                </div>
            </Content>
        </div>

    )
}

export default CustomerAdd;
